/**Packages */
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import TextLink from "../../Elements/TextLink";
import FlexRow from "../../Elements/FlexRow";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const Footer = () => {
  const { t, i18n, ready } = useTranslation();
  const isLogged = useSelector((state) => state?.user?.isLoggedIn);

  return (
    <footer id="layout-footer" className="background-gray-dark">
      <FlexRow id="footer-first-row" classList="xs cols-equal">
        <div className="cpar-flex-col">
          <TextLink
            onClick={() => {
              NavigationProvider.goTo(isLogged ? PAGES.USER_AREA.USER_ONLINE_SECURITY : PAGES.ONLINE_SECURITY);
            }}
            text={t("Sicurezza online")}
          />
        </div>
        <div className="cpar-flex-col">
          <TextLink
            onClick={() => {
              NavigationProvider.goTo(isLogged ? PAGES.USER_AREA.USER_USEFUL_NUMBERS : PAGES.USEFUL_NUMBERS);
            }}
            text={t("Numeri utili per assistenza")}
          />
        </div>
      </FlexRow>
      <FlexRow id="footer-second-row" classList="lg">
        <p className="text-size-xs">
          Compass Banca S.p.A., socio unico direzione e coordinamento Mediobanca S.p.A.. Tutti i diritti riservati. Sede
          Legale e Direzione Generale: Via Caldera 21 - 20153 Milano. Capitale euro 587.500.000 i.v.;Partita IVA Gruppo
          IVA Mediobanca: 10536040966; Codice Fiscale e Numero di Iscrizione nel Registro delle Imprese di Milano:
          00864530159; Banca iscritta all’Albo delle Banche e appartenente al Gruppo Bancario Mediobanca iscritto
          all’Albo dei Gruppi Bancari. Iscritta all’Albo degli Intermediari Assicurativi tenuto dall’IVASS: D000203141
          sez. D del RUI; Aderente al Fondo Interbancario di Tutela dei Depositi;. Associata ABI Associazione Bancaria
          Italiana - Associata ASSOFIN Associazione Italiana del Credito al Consumo e Immobiliare.
        </p>
      </FlexRow>
    </footer>
  );
};
export default Footer;
