/**
 * INDEX_URL describe the index of the application
 */
export const INDEX_URL = "/";

/**
 * Enumeration of the pages
 */
export const PAGES = {
  HOME: "home",
  LOGIN_STEP_1: "login-1",
  LOGIN_STEP_2: "login-2",
  SIGNUP: "signup",
  SIGNUP_CONFIRMATION: "signup-confirmation",
  FORGOT_PASSWORD: "forgot-password",
  FORGOT_PASSWORD_STEP_2: "forgot-password-2",
  FORGOT_PASSWORD_OTP: "forgot-password-otp",
  FORGOT_PASSWORD_ACTIVATE: "new-password-confirmation",
  NEW_PASSWORD_OTP: "new-password-otp",
  /**Deprecated? */
  NEW_PASSWORD_CONFIRMATION: "new-password-confirmation",
  ONLINE_SECURITY: "online-security",
  USEFUL_NUMBERS: "useful-numbers",
  USER_AREA: {
    ACCOUNT_ACCESS: "account-access",
    ACCOUNT_ACCESS_OTP: "account-access-otp",
    ACCOUNT: "account",
    ACCOUNT_UPDATE_CONFIRM_OTP: "account-update-confirm-otp",
    EDIT_PASSWORD: "edit-password",
    EDIT_PASSWORD_CONFIRM_OTP: "edit-password-confirm-otp",
    EDIT_PASSWORD_CONFIRMATION: "edit-password-confirmation",
    INBOX: "inbox",
    INDEX: "user-area",
    MOVEMENTS: "movements",
    MOVEMENTS_MONTH: "movements-month",
    ACCOUNT_BALANCE: "account-balance",
    BANK_TRANSFER: "bank-transfer",
    BANK_TRANSFER_CALCULATE_RATE: "bank-transfer-calculate-rate",
    BANK_TRANSFER_CALCULATE_RATE_SUMM: "bank-transfer-calculate-rate-summ",
    BANK_TRANSFER_REQUEST: "bank-transfer-request",
    CHANGE_STANDING_EC: "change-standing-ec",
    USER_USEFUL_NUMBERS: "user-useful-numbers",
    USER_ONLINE_SECURITY: "user-online-security",
    SMS_ACTIVATION: "sms-activation",
    SUPPORT: "support",
    OTP_FORM: "otp",
  },
  TRANSPARENCY: "https://www.poste.it/resources/bancoposta/pdf/trasparenza/SECCI_CPIU.pdf",
  ERROR: "error",
};

/**
 * Enumeration of cases for the otpFormHandler util
 */
export const OTP_CASES = {
  BONIFICO: "bonifico",
  SMS_ACTIVATION: "sms_activation",
  MODIFY_USER: "modify_user",
  EC_METHOD: "EC_METHOD",
};

/**
 * key used to save token in sessionStorage
 */
export const TOKEN_KEY = "token";

/**
 * key used to save user data in sessionStorage
 */
export const USER_DATA_KEY = "user_data";

/**
 * Key for movement of the current month -> used in redux state
 */
export const VIAGGIANTI = "viaggianti";

/**
 * Describe base Movement obj (in Redux state)
 */
export const BASE_MOVEMENTS_OBJ = {
  acquisti: [
    /**
         *  public string Data { get; set; }
            public Decimal Importo { get; set; }
            public string Descrizione { get; set; }
            public bool Positivo { get; set; }
         */
  ],
  movimenti: [
    /**
         *  public string Data { get; set; }
            public Decimal Importo { get; set; }
            public string Descrizione { get; set; }
            public bool Positivo { get; set; }
         */
  ],
  totale: 0,
};

/**
 * Describe base Resume obj (in Redux state)
 */
export const BASE_RESUMES_OBJ = {
  /*
    "periodo": "4/2020",
    "acquisti": 336.0,
    "pagamenti": 938.0,
    "insoluti": 0.0,
    "intCom": 2.0,
    "saldo": 338.0
    */
  periodo: "",
  acquisti: 0,
  pagamenti: 0,
  insoluti: 0,
  intCom: 0,
  saldo: 0,
};

/**
 * Enumeration of API codes response
 */
export const API_RESPONSE = {
  SUCCESS_CODE: 0,
};

/**
 * Enumeration of Estratto conto (codes) methods possible
 */
export const EC_METHODS = {
  CARTACEO: "C",
  INTERNET: "I",
  //not used
  EMAIL: "E",
};

/**
 * Enumeration of Estratto conto (full string) methods possible
 */
export const EC_METHODS_FULL = {
  C: "Cartaceo",
  I: "Internet",
  //not used
  E: "Email",
};

/**
 * Enumeration of Products possible (with id, name, title and link to the correct image)
 */
export const PRODOTTO_TIPO = {
  CARTA: {
    id: 0,
    name: "cartaBpp",
    title: "CartaBancoPostaPiu",
    image: "images/cards/carta_bpp.svg",
  },
  FIDO: {
    id: 1,
    name: "fidoPos",
    title: "FidoBancoPostaPos",
    image: "images/cards/fido_pos.svg",
  },
  FIDO_AFFARI: {
    id: 2,
    name: "fidoAffari",
    title: "FidoBancoPostaAffari",
    image: "images/cards/fido_affari.svg",
  },
};

/**
 * Number of movements and resumes possible to see in the Dashboard
 */
export const MAX_ITEMS_HOME = 3;

/**
 * Enumeration of possible types of alerts
 */
export const SITE_MESSAGE_STATUS = {
  SUCCESS: 0,
  WARNING: 1,
  ERROR: 2,
};

/**
 * Base phone link
 */
export const DIALER_BASE_URL = "tel:";

/**
 * Obsolete dates
 */
export const DATES_OBSOLETE = ["01/01/0001", "01/01/1901", "01/01/1971", "01/01/2001"];
