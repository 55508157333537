/**Packages */
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import Backend from "i18next-http-backend";

/**Helpers */
import { getCurrentLng, getLanguages, getFormattedPublicUrl } from "./helpers/language";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    lng: getCurrentLng(),
    debug: true,
    supportedLngs: getLanguages(),
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default,
      format: function (value, format, lng) {
        if (format.includes("uppercase")) return value.toUpperCase();
        if (format.includes("bold")) return `<strong>${value}</strong>`;
        if (format.includes("underline")) return `<u>${value}</u>`;
        return value;
      },
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ["u", "strong"],
    },
    backend: {
      loadPath: getFormattedPublicUrl() + "locales/{{lng}}/{{ns}}.json",
    },
  });
export default i18n;
