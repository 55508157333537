/**CSS */
import './style.css';

const PinPad = ({ keyPadClickHandler, keyPadBackkHandler }) => {
    //=> [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const pins = [...Array(10).keys()];

    const getPinsHeader = () => {
        return pins.slice(0, 3).map((value, idx) => {
            return (
                <td
                    key={'header_' + idx}
                    className="control-text keyhover"
                    //width={value === 1 ? '34%' : '33%'}
                    onClick={() => keyPadClickHandler(value)}
                >
                    {value}
                </td>
            );
        });
    };
    const getPinsEl = (startIndex, endIndex) => {
        return pins.slice(startIndex, endIndex).map((value, idx) => {
            return (
                <td
                    key={'el_' + (startIndex + idx)}
                    className="control-text keyhover"
                    onClick={() => keyPadClickHandler(value)}
                >
                    {value}
                </td>
            );
        });
    };
    return (
        <table className="table">
            <tbody>
                <tr>{getPinsHeader()}</tr>
                <tr>{getPinsEl(3, 6)}</tr>
                <tr>{getPinsEl(6, 9)}</tr>
                <tr>
                    <td className="control-text"></td>
                    {getPinsEl(9, 10)}
                    <td className="control-text keyhover" onClick={keyPadBackkHandler}>
                        <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M6.603 2h7.08a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-7.08a1 1 0 0 1-.76-.35L1 8l4.844-5.65A1 1 0 0 1 6.603 2zm7.08-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zM5.829 5.146a.5.5 0 0 0 0 .708L7.976 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"
                            />
                        </svg>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default PinPad;
