/**Action Types*/
import { ERROR_VISIBLE, ERROR_INVISIBLE } from "./../actionTypes/errorActionsTypes";

/**
 *
 * @param {*} title - title of the error
 * @param {*} message - message of the error
 * @returns The action to be dispatched to view the error dialog
 */
export const setError = (title = "Errore", message = "Qualcosa è andato storto, riprova più tardi") => {
  return {
    type: ERROR_VISIBLE,
    payload: {
      title,
      message,
    },
  };
};
/**
 *
 * @returns The action to be dispatched to expire the error dialog
 */
export const expireError = () => {
  return {
    type: ERROR_INVISIBLE,
  };
};
