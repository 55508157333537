/**Packages */
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

/**
 *
 * @param {*} value - value to be checked
 * @param {*} minLength - minimum length of the string
 * @returns true if string is valid and respect he minimum length, otherwise false
 */
export const checkString = (value, minLength = 0) => {
  if (!typeof value === "string" || value.length < minLength) return false;

  return true;
};
/**
 *
 * @param {*} value - value to be checked
 * @returns true if value is a valid email, otherwise false
 */
export const validateEmail = (value) => {
  if (!checkString(value)) return false;

  const mailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  // return value.match(mailRegex);
  return mailRegex.test(value);
};
/**
 *
 * @param {*} value - value to be checked
 * @returns true if value is a valid password, otherwise false
 */
export const validatePassword = (value) => {
  if (!checkString(value)) return false;

  return value.length >= 6;
};
/**
 *
 * @param {*} value_1 - value to be checked
 * @param {*} value_2 - value to be checked
 * @returns true if values are equal, otherwise false
 */
export const validateEquality = (value_1, value_2) => {
  if (!checkString(value_1) || !checkString(value_2)) return false;

  return value_1 === value_2;
};
/**
 *
 * @param {*} value - value to be checked
 * @param {*} minLength - minimum length of the string
 * @returns true if string is valid and respect he minimum length, otherwise false
 */
export const validateGenericString = (value, minLength = 0) => {
  if (!checkString(value)) return false;

  return value.length >= minLength;
};
/**
 *
 * @param {*} value - value to be checked
 * @returns true if value is a valid italian phone number, otherwise false
 */
export const validatePhoneNumber = (phone) => {
  if (
    !validateGenericString(phone) ||
    //phone === "+393" ||
    phone.length < 12

    //Commented on 29/07/21 on Compass request
    //|| phone.length < 13
  )
    return false;
  try {
    const region = "IT";
    const value = phoneUtil.parseAndKeepRawInput(phone, region);
    return phoneUtil.isValidNumberForRegion(value, region);
  } catch (e) {
    return false;
  }
};

export const validateOnlyNumberAndSpaces = (val) => {
  const re = /^[0-9 ]{0,11}$/;
  return re.test(String(val).toLowerCase());
}