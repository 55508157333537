/**Helpers */
import { INDEX_URL, PAGES } from "../helpers/constants";
import history from "../helpers/history";

/**
 * Navigation wrapper around the library we actually use for navigation (currently history.js)
 * Useful for several reasons: maybe we want to use different providers depending on the env (dev, test, provd), or we plan to change library in the future.
 * Thanks to this wrapper we'll only change implementation in this file, leaving everything else intact (basically a kind of simplistic facade)
 */
const NavigationProvider = {
  /**
   *
   * @param {*} page - page where to go
   * @param {*} state - data to pass to the next page identified by parameter page
   * @param {*} previousPage - page which from navigation starts
   * The method permits to navigate to page passing state
   */
  goTo: (page, state = {}, previousPage = null) => {
    const pageSlug = window.location.pathname.substr(1, window.location.pathname.length);
    // avoid pushing the same page over and over again in the stack
    if (pageSlug !== page) {
      history.push(INDEX_URL + page, { ...state, previousPage });
    }
  },
  /**
   *
   * @param {*} url Url to open in new tab
   * The method open the param url in a new tab
   */
  openExternalLink: (url) => {
    window.open(url, "_blank");
  },
  /**
   *
   * @param {*} url Phone to open in dialer
   * The method open the param url in the dialer
   */
  openDialer: (url) => {
    window.open(url);
  },
  replace: (page) => {
    history.replace(INDEX_URL + page);
  },
  /**
   * The method permits to turn back to the previous page
   */
  goBack: () => {
    history.goBack();
  },
  /**
   * The method permits to go forward to the next page
   */
  goForward: () => {
    history.goForward();
  },

  // utilities
  /**
   * The method permits to go to the login page
   */
  goToLoginPage: () => {
    history.push(INDEX_URL + PAGES.LOGIN_STEP_1);
  },
  /**
   * The method permits to go to the user area dashboard (homepage when user is logged)
   */
  goToUserArea: () => {
    history.push(INDEX_URL + PAGES.USER_AREA);
  },
  /**
   *
   * @param {*} data - data to pass to the otp page (used when click "check otp" button)
   * @param {*} dataOTP - data used to call the getNewOtp API
   * The method permits to go to the OTP page passing some essential data
   */
  goToOTPPage: (data, dataOTP) => {
    history.push(INDEX_URL + PAGES.USER_AREA.OTP_FORM, { data, dataOTP });
  },
  /**
   *
   * @returns The state passed by history.location if exists, otherwise null/undefined
   */
  getStateFromHistory: () => {
    return history?.location?.state;
  },
  /**
   * The method open the calculate rate page on the external page
   */
  getCalculateRateLink: () => {
    window.open(
      "https://bancopostaonline.poste.it/bpol/bancoposta/PBLC/CartaBancopostaPiu/index.html?WT.ac=BP_SIMULBPPIU_OP_RATA_201308#bpol",
      "_blank"
    );
  },
};

export default NavigationProvider;
