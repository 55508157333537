/**Packages */
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**Components */
import HelperNumber from '../../../components/Elements/HelperNumber';
import PageTitle from '../../../components/Elements/PageTitle';
import TwoColumnsLayout from '../../../components/Layout/TwoColumnsLayout';
import ContentBox from '../../../components/Elements/ContentBox';
import UsefulNumbersComponent from '../../../components/Elements/UsefulNumbersComponent';
import Row from '../../../components/Elements/Row';
import UserCardCompact from '../../../components/Elements/UserCardCompact';

/**CSS */
import './style.css';


const customStyle = {
    minHeight: '650px',
};

const UserUsefulNumbers = () => {
    const { t, i18n, ready } = useTranslation();
    return (
        <>
            <TwoColumnsLayout id="user-useful-numbers">
                <Row gridSize="md" classList="space-below-md">
                    <UserCardCompact columns={['productType', 'ticketNumberAndExpiration']} classList="text-left"></UserCardCompact>
                </Row>

                <PageTitle title="Numeri utili per assistenza" />

                <UsefulNumbersComponent />
            </TwoColumnsLayout>
        </>
    );
};

export default UserUsefulNumbers;