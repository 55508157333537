const urls = {
  loginStep1: "Login",
  loginStep2: "VerifyLogin",
  userInfo: "UserInfo",
  signup: "Signup",
  transactions: "Transactions",
  resumes: "Resumes",
  getOtp: "GetOtp",
  getCardsData: "GetCardsData",
  transfer: "Transfer",
  SetECMethod: "SetECMethod",
  SMSActivation: "SMSActivation",
  user: "User",
  customCare: "Customercare",
  recoveryPassword: "RecoveryPassword",
  editPassword: "EditPassword",
  pdfDownload: "GetEcPdf",
};

export { urls };
