/**Packages */
import moment from "moment";

/**
 *
 * @param {*} transaction - data
 * @returns true if transaction has parameter data, otherwise false
 */
const validTransaction = (transaction) => {
  return Object.prototype.hasOwnProperty.call(transaction, "data");
};
/**
 *
 * @param {*} a - first transaction
 * @param {*} b - second transaction
 * @returns 1 if a is after b, -1 if b is after b otherwise 0
 */
const dateDesc = (a, b) => {
  if (validTransaction(a) && validTransaction(b)) {
    return moment(a.data).isAfter(b.data) ? 1 : moment(a.data).isBefore(b.data) ? -1 : 0;
  }

  return 0;
};
/**
 *
 * @param {*} a - first transaction
 * @param {*} b - second transaction
 * @returns -1 if a is after b, 1 if b is after b otherwise 0
 */
const dateAsc = (a, b) => {
  if (validTransaction(a) && validTransaction(b)) {
    return moment(a.data).isAfter(b.data) ? -1 : moment(a.data).isBefore(b.data) ? 1 : 0;
  }

  return 0;
};
/**
 *
 * @param {*} arr - array of movements
 * @param {*} desc - true if descendent order, otherwise ascendent
 * @returns arr ordered by parameter desc
 */
export const orberByData = (arr, desc = false) => {
  if (!(arr || Array.isArray(arr)) || arr.length === 0) return [];

  return arr.sort(desc ? dateDesc : dateAsc);
};
