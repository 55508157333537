/**Routes */
import user from "./user";
import movement from "./movement";
import operation from "./operation";
import customCare from "./customCare";

export const api = {
  user,
  movement,
  operation,
  customCare,
};
