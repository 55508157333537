/**Helpers */
import { getIdxProductFromArray } from "../../helpers/user";

/**Redux */
import { SET_PRODUCTS, DELETE_ALL_PRODUCTS, UPDATE_PRODUCT } from "../actionTypes/productsActionsTypes";

const initialState = [];

export const productsReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SET_PRODUCTS: {
      const data = payload?.data;

      return [...data];
    }
    case UPDATE_PRODUCT: {
      const { id, newData } = payload?.data;

      if (id) {
        const selectedProductIdx = getIdxProductFromArray(state, id);

        if (selectedProductIdx >= 0) {
          state[selectedProductIdx] = Object.assign(state[selectedProductIdx], newData);
          return [...state];
        }
      } else {
        return {
          ...state,
        };
      }

      break;
    }
    case DELETE_ALL_PRODUCTS: {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};
