/**Packages */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import TextLink from "../../../components/Elements/TextLink";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import FloatingInput from "../../../components/Form/FloatingInput";
import Form from "../../../components/Form/Form";

/**Helpers */
import { OTP_CASES, EC_METHODS } from "../../../helpers/constants";
import { getOTPProcedure } from "../../../helpers/handler";
import { validateEmail } from "../../../helpers/validators";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const getMode = (modInvioEC, modInvioECDESC) => {
  return modInvioEC && modInvioEC.trim() !== ""
    ? modInvioEC.trim()
    : modInvioECDESC
      ? modInvioECDESC[0].toUpperCase()
      : "";
};

const ChangeStandingEc = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const { selectedCard, token, email: userEmail } = user;
  const { id = null, modInvioEC = "", modInvioECDESC = "" } = selectedCard;

  const [mode, setMode] = useState(getMode(modInvioEC, modInvioECDESC));
  const [email, setEmail] = useState(userEmail);
  const [formValidated, setFormValidated] = useState(false);

  const onChangeEmailHandler = (e) => {
    const newValue = e?.target?.value;
    if (newValue?.length >= 0) {
      setEmail(newValue);
    }
  };

  const onClickHandler = async (newMethod) => {
    activateLoader();

    const onSuccessHandler = async (data) => {
      const dataToPass =
        newMethod === EC_METHODS.CARTACEO
          ? {
            clickCase: OTP_CASES.EC_METHOD,
            token,
            card_id: id,
            mode: EC_METHODS.CARTACEO,
          }
          : {
            clickCase: OTP_CASES.EC_METHOD,
            token,
            card_id: id,
            mode: EC_METHODS.INTERNET,
            email_cc_receiver: email,
          };
      NavigationProvider.goToOTPPage(dataToPass, {
        token,
        id,
      });
      deactivateLoader();
    };

    const onErrorHandler = (data) => {
      deactivateLoader();
      setErrorMessage("Errore", "Errore durante la procedura di invio dell'otp, riprovare più tardi");
    };

    await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
  };

  const getFullStrOpposite = (method) => {
    if (method === EC_METHODS.INTERNET) return "cartaceo";

    return "online";
  };

  useEffect(() => {
    let value = true;
    if (mode === EC_METHODS.CARTACEO) {
      value = validateEmail(email);
    }

    setFormValidated(value);
  }, [email]);

  return (
    <TwoColumnsLayout id="change-ec-standing-page">
      <Row gridSize="md" classList="space-below-md">
        <UserCardCompact columns={["productCode", "currentEcMode"]} classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="">
        <PageTitle inner title={"richiesta estratto conto " + getFullStrOpposite(mode)}>
          {mode !== EC_METHODS.CARTACEO && (
            <p className="text-clear-formatting">
              <b>
                Attenzione:
                <br />
                tornando alla modalità cartacea ti verrà addebitato il costo di spedizione per ciascun invio come da
                condizioni contrattuali della tua carta.
              </b>
            </p>
          )}
        </PageTitle>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox withTitle={rp.getResource("changeStandingEc.confirmText")} compact classList="space-below-lg">
          <br />
          {mode !== EC_METHODS.CARTACEO && mode === EC_METHODS.INTERNET && (
            <div className="inline-on-desktop btn-container">
              <Button
                round
                outline
                text="annulla"
                classList="btn-block"
                onClick={() => {
                  NavigationProvider.goBack();
                }}
              />
              <Button
                round
                text="conferma"
                classList="btn-block"
                isEnabled={true}
                onClick={() => {
                  onClickHandler(EC_METHODS.CARTACEO);
                }}
              />
            </div>
          )}
          {mode !== EC_METHODS.INTERNET && (
            <Form
              submitText="Conferma"
              noSubmit
              classList="space-below-md"
              onSubmit={() => {
                onClickHandler(EC_METHODS.EMAIL);
              }}
            >
              <FloatingInput
                name="userEmail"
                placeholder="E-mail"
                validIf={['email', 'notEmpty']}
                value={email}
                onChange={onChangeEmailHandler}
              />
              {/* <h6 className="tos-note">
                Con l’adesione al servizio confermo di aver preso visione dei <TextLink inline text="Termini" /> e{" "}
                <TextLink inline text="Condizioni del Servizio" />
              </h6> */}

              <div className="inline-on-desktop btn-container">
                <Button
                  type="reset"
                  round
                  outline
                  text="annulla"
                  classList="btn-block"
                  onClick={() => {
                    NavigationProvider.goBack();
                  }}
                />
                <Button
                  // isEnabled={formValidated}
                  type="submit"
                  round
                  text="conferma"
                  classList="btn-block"
                />
              </div>
            </Form>
          )}
        </ContentBox>

        <Row gridSize="md" classList="space-below-lg">
          <p className="text-light-gray">
            {rp.getResource("changeStandingEc.helperTextOne")}
            <br />
            <br />
            {rp.getResource("changeStandingEc.helperTextTwo")}
          </p>
        </Row>
      </Row>
    </TwoColumnsLayout>
  );
};
export default ChangeStandingEc;
