/**Packages */
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import UserCard from "../../../components/Elements/UserCard";
import MovementItemList from "../../../components/Elements/MovementItemList";
import MovementExpandedItemList from "../../../components/Elements/MovementExpandedItemList";
import NavigationCard from "../../../components/Elements/NavigationCard";

/**Helpers */
import { PAGES, MAX_ITEMS_HOME } from "../../../helpers/constants";
import { getReduxMonthYearKey } from "../../../helpers/utils";
import { orberByData } from "../../../helpers/order";
import { getLastAccess } from "../../../helpers/lastAccess";
import { getMovimentiProcedure, getResumesProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setMovementsMonthYear } from "../../../redux/actions/movementsActions";
import { setResumes } from "../../../redux/actions/resumesActions";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const Dashboard = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setMovementsRedux = (movementsData, month = null, year = null) => {
    dispatch(setMovementsMonthYear(month, year, movementsData));
  };
  const setResumesRedux = (resumes) => {
    dispatch(setResumes(resumes));
  };
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const movements = useSelector((state) => state?.movements);
  const resumes = useSelector((state) => state.resumes);
  const viaggiantiMovements = movements[getReduxMonthYearKey()];

  const { name, surname, lastAccess, cf, cards, selectedCard, token } = user;
  const { id = null, pan } = selectedCard;

  const getMovements = async () => {
    activateLoader();
    if (id) {
      const on200Callback = (data) => {
        //setMovementsRedux(data);
        deactivateLoader();
      };

      const on500Callback = (data) => {
        deactivateLoader();
        setErrorState("Errore", "Errore durante il download dei movimenti del mese, ritenta più tardi");
      };

      await getMovimentiProcedure(token, null, null, id, on200Callback, on500Callback, setMovementsRedux);
    } else {
      setMovementsRedux([]);
      deactivateLoader();
    }
  };

  const getResumes = async () => {
    activateLoader();
    if (id) {
      const on200Callback = (data) => {
        //setResumesRedux(data?.ec);
        deactivateLoader();
      };

      const on500Callback = (data) => {
        deactivateLoader();
        setErrorState("Errore", "Errore durante il download dei movimenti del mese, ritenta più tardi");
      };

      await getResumesProcedure(token, 0, 0, id, on200Callback, on500Callback, setResumesRedux);
    } else {
      setResumesRedux({});
      deactivateLoader();
    }
  };

  useEffect(async () => {
    await getMovements();
    await getResumes();
  }, []);

  /*
  const onMonthTitleClick = (e) => {
    if (getCurrentPage().indexOf(PAGES.USER_AREA.MOVEMENTS) === -1)
      history.push(INDEX_URL + PAGES.USER_AREA.INDEX + "/" + PAGES.USER_AREA.MOVEMENTS);
  };
  */
  const onMonthClick = (e, monthYear, month, year) => {
    NavigationProvider.goTo(
      PAGES.USER_AREA.MOVEMENTS_MONTH,
      {
        monthYear,
        month,
        year,
      },
      "Dashboard"
    );
  };

  const getMovementsSliced = () => {
    if (viaggiantiMovements?.acquisti) {
      return orberByData(viaggiantiMovements?.acquisti).slice(0, MAX_ITEMS_HOME);
    }

    return [];
  };

  const getResumesSliced = () => {
    const keys = Object.keys(resumes);
    if (keys?.length > 0) {
      return keys.slice(0, MAX_ITEMS_HOME).map((itm) => {
        return resumes[itm];
      });
    }

    return {};
  };

  return (
    <TwoColumnsLayout classList="private-page">
      <Row gridSize="md" classList="space-below-md">
        <UserCard classList="text-left">
          <PageTitle
            inner
            title={`Bentornato ${name} ${surname}`}
            subtitle={`ultimo accesso ${getLastAccess(lastAccess)}`}
          />
        </UserCard>
      </Row>

      <Row gridSize="md" classList="tablet-only">
        <PageTitle
          inner
          title={`Bentornato ${name} ${surname}`}
          subtitle={`ultimo accesso ${getLastAccess(lastAccess)}`}
        />
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox withTitle="ultimi movimenti" compact>
          <MovementItemList elements={getMovementsSliced()} />
        </ContentBox>
      </Row>

      {rp.getResource("dashboard.resumesSeciion") && (
        <Row gridSize="md" classList="space-below-md">
          <ContentBox withTitle="Ultimi estratti conto" compact>
            <MovementExpandedItemList elements={getResumesSliced()} onClick={onMonthClick} />
          </ContentBox>
        </Row>
      )}

      <FlexRow classList="no-padding cols-equal responsive">
        <Row gridSize="md" classList="space-below-sm ">
          <NavigationCard path={PAGES.USER_AREA.MOVEMENTS} title="movimenti" />
        </Row>
        <Row gridSize="md" classList="space-below-sm">
          <NavigationCard path={PAGES.USER_AREA.ACCOUNT_BALANCE} title="estratto conto" />
        </Row>
        <Row gridSize="md" classList="space-below-sm">
          <NavigationCard path={PAGES.USER_AREA.BANK_TRANSFER} title={rp.getResource("header.bankAdvanceTitle")} />
        </Row>
      </FlexRow>
    </TwoColumnsLayout>
  );
};
export default Dashboard;
