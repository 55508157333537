/**Helpers */
import { getBaseNameURL } from "./basename";

/**
 *
 * @param {*} url - relative link of the image
 * @returns Link to the image in absolute path (like: http://example.com/image.jpg)
 */
export const getImage = (url) => {
  if (!url || url === "") return null;

  return `${getBaseNameURL()}/${url}`;
};
