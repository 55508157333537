/**Packages */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import MovementsFilterableList from "../../../components/Elements/MovementsFilterableList";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { getResumesProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setResumes } from "../../../redux/actions/resumesActions";

/**CSS */
import "./style.css";

const AccountBalance = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setResumesRedux = (resumes) => {
    dispatch(setResumes(resumes));
  };
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const resumes = useSelector((state) => state.resumes);
  const { selectedCard, token } = user;
  const { id = null } = selectedCard;

  const onMonthClick = (e, monthYear, month, year) => {
    NavigationProvider.goTo(PAGES.USER_AREA.MOVEMENTS_MONTH, {
      monthYear,
      month,
      year,
    }, "Estratti conto");
  };

  const getResumes = async () => {
    activateLoader();
    if (id) {
      const on200Callback = (data) => {
        //setResumesRedux(data?.ec);
        deactivateLoader();
      };

      const on500Callback = (data) => {
        deactivateLoader();
        setErrorState("Errore", "Errore durante il download dei movimenti del mese, ritenta più tardi");
      };

      await getResumesProcedure(token, 0, 0, id, on200Callback, on500Callback, setResumesRedux);
    } else {
      setResumesRedux({});
      deactivateLoader();
    }
  };

  useEffect(async () => {
    await getResumes();
  }, []);

  return (
    <TwoColumnsLayout>
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left" />
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <PageTitle inner title="estratti conto" />
      </Row>

      <MovementsFilterableList
        formTitle={rp.getResource("accountBalance.formTitle")}
        onClick={onMonthClick}
        elements={resumes}
      />
    </TwoColumnsLayout>
  );
};
export default AccountBalance;
