/**Action Types*/
import { WARNING_VISIBLE, WARNING_INVISIBLE } from "../actionTypes/warningActionsTypes";

/**
 *
 * @param {*} title - title of the warning
 * @param {*} message - message of the warning
 * @returns The action to be dispatched to view the warning dialog
 */
export const setWarning = (title, message) => {
  return {
    type: WARNING_VISIBLE,
    payload: {
      title,
      message,
    },
  };
};
/**
 *
 * @returns The action to be dispatched to expire the warning dialog
 */
export const expireWarning = () => {
  return {
    type: WARNING_INVISIBLE,
  };
};
