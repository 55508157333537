/**Packages */
import i18n from "i18next";

/**
 *
 * @param {*} newLng - new lng to set
 * The method permits to change the user interface language
 */
export const changeLang = (newLng) => {
  const isNewLngSupported = i18n.options.supportedLngs?.find((lng) => {
    return lng === newLng;
  });
  if (isNewLngSupported) {
    i18n.changeLanguage(newLng, (error) => {
      if (error) {
        console.log("error in changing language:", error);
        return;
      }
      localStorage.setItem("lang", newLng);
      //Strings are aupdated automatically
    });
    //Setting language in LocalStorage
  }
};
/**
 *
 * @returns Return the current language
 */
export const getCurrentLng = () => {
  return localStorage.getItem("lang") || i18n.language || navigator.language.slice(0, 2);
};
/**
 *
 * @returns Return the enabled languages
 */
export const getLanguages = () => {
  return ["it", "en"];
};
/**
 * @returns return the formatted PUBLIC_URL for languages
 */
export const getFormattedPublicUrl = () => {
  if (!process.env.PUBLIC_URL || process.env.PUBLIC_URL === "") return "";

  const publicUrl = process.env.PUBLIC_URL;
  return publicUrl[publicUrl.length - 1] === "/" ? publicUrl : `${publicUrl}/`;
};
