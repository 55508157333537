/**Action Types*/
import { SUCCESS_VISIBLE, SUCCESS_INVISIBLE } from "../actionTypes/successActionsTypes";

/**
 *
 * @param {*} title - title of the success
 * @param {*} message - message of the success
 * @returns The action to be dispatched to view the success dialog
 */ export const setSuccess = (title, message) => {
  return {
    type: SUCCESS_VISIBLE,
    payload: {
      title,
      message,
    },
  };
};
/**
 *
 * @returns The action to be dispatched to expire the success dialog
 */
export const expireSuccess = () => {
  return {
    type: SUCCESS_INVISIBLE,
  };
};
