/**CSS */
import "./style.css";

const FullWidthLayout = ({ id = "", children }) => {
  return (
    <div id={id} className="page-main-content-container">
      <div className="cpar-flex-container">{children}</div>
    </div>
  );
};

export default FullWidthLayout;
