/**Packages */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import ContentBox from "../../../components/Elements/ContentBox";

/**Helpers */
import { OTP_CASES } from "../../../helpers/constants";
import { validatePhoneNumber, validateOnlyNumberAndSpaces } from "../../../helpers/validators";
import { getOTPProcedure } from "../../../helpers/handler";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const SmsActivation = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const { selectedCard, token } = user;
  const {
    id = null,
    crSidImpSogliaAut = null,
    crSidAvvisoAut = "N",
    crSidServizioAggEC = "N",
    crSidCellulare = "",
  } = selectedCard;

  const [formValidated, setFormValidated] = useState(false);
  const [SMSOnPaymentBiggerThen, setSMSOnPaymentBiggerThen] = useState(crSidAvvisoAut === "S" ? true : false);
  const [SMSECsend, setSMSECsend] = useState(crSidServizioAggEC === "S" ? true : false);
  const [SMSPaymentAmount, setSMSPaymentAmount] = useState(crSidImpSogliaAut);
  const [SMSPhone, setSMSPhone] = useState(crSidCellulare);

  const onChangeSMSPaymentAmount = (e) => {
    const newValue = e?.target?.value;

    if (!validateOnlyNumberAndSpaces(newValue)) return;

    setSMSPaymentAmount(newValue);
  };

  const onChangeSMSPhone = (e) => {
    const newValue = e?.target?.value;

    if (!validateOnlyNumberAndSpaces(newValue)) return;

    setSMSPhone(newValue);
  };

  const onClickHandler = async (e) => {
    e.preventDefault();

    if (formValidated) {
      activateLoader();

      const onSuccessHandler = async (data) => {
        NavigationProvider.goToOTPPage(
          {
            clickCase: OTP_CASES.SMS_ACTIVATION,
            token,
            card_id: id,
            min_amount: SMSPaymentAmount,
            alertNuovaAutorizzazione: SMSOnPaymentBiggerThen,
            alertCalcoloNuovoEC: SMSECsend,
            phone_number: SMSPhone,
          },
          {
            token,
            id,
          }
        );
        deactivateLoader();
      };

      const onErrorHandler = (data) => {
        deactivateLoader();
        setErrorMessage("Errore", "Errore durante la procedura di invio dell'otp, riprovare più tardi");
      };

      await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
    }
  };

  const validateInput = () => {
    let validated = true;

    if (
      ((SMSOnPaymentBiggerThen || SMSECsend) && !validatePhoneNumber("+39" + SMSPhone)) ||
      (SMSOnPaymentBiggerThen && SMSPhone.length <= 0)
    ) {
      validated = false;
    }

    setFormValidated(validated);
  };

  useEffect(() => {
    validateInput();
  }, [SMSPhone, SMSOnPaymentBiggerThen, SMSECsend]);

  return (
    <TwoColumnsLayout id="sms-activation-page">
      <Row gridSize="md" classList="space-below-md">
        <UserCardCompact columns={["productType", "availability"]} classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="">
        <PageTitle inner title="attivazione sms" />
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox compact>
          <Form noSubmit onSubmit={onClickHandler} submitText="Conferma" classList="stack">
            <div className="always-inline">
              <label htmlFor="sendSmsForTransactionAmount">
                <input
                  name="sendSmsForTransactionAmount"
                  id="sendSmsForTransactionAmount"
                  defaultChecked={SMSOnPaymentBiggerThen}
                  onChange={() => setSMSOnPaymentBiggerThen(!SMSOnPaymentBiggerThen)}
                  type="checkbox"
                />
                {rp.getResource("smsActivation.sendSmsForTransactionAmountText")}
              </label>
            </div>

            {rp.getResource("smsActivation.sendSmsForTransactionAmountField") && (
              <>
                <FloatingInput
                  classList="_indent"
                  id="amount"
                  name="amount"
                  placeholder="€"
                  onChange={onChangeSMSPaymentAmount}
                  value={SMSPaymentAmount}
                  validIf={['notEmpty']}
                  required={true}
                />
                <p className="_indent space-below-lg">
                  *Il costo di ciascun sms opzionale, se le notifiche sono impostate per importi inferiori ad Euro 180, varia in base al piano tariffario del proprio operatore telefonico.
                </p>
              </>
            )}

            <div className="always-inline space-below-md">
              <label htmlFor="sendSmsForUpdates">
                <input
                  name="sendSmsForUpdates"
                  id="sendSmsForUpdates"
                  defaultChecked={SMSECsend}
                  onChange={() => setSMSECsend(!SMSECsend)}
                  type="checkbox"
                />
                <span>{rp.getResource("smsActivation.sendSmsForUpdatesText")}</span>
              </label>
            </div>

            <p className="_indent-on-tablet">
              <strong>Le notifiche via SMS saranno inviate al numero</strong>
            </p>

            <FloatingInput
              classList="_indent-on-tablet"
              name="phone"
              placeholder="Numero di telefono"
              onChange={onChangeSMSPhone}
              value={SMSPhone}
              required={true}
            />

            <Row classList="center-on-mobile">
              <Button type="submit" round text="conferma" />
            </Row>
          </Form>
        </ContentBox>
      </Row>
    </TwoColumnsLayout>
  );
};
export default SmsActivation;
