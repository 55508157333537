/**Components */
import ContentBox from "../ContentBox";
import FlexRow from "../FlexRow";
import HelperNumber from "../HelperNumber";

/**Helpers */
import { default as rp } from "../../../providers/ResourceProvider";

/**CSS */
import "./style.css";

const UsefulNumbersComponent = ({ classList = "", children }) => {
    const leftNumbers = rp.getResource("usefulNumbers.leftColumn");

    let rightNumbers = [
        {
            text: "Fido Affari BancoPosta",
            number: "800 160 160",
        },
        {
            text: "Fido Pos BancoPosta",
            number: "800 160 160",
        },
    ];
    if (rp.getProductType() == "cartaBpp") {
        rightNumbers = [
            {
                text: "Carta BancoPosta Più",
                number: "06 45263322",
            },
        ];
    }

    const classes = `user-card-header ${classList}`;
    return (
        <>
            <FlexRow gridSize="md" classList="gap cols-equal responsive space-below-lg">
                <ContentBox withTitle="Numeri assistenza" classList="cpar-flex-col mobile-full-width">
                    {leftNumbers.map((itm, idx) => {
                        return <HelperNumber key={idx} {...itm} />;
                    })}

                    {rp.getResource("usefulNumbers.helperText") && (
                        <>
                            <br />
                            <small>Segui la voce guida e scegli “servizi dedicati a professionisti, Ditte individuali e PMI”</small>
                        </>
                    )}
                </ContentBox>

                <ContentBox withTitle="Numeri utili" classList="cpar-flex-col mobile-full-width">
                    {rightNumbers.map((itm, idx) => {
                        return <HelperNumber key={idx} {...itm} />;
                    })}
                </ContentBox>
            </FlexRow>
            {rp.getResource("usefulNumbers.conditionsText") && (
                <FlexRow>
                    <br />

                    <small>
                        **Per chiamate da rete fissa, il costo massimo è di 14,26 centesimi al minuto, con uno scatto alla risposta di 6,19 centesimi. Per chiamate da rete mobile, il costo massimo è
                        di 48 centesimi al minuto, con uno scatto alla risposta di 15,49 centesimi. Per chiamate dall’estero +39 02 454 67 925.
                    </small>
                </FlexRow>
            )}
        </>
    );
};

export default UsefulNumbersComponent;
