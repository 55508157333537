/**
 *
 * @returns Get valuta from the .env file
 */
export const getValuta = () => {
  return process.env.REACT_APP_BASE_VALUTA;
};

/**
 *
 * @param {*} value - value to format
 * @returns Teh value formatted in euros
 */
export const getValueWithValuta = (value) => {
  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });
  return `€ ${formatter.format(value).replace("€", "")}`;
};
