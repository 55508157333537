/**Packages */
import { request } from "monforteclient";

/**Helpers */
import { createErrorResponse } from "../helpers/response";
import { getAxiosHeadersConfig } from "../helpers/request";
import config from "../helpers/config";

const customCare = {
  /**
   *
   * @param {*} param0 - { token, email_receiver, topic, text }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  customCare: async ({ token, email_receiver, topic, text }, on200Callback, on500Callback) => {
    const body = {
      email_receiver,
      topic,
      text,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.customCare}`,
        language: "it",
        method: "POST",
        data: JSON.stringify(body),
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.customCare);
    }
  },
};

export default customCare;
