/**Components */
import TextLink from "../../Elements/TextLink";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**CSS */
import "./style.css";

const TwoColumnsLayout = ({ id = "", children }) => {
  const onSideMenuItemClick = (nodeId, page) => {
    resetNavigationLink();
    setTimeout(() => {
      const nodes = document.getElementsByClassName(nodeId);
      for (let idx = 0; idx < nodes.length; idx++) {
        nodes[idx].classList.add("strong");
      }
    }, 100);
    NavigationProvider.goTo(page);
  };

  const resetNavigationLink = () => {
    const linkAreas = [
      "#header-navigation .text-link.strong",
      "#user-side-menu .text-link.strong",
      "#header-navigation-mobile .text-link.strong",
      "#header-user-actions .text-link.strong",
    ];

    linkAreas.forEach((l) => {
      let allNodes = document.querySelectorAll(l);
      allNodes.forEach((n) => {
        n.classList.remove("strong");
      });
    });
  };

  return (
    <div id={id} className="two-columns-page-main-content-container">
      <div className="left-column-flex-container">{children}</div>
      <div className="right-column-flex-container">
        <ul id="user-side-menu">
          <li>
            <TextLink
              onClick={() => {
                onSideMenuItemClick("side-menu-change-standing-eq", PAGES.USER_AREA.CHANGE_STANDING_EC);
              }}
              classList="text-uppercase side-menu-change-standing-eq"
              text="modifica invio e/c"
            />
          </li>
          <li>
            <TextLink
              onClick={() => {
                onSideMenuItemClick("side-menu-sms", PAGES.USER_AREA.SMS_ACTIVATION);
              }}
              classList="text-uppercase side-menu-sms"
              text="attivazione sms"
            />
          </li>
          {/* <li>
            <TextLink
              onClick={() => {
                NavigationProvider.openExternalLink(rp.getResource("externalLinks.transparency"));
              }}
              classList="text-uppercase"
              text="trasparenza"
            />
          </li> */}
          <li>
            <TextLink
              onClick={() => {
                onSideMenuItemClick("side-menu-support", PAGES.USER_AREA.SUPPORT);
              }}
              classList="text-clear-formatting text-black side-menu-support"
              text="Servizio clienti"
            />
          </li>
          <li>
            <TextLink
              onClick={() => {
                onSideMenuItemClick("side-menu-numbers", PAGES.USER_AREA.USER_USEFUL_NUMBERS);
              }}
              classList="text-clear-formatting text-black side-menu-numbers"
              text="Assistenza"
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TwoColumnsLayout;
