import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import Button from "../../../components/Elements/Button";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";

/**Helpers */
import { INDEX_URL, PAGES } from "../../../helpers/constants";
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const AccountAccess = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();

  /**
   * Handles Login Form submission
   *
   * @function onLoginFormSubmit
   * @param {SyntheticEvent} submitEvent - it contains the event target (form element) we can use to access its fields' values by the name attribute
   * @return {Void}
   */
  // const goToAccountPage = () => {
  //     history.push(INDEX_URL + PAGES.SIGNUP);
  // };

  return (
    <>
      <FullWidthLayout>
        <PageTitle small title="dati anagrafici" />

        <Row gridSize="sm" classList="">
          <ContentBox compact fullWidthOnMobile classList="text-center">
            <p className="_grid-ms">
              Gentile cliente, la pagina presente richiede l’inserimento dell’OTP
              <br /> per essere visualizzata
            </p>
            <br />

            <FlexRow classList="_grid-ms cols-equal gap responsive">
              <div className="cpar-flex-col">
                <Button onClick={NavigationProvider.goBack} round outline fullWidth text="Annulla" />
              </div>
              <div className="cpar-flex-col">
                <Button
                  round
                  fullWidth
                  text="richiedi otp"
                  onClick={() => {
                    NavigationProvider.goTo(PAGES.USER_AREA.ACCOUNT_ACCESS_OTP);
                  }}
                />
              </div>
            </FlexRow>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};

export default AccountAccess;
