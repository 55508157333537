/**Packages */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { recoveryPasswordProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const ForgotPassword = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorState = (title, message) => dispatch(setError(title, message));

  // const [formValidated, setFormValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [userCF, setUserCF] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // useEffect(() => {
  //   validateInput();
  // });

  // const validateInput = () => {
  //   let validated = true;
  //   if (username === "" || userCF === "" || userEmail === "") {
  //     validated = false;
  //   }
  //   setFormValidated(validated);
  // };

  const usernameChangeHandler = (event, value) => {
    setUsername(value);
  };

  const userCFChangeHandler = (event, value) => {
    setUserCF(value);
  };

  const userEmailChangeHandler = (event, value) => {
    setUserEmail(value);
  };

  const recoverPasswordHandler = async () => {
    // if (formValidated) {
    activateLoader();
    const onSuccessCallback = (data) => {
      deactivateLoader();
      // NavigationProvider.goTo(PAGES.FORGOT_PASSWORD_STEP_2, { username, guid: data.guid });
      NavigationProvider.goTo(PAGES.FORGOT_PASSWORD_ACTIVATE, { username, guid: data.guid });
    };

    const onErrorCallback = (err = "Errore durante la procedura di recupero della password, ritentare più tardi") => {
      deactivateLoader();
      console.log(err);
      setErrorState("Errore", typeof err == "string" ? err : "");
    };

    await recoveryPasswordProcedure(username, userEmail, userCF, onSuccessCallback, onErrorCallback);
    // }
  };

  return (
    <FullWidthLayout id="forgot-password-page">
      <PageTitle
        small
        title="Reimposta password"
        subtitle="Per reimpostare la tua password inserisci i tuoi dati per permetterci di recuperare il tuo account: ti invieremo la nuova password all’indirizzo e-mail utilizzato in fase di registrazione."
      />

      <Row gridSize="xs" classList="space-below-md">
        <ContentBox compact fullWidthOnMobile classList="text-center">
          <Form submitText={"Reimposta"} onSubmit={recoverPasswordHandler}>
            <FloatingInput
              name="username"
              placeholder={t("user_username")}
              validIf={['notEmpty']}
              value={username}
              onChange={usernameChangeHandler}
            />
            <FloatingInput
              name="userCF"
              placeholder="Codice Fiscale"
              validIf={['minLength--16', 'maxLength--16', 'notEmpty']}
              value={userCF}
              onChange={userCFChangeHandler}
            />
            <FloatingInput
              name="userEmail"
              placeholder="Email"
              validIf={['email', 'notEmpty']}
              value={userEmail}
              onChange={userEmailChangeHandler}
            />
          </Form>
        </ContentBox>
      </Row>
    </FullWidthLayout>
  );
};
export default ForgotPassword;
