import { LOADER_VISIBLE, LOADER_INVISIBLE } from './../actionTypes/loaderActionsTypes';

const initialState = {
    loaderVisible: false,
};

export const loaderReducer = function (state = initialState, action) {
    switch (action.type) {
        case LOADER_VISIBLE:
            return {
                ...state,
                loaderVisible: true,
            };
        case LOADER_INVISIBLE:
            return {
                ...state,
                loaderVisible: false,
            };
        default:
            return state;
    }
};
