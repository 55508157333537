/**Packages */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { loginProcedureStep1 } from "../../../helpers/handler";

/**Redux */
import { setTokenOTP } from "../../../redux/actions/tokenActions";
import { setError } from "../../../redux/actions/errorActions";

import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**CSS */
import "./style.css";
import { getImage } from "../../../helpers/image";

/**
 *
 * User to use to login
 *
 *
 * BZZMRC74B10D612N
 *
 * CRRGNN77E04F799H
 *
 * CNOTCS48L27E216G
 *
 * CRRGNN77E04F799H
 *
 * PPACST68E66F572F
 *
 * CHFDNC69H06L049N
 *
 * DMNLND51T23C933N -> Collaudo ok
 *
 *
 *
 *
 *
 * Password Jolly: MP3Hyb39
 * OTP Jolly: MP3Hyb39
 */

const LoginStep1 = () => {
  const { t, i18n, ready } = useTranslation();
  const [formValidated, setFormValidated] = useState(false);
  const [userid, setUserid] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [productLogoImage, setProductLogoImage] = useState("");

  /* 
  Since the user is not logged in yet, we need to check product type based on the url
  possible params are:
  - ?pt=cartaBpp
  - ?pt=fidoPos
  - ?pt=fidoAffari
  */
  useEffect(() => {
    // @tmp use querystring workaround until we have some actual urls to test
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // const productTypeBasedOnUrl = params.pt || "cartaBpp";
    // replace code above with something that checks for a substr in the URL, e.g:
    const href = window.location.href.toLowerCase();
    const productTypeBasedOnUrl = href.includes("fidoaffari") ? "fidoAffari" : href.includes("fidopos") ? "fidoPos" : "cartaBpp";

    // this approach lets ResourceProvider work as expected. 
    // After login the sessionStorage will be overridden by the actual user.productType
    sessionStorage.setItem("productType", productTypeBasedOnUrl);
    setProductLogoImage(getImage(rp.getResource("products.logo")))
  }, []);

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setTokenOTPState = (tokenOTP) => dispatch(setTokenOTP(tokenOTP));
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const loginClickHandler = async () => {
    activateLoader();
    console.log("loginClickHandler");

    const onSuccessCallback = async (result) => {
      deactivateLoader();
      console.log("onSuccessCallback");
      if (result?.tokenOTP) {
        NavigationProvider.goTo(PAGES.LOGIN_STEP_2);
      } else {
        // console.error("something went wrong with the request");
        onErrorCallback(result?.description);
      }
    };

    const onErrorCallback = (err) => {
      deactivateLoader();
      console.log("onErrorCallback");
      setErrorState("Errore durante la fase di login", err);
    };

    await loginProcedureStep1(
      userid,
      userPassword,
      onSuccessCallback,
      onErrorCallback,
      setTokenOTPState
    );
  };

  const userIdChangeHandler = (event, value) => {
    setUserid(value);
  };

  const userPasswordChangeHandler = (event, value) => {
    setUserPassword(value);
  };

  return (
    <>
      <FullWidthLayout id="login-step-1">
        <FlexRow
          id="products-logo-container"
          gridSize="md"
          classList="cols-equal responsive space-below-xs"
        >
          <div className="flex-equal icon-container text-center space-below-lg">
            <img src={productLogoImage} alt="" />
          </div>
          {/* <div className="flex-equal icon-container text-center space-below-lg">
            <img src={getImage("images/logo/affari.svg")} alt="" />
          </div> */}
        </FlexRow>

        <PageTitle title={t("Welcome!")} subtitle={t("login_description")} />

        <Row gridSize="xs" classList="space-below-md">
          <ContentBox fullWidthOnMobile classList="text-center">
            <Form submitText={t("login")} onSubmit={loginClickHandler}>
              <FloatingInput
                name="userId"
                validIf={["minLength--5", "notEmpty"]}
                placeholder={t("user_username")}
                value={userid}
                onChange={userIdChangeHandler}
              />
              <FloatingInput
                type="password"
                name="userPassword"
                validIf={["notEmpty"]}
                placeholder={t("user_password")}
                value={userPassword}
                onChange={userPasswordChangeHandler}
              />
            </Form>

            <p>Non ricordi le credenziali?</p>
            <TextLink
              onClick={() => {
                NavigationProvider.goTo(PAGES.FORGOT_PASSWORD);
              }}
              strong
              text={t("recover password")}
            />
            <TextLink
              onClick={() => {
                NavigationProvider.goTo(PAGES.SIGNUP);
              }}
              strong
              text="Se hai dimenticato il tuo nome utente registrati nuovamente"
            />
          </ContentBox>
        </Row>

        <Row gridSize="xs" classList="text-center">
          <p>{t("not registered")}</p>
          <TextLink
            onClick={() => {
              NavigationProvider.goTo(PAGES.SIGNUP);
            }}
            strong
            text={t("registration")}
          />
        </Row>
      </FullWidthLayout>

      {/* this node will always be rendered at the bottom of the page, no matter the content length */}
      <div className="stick-to-bottom">
        <Row gridSize="md" classList="text-center">
          <p>
            Per accedere a questo portale non sono valide le credenziali del
            portale Poste.it ed occorre effettuare una specifica registrazione.
            La registrazione è ammessa esclusivamente per i titolari di Carta
            BancoPosta Più, Fido Affari e/o Fido POS
          </p>
        </Row>
      </div>
    </>
  );
};

export default LoginStep1;
