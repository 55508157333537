/**Packages */
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const ForgotPasswordActivate = () => {
  const { t, i18n, ready } = useTranslation();
  const { title, body } = NavigationProvider.getStateFromHistory();

  return (
    <>
      <FullWidthLayout id="forgot-password-confirmation">
        <PageTitle small title={
          title || t("Reset Password")
        }>
          <div className="subtitle">
            <p className="space-below-md text-center">
              Ti abbiamo appena inviato un'email.
              <br />
              Clicca sul link che hai ricevuto.
            </p>
          </div>
        </PageTitle>
      </FullWidthLayout>
    </>
  );
};
export default ForgotPasswordActivate;
