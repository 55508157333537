/**Packages */
import Cookies from "universal-cookie";

/**Helpers */
import { USER_DATA_KEY } from "./constants";

/**
 *
 * @returns token from the session Storage
 */
export const getUserData = () => {
  //const stringContent = sessionStorage.getItem(USER_DATA_KEY);
  const cookies = new Cookies();
  return cookies.get(USER_DATA_KEY);
};

/**
 *
 * @param {*} userData - user data
 * The method sets value of the user data in sessionStorage
 */
export const setUserData = (userData) => {
  //sessionStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
  const cookies = new Cookies();
  cookies.set(USER_DATA_KEY, JSON.stringify(userData), { path: "/" });
};

/**
 * The method removes UserData from the sessionStorage
 */
export const removeUserData = () => {
  //sessionStorage.removeItem(USER_DATA_KEY);
  const cookies = new Cookies();
  cookies.remove(USER_DATA_KEY, { path: "/" });
};
