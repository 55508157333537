/**Helpers */
import { DIALER_BASE_URL } from "../../../helpers/constants";
import { validateGenericString } from "../../../helpers/validators";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const HelperNumber = ({ text = "", number = "", onClickEnabled = true }) => {
  const onClickHandler = (e) => {
    e.preventDefault();
    if (validateGenericString(number)) {
      const redirectUrl = DIALER_BASE_URL + number;
      NavigationProvider.openDialer(redirectUrl);
    }
  };

  return (
    <div className="helper-number-container">
      <span className="text" dangerouslySetInnerHTML={{ __html: text }}></span>
      <span className="number clickable" onClick={onClickHandler}>
        {number}
      </span>
    </div>
  );
};

export default HelperNumber;
