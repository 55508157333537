/**Redux */
import { SET_MOVEMENTS, DELETE_MOVEMENTS, DELETE_ALL_MOVEMENTS } from "./../actionTypes/movementsActionsTypes";

/**Helpers */
import { BASE_MOVEMENTS_OBJ } from "./../../helpers/constants";
import { getReduxMonthYearKey, getMonthYearPrevious } from "./../../helpers/utils";

const initialState = {};
//initialState[getReduxMonthYearKey(null, null)] = Object.assign({}, BASE_MOVEMENTS_OBJ);
//initialState[getMonthYearPrevious()] = Object.assign({}, BASE_MOVEMENTS_OBJ);

export const movementsReducer = function (state = initialState, action) {
  const payload = action?.payload;
  const key = getReduxMonthYearKey(payload?.month, payload?.year);
  switch (action.type) {
    case SET_MOVEMENTS: {
      state[key] = Object.assign({}, payload.data);

      return {
        ...state,
      };
    }
    case DELETE_MOVEMENTS: {
      delete state[key];

      return {
        ...state,
      };
    }
    case DELETE_ALL_MOVEMENTS: {
      //resetState[getReduxMonthYearKey(null, null)] = Object.assign({}, BASE_MOVEMENTS_OBJ);
      //resetState[getMonthYearPrevious()] = Object.assign({}, BASE_MOVEMENTS_OBJ);
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
