/**Packages */
import { combineReducers } from "redux";

/**Redux - Reducers */
import { userReducer } from "./userReducer";
import { usersReducer } from "./usersReducer";
import { productsReducer } from "./productsReducer";
import { movementsReducer } from "./movementsReducer";
import { resumesReducer } from "./resumesReducer";
import { loaderReducer } from "./loaderReducer";
import { errorReducer } from "./errorReducer";
import { successReducer } from "./successReducer";
import { warningReducer } from "./warningReducer";

export default combineReducers({
  user: userReducer,
  users: usersReducer,
  products: productsReducer,
  movements: movementsReducer,
  resumes: resumesReducer,
  loader: loaderReducer,
  error: errorReducer,
  success: successReducer,
  warning: warningReducer,
});
