/**Components */
import TextLink from "../../Elements/TextLink";

/**CSS */
import "./style.css";

const DropDownMenu = ({ id = "", onClick = (item) => { }, menuItems = [] }) => {
  const onItemClick = (event, item) => {
    event.preventDefault();
    onClick(item);
  };

  const firstItem = menuItems[0];

  return (
    menuItems?.length > 0 && (
      <div id={id} className="dropdown-menu-container">
        <div className="icon-with-bottom-text">
          <img id="card-top-menu-icon" className="icon card" src={firstItem.icon} alt={firstItem.title} />
          <span className="bottom-text text-uppercase">{firstItem.title}</span>
        </div>

        {menuItems?.length > 1 &&
          <div className="cpar-dropdown-menu">
            <ul>
              {menuItems.slice(1, menuItems.length).map((item) => (
                <li className="clickable" onClick={(e) => onItemClick(e, item)}>
                  <div>
                    {item.icon && <img className="icon card" src={item.icon} alt={item.title} />}
                    <TextLink text={item.title} classList={`text-uppercase ${item.icon && "text-small"}`} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        }
      </div>
    )
  );
};
export default DropDownMenu;
