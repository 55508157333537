/* eslint-disable no-undef */
/**Packages */
import { useState } from "react";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TextLink from "../../../components/Elements/TextLink";
import Timer from "../../../components/Elements/Timer";
import Button from "../../../components/Elements/Button";
import Keypad from "../../../components/Elements/Keypad";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";

/**Helpers */
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

/**
 *
 * otpType: 0 = numerico, 1 = testo
 *
 */

const OTPForm = ({
  title = "Pagina riservata OTP",
  onClickHandler = (e) => {
    console.log(e);
  },
  onTimerFinish = () => {
    NavigationProvider.goBack();
  },
  onNewOtpHandler = async () => { },
  restartTimer = false,
  otpType = 1,
}) => {
  let [finalOTPString, setFinalOTPString] = useState("");

  return (
    <>
      <PageTitle small title={title}></PageTitle>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox id="otp-component-container" fullWidthOnMobile classList="text-center space-below-lg">
          <Row gridSize="xxs" classList="space-below-md">
            <h3>codice otp</h3>
            {otpType === 0 ? (
              <div className="cpar-form">
                <Keypad
                  pinInputChangeHandler={(finalString) => {
                    setFinalOTPString(finalString);
                    console.log("aaa: " + finalString);
                  }}
                />
                <Timer onTimerFinish={onTimerFinish} restartTimer={restartTimer} />

                <TextLink text="Richiedi nuovo OTP" onClick={onNewOtpHandler} />

                <Button
                  isEnabled={finalOTPString.length === 5}
                  onClick={(e) => {
                    e.preventDefault();
                    onClickHandler(finalOTPString);
                  }}
                  round
                  text="Conferma"
                />
              </div>
            ) : (
              <ContentBox fullWidthOnMobile classList="text-center">
                <Form
                  submitEnabled="true"
                  submitText={"Conferma"}
                  onSubmit={(e) => {
                    e.preventDefault();
                    onClickHandler(finalOTPString);
                  }}
                >
                  <FloatingInput
                    name="userPin"
                    placeholder="Codice OTP"
                    validIf={['notEmpty']}
                    value={finalOTPString}
                    onChange={(e) => setFinalOTPString(e?.target?.value)}
                  />
                  <Timer onTimerFinish={onTimerFinish} restartTimer={restartTimer} />

                  <TextLink text="Richiedi nuovo OTP" onClick={onNewOtpHandler} />
                </Form>
              </ContentBox>
            )}
          </Row>
        </ContentBox>
      </Row>
    </>
  );
};

export default OTPForm;
