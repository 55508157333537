import { UPDATE_PRODUCT } from "../actionTypes/productsActionsTypes";

/**
 *
 * @param {*} cardId - id of the product to update
 * @param {*} newData - new data to be setted
 * @returns The action to update the current product selected (after operations)
 */
export const updateProductById = (id, newData) => {
  return {
    type: UPDATE_PRODUCT,
    data: {
      id,
      newData,
    },
  };
};
