/**Action Types*/
import {
  SET_RESUMES,
  SET_RESUMES_MONTH_YEAR,
  DELETE_RESUMES,
  DELETE_ALL_RESUMES,
} from "./../actionTypes/resumesActionsTypes";

/**
 *
 * @param {*} data - data of the resumed to be saved
 * @returns Teh action to save all resumes data
 */
export const setResumes = (data) => {
  return {
    type: SET_RESUMES,
    payload: {
      data,
    },
  };
};
/**
 *
 * @param {*} month - month
 * @param {*} year - year
 * @param {*} data - data to be setted in the state
 * @returns The action to save data in correct month-year field
 */
export const setResumesMonthYear = (month = null, year = null, data) => {
  return {
    type: SET_RESUMES_MONTH_YEAR,
    payload: {
      month,
      year,
      data,
    },
  };
};
/**
 *
 * @param {*} month - month
 * @param {*} year - year
 * @returns The action to delete data from correct month-year field
 */
export const deleteResumesMonthYear = (month = null, year = null) => {
  return {
    type: DELETE_RESUMES,
    payload: {
      month,
      year,
    },
  };
};
/**
 *
 * @returns The action to delete all resumes from the state
 */
export const deleteAllResumes = () => {
  return {
    type: DELETE_ALL_RESUMES,
  };
};
