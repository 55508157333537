/**Components */
import FlexRow from "../FlexRow";

/**Helpers */
import { getValueWithValuta } from "../../../helpers/valuta";

/**CSS */
import "./style.css";

const MovementItem = ({ trxDate = "", trxDescription = "", importo = "", positivo = "", onClick = () => {} }) => {
  return (
    <div className="movement-item" onClick={onClick}>
      <FlexRow>
        <div className="cpar-flex-col left-column">
          {trxDate}
          <br />
          <div className="ellipsis">{trxDescription}</div>
        </div>
        <div className="cpar-flex-col right-column">
          {getValueWithValuta(importo)} {positivo ? "+" : "-"}
        </div>
      </FlexRow>
    </div>
  );
};

export default MovementItem;
