/**Action Types*/
import {
  USER_SET_SELECTED_CARD_EC_METHOD,
  USER_SET_SMS_ACTIVATION,
  USER_UPDATE_SELECTED_PRODUCT,
  USER_CHANGE_SELECTED_PRODUCT,
  USER_LOGGED_IN,
} from "./../actionTypes/userActionTypes";

/**
 *
 * @param {*} user - data of the user to be setted as "selected"
 * @returns The action to set the current user
 */
export const setSelectedUserAction = (user) => {
  return {
    type: USER_LOGGED_IN,
    data: user,
  };
};
/**
 *
 * @param {*} cardId - id of the product
 * @param {*} newMethod - new estratto conto method
 * @param {*} newFullMethod - new estratto conto method full string
 * @param {*} email - email (id newMethod = "I") where send estratto conto
 * @returns The action to set new Estratto conto for product with id "cardId"
 */
export const setNewECMethod = (cardId, newMethod, newFullMethod, email = null) => {
  return {
    type: USER_SET_SELECTED_CARD_EC_METHOD,
    data: {
      id: cardId,
      ecMethod: newMethod,
      fullEcMethod: newFullMethod,
      email: email ? email : null,
    },
  };
};
/**
 *
 * @param {*} cardId - id of the product
 * @param {*} minAmount - min amount of value from star send SMS
 * @param {*} alertNuovaAutorizzazione - flag to activate or not SMS alert of payments with value bugger than "minAmount"
 * @param {*} alertCalcoloNuovoEC - flag to activate or not SMS alert of new estratto conto generated
 * @param {*} phoneNumber - phone number where sends SMS
 * @returns The action to set SMS alerts for product with id "cardId"
 */
export const setSMSActivation = (
  cardId,
  minAmount,
  alertNuovaAutorizzazione,
  alertCalcoloNuovoEC,
  phoneNumber = ""
) => {
  return {
    type: USER_SET_SMS_ACTIVATION,
    data: {
      id: cardId,
      minAmount,
      alertNuovaAutorizzazione,
      alertCalcoloNuovoEC,
      phoneNumber,
    },
  };
};
/**
 *
 * @param {*} cardId - id of the product to display
 * @returns The action to change the current product displayed
 */
export const changeSelectedProduct = (cardId) => {
  return {
    type: USER_CHANGE_SELECTED_PRODUCT,
    data: {
      id: cardId,
    },
  };
};
/**
 *
 * @param {*} cardId - id of the product to update
 * @param {*} newData - new data to be setted
 * @returns The action to update the current product selected (after operations)
 */
export const updateSelectedProduct = (cardId, newData) => {
  return {
    type: USER_UPDATE_SELECTED_PRODUCT,
    data: {
      id: cardId,
      newData,
    },
  };
};
