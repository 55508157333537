/**Packages */
import moment from "moment";
import { useState, useEffect } from "react";

/**Components */
import MovementExpandedItemList from "../MovementExpandedItemList";
//import SearchMovementsForm from "../SearchMovementsForm";
import Row from "../Row";
import ContentBox from "../ContentBox";

/**CSS */
import "./style.css";

const MovementsFilterableList = ({ expandable = false, formTitle = "", onClick = () => { }, elements = {} }) => {
  const [cpElements, setCPElements] = useState({});

  /*TODO: da testare*/
  const onSearchClickHandler = (e, fromDate, toDate) => {
    e.preventDefault();
    if (!(fromDate || toDate)) return;

    if (Object.keys(elements)?.length > 0) {
      const momentFromDate = moment(fromDate).clone().startOf("month");
      const momentToDate = moment(toDate).clone().startOf("month");

      const filtered = Object.keys(elements).filter((key) => {
        const movement = elements[key];

        const momentMovementData = moment("01/" + movement.periodo, "DD/MM/YYYY")
          .clone()
          .startOf("month");
        return momentMovementData.isBetween(momentFromDate, momentToDate, "d");
      });

      if (filtered.length > 0) {
        const newObj = {};
        filtered.forEach((itm) => {
          newObj[itm] = elements[itm];
        });
        setCPElements(newObj);
      } else {
        setCPElements({});
      }
    } else {
      setCPElements({});
    }
  };

  useEffect(() => {
    setCPElements(elements);
  }, [elements]);

  return (
    <div className={`movements-filterable-list-container`}>
      {/* <Row gridSize="md" classList="space-below-md">
        <ContentBox compact>
          <SearchMovementsForm formTitle={formTitle} onClick={onSearchClickHandler} />
        </ContentBox>
      </Row> */}

      <Row gridSize="md" classList="space-below-md">
        <ContentBox classList="compactx2" compact>
          <p className="space-below-md">
            Per visualizzare i movimenti del mese clicca sulla data del periodo di interesse.
          </p>

          <MovementExpandedItemList expandable={expandable} elements={cpElements} onClick={onClick} />
        </ContentBox>
      </Row>
    </div>
  );
};

export default MovementsFilterableList;
