/**Helpers */
import config from "./config";

/**
 *
 * @param {*} token - value to be setted in "Authorization" header, otherwise none
 * @returns the headers for the api call
 */
export const getHeaders = (token = null) => {
  return token
    ? {
        //Authorization: `Bearer ${token}`,
        Authorization: `${token}`,
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
      };
};
/**
 *
 * @param {*} token - value to be setted in "Authorization" header, otherwise none
 * @returns options like an object
 */
export const getAxiosHeadersConfig = (token = null) => {
  const headers = token ? getHeaders(token) : getHeaders();

  const options = {
    headers,
  };

  return options;
};

export const getPdfUrl = () => {
  return `${config.baseUrl}${config.urls.pdfDownload}`;
};

export const openWindowWithPost = (url = "", data = {}) => {
  const form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  for (var key in data) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

/*
export const getConfiguredAxios = () => {
    axios.defaults.baseURL = config.baseUrl;
    return axios;
};
*/
