/**Packages */
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

/**Helpers */
import { getRandomString } from "../../../helpers/utils";

/**CSS */
import "./style.css";

const Radio = ({
  type = "text",
  name = "",
  value = "",
  checked = false,
  label = "",
  disabled = false,
  readonly = false,
  withError = false,
  hideError = false,
  validIf = [],
  errorMessage = "Errore!!!",
  id = `floating-input-${name}-${(Math.random() + 1).toString(36).substring(5)}`,
  classList = "",
  onChange = (e) => { },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [togglePasswordClass, setTogglePasswordClass] = useState(faEye);

  function handleTextChange(e, currentValue) {
    if (currentValue !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    onChange(e, currentValue);
  }

  function getIsActive() {
    return isActive || value !== "";
  }

  const togglePassword = (e) => {
    e.preventDefault();

    if (inputType === "password") {
      setInputType("text");
      setTogglePasswordClass(faEyeSlash);
    } else {
      setInputType("password");
      setTogglePasswordClass(faEye);
    }
  };

  const classes = `radio-input-${name} ${classList} ${withError && "with-error"}`;

  return (
    <label className={classes} htmlFor={id}>
      <input
        disabled={disabled}
        readOnly={readonly}
        name={name}
        defaultChecked={checked}
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        id={id}
        type="radio"
      />{" "}
      <div className={`error-message ${!!hideError && 'hidden'}`}>{errorMessage}</div>
      {label}


    </label>
  );
};

export default Radio;
