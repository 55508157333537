/**Packages */
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import UsefulNumbersComponent from "../../../components/Elements/UsefulNumbersComponent";

/**CSS */
import "./style.css";

/**Page when not logged */
const UsefulNumbers = () => {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <FullWidthLayout id="guest-useful-numbers">
        <PageTitle title="Numeri utili per assistenza" />

        <UsefulNumbersComponent />
      </FullWidthLayout>
    </>
  );
};

export default UsefulNumbers;
