/**Packages */
import { request } from "monforteclient";

/**Helpers */
import { createSuccessResponse, createErrorResponse } from "../helpers/response";
import { getAxiosHeadersConfig } from "../helpers/request";
import config from "../helpers/config";

const user = {
  /**
   * @Deprecated
   *
   * TODO: delete
   * */
  getUserInfo: async (token, on200Callback, on500Callback) => {
    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.userInfo}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.userInfo);
    }
  },
  /**
   *
   * @param {*} param0 { userId, userPassword }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  loginStep1: async ({ userId, userPassword }, on200Callback, on500Callback) => {
    const body = {
      username: userId,
      password: userPassword,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.loginStep1}`,
        ...getAxiosHeadersConfig(),
        language: "it",
        method: "post",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.loginStep1);
    }
  },
  /**
   *
   * @param {*} param0 { tokenOTP, userOTP }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  loginStep2: async ({ tokenOTP, userOTP }, on200Callback, on500Callback) => {
    const body = {
      tokenOTP,
      otp: userOTP,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.loginStep2}`,
        ...getAxiosHeadersConfig(),
        language: "it",
        method: "post",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.loginStep2);
    }
  },
  /**
   *
   * @param {*} param0 { username = "", email = "", cf = "", card_pan = "0000", // idEmettitore = "003,100", // password = "", bPrivacy = false, bPrivacyCom = false }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  signup: async (
    {
      username = "",
      email = "",
      cf = "",
      card_pan = "0000",
      // idEmettitore = "003,100",
      // password = "",
      bPrivacy = false,
      bPrivacyCom = false,
    },
    on200Callback,
    on500Callback
  ) => {
    const body = {
      username,
      email,
      cf,
      card_pan,
      // idEmettitore,
      // password,
      bPrivacy,
      bPrivacyCom,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.signup}`,
        ...getAxiosHeadersConfig(),
        language: "it",
        method: "post",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.signup);
    }
  },
  /**
   *
   * @param {*} param0 { username, email, cf }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  recoveryPassword: async ({ username, email, cf }, on200Callback, on500Callback) => {
    const body = {
      login: username,
      email,
      codFiscale: cf,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.recoveryPassword}`,
        ...getAxiosHeadersConfig(),
        language: "it",
        method: "POST",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.recoveryPassword);
    }
  },
  /**
   *
   * @param {*} param0 { username, magickey, password }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  recoveryPasswordStep2: async ({ username, magickey, password }, on200Callback, on500Callback) => {
    const body = {
      login: username,
      magickey,
      password,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.editPassword}`,
        ...getAxiosHeadersConfig(),
        language: "it",
        method: "POST",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.editPassword);
    }
  },
  /**
   *
   * @param {*} param0 { token, username = "", oldPassword, password } -> username not used
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  editPassword: async ({ token, username = "", oldPassword, password }, on200Callback, on500Callback) => {
    const body = {
      magicKey: "",
      oldPassword,
      password,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.editPassword}`,
        ...getAxiosHeadersConfig(token),
        language: "it",
        method: "post",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.editPassword);
    }
  },
  /**
   *
   * @param {*} param0 { token, email, card_id, nPratica, telefono, cellulare, telefono2, indirizzo, cap, localita, provincia, otp }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  updateUserInfo: async (
    { token, email, card_id, nPratica, telefono, cellulare, telefono2, indirizzo, cap, localita, provincia, otp },
    on200Callback,
    on500Callback
  ) => {
    const body = {
      email,
      card_id,
      nPratica,
      telefono,
      cellulare,
      telefono2,
      indirizzo,
      cap,
      localita,
      provincia,
      otp,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.user}`,
        ...getAxiosHeadersConfig(token),
        language: "it",
        method: "POST",
        data: JSON.stringify(body),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.user);
    }
  },
};

export default user;
