/**CSS */
import "./style.css";

const ContentBox = ({
  id = "",
  compact = false,
  fullWidthOnMobile = null,
  withTitle = "",
  classList = "",
  onClick = () => {},
  children,
}) => {
  const classes = `content-box ${compact && "compact"} ${fullWidthOnMobile && "mobile-full-width"} ${classList}`;

  return (
    <div onClick={onClick} id={id} className={classes}>
      {withTitle && <h3>{withTitle}</h3>}
      {children}
    </div>
  );
};

export default ContentBox;
