/**Packages */
import { useState, useEffect } from "react";

/**CSS */
import "./style.css";

const Timer = ({
  text = "La password è valida per",
  initTime = 120,
  setErrorState = () => {},
  onTimerFinish = () => {},
  restartTimer = false,
}) => {
  const [timer, setTimer] = useState(initTime);
  const [timeooutReference, setTimeoutReference] = useState(null);

  const setTimerState = () => {
    if (timer > 0) {
      const timeout = setTimeout(() => setTimer(timer - 1), 1000);
      setTimeoutReference(timeout);
    } else {
      clearTimeout(timeooutReference);
      setErrorState("Tempo scaduto", "L'OTP non è più valido, riprova");
      onTimerFinish();
    }
  };

  useEffect(() => {
    setTimerState();
  }, [timer]);

  useEffect(() => {
    if (restartTimer) {
      onRestart();
    }
  }, [restartTimer]);

  const onRestart = () => {
    clearTimeout(timeooutReference);
    initTime = 120;
    setTimer(initTime);
  };

  const getMinutesSeconds = () => {
    const minutes = Math.floor(timer / 60);
    return {
      minutes,
      seconds: timer - minutes * 60,
    };
  };

  const getMinutesSecondsText = () => {
    const { minutes, seconds } = getMinutesSeconds();
    const textMinute = minutes < 0 ? "minuti" : minutes === 1 ? "minuto" : "minuti";
    const textSecond = seconds === 1 ? "secondo" : "secondi";
    return `${minutes} ${textMinute} e ${seconds} ${textSecond}`;
  };

  const completePercentByTimer = () => {
    return `${(100 / initTime) * timer}%`;
  };

  return (
    <div className="timer-container">
      <p className="timer-text">
        {text}
        <br />
        {getMinutesSecondsText()}
      </p>
      <div className="timer-progress-bar">
        <div className="complete-progress-bar" style={{ width: completePercentByTimer() }}></div>
      </div>
    </div>
  );
};

export default Timer;
