/**CSS */
import "./style.css";

const FlexRow = ({ gridSize = "md", classList = "", id = "", children }) => {
  const classes = `cpar-flex-row ${gridSize} ${classList}`;

  return (
    <div id={id} className={classes}>
      {children}
    </div>
  );
};

export default FlexRow;
