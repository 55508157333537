/**Components */
import ContentBox from "../ContentBox";

/**Helpers */
import { getImage } from "../../../helpers/image";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const NavigationCard = ({ title = "Title", path = "" }) => {
  return (
    <ContentBox
      compact
      classList="navigation-card"
      onClick={() => {
        NavigationProvider.goTo(path);
      }}
    >
      <h3>{title}</h3>
      <img alt={"Logo"} src={getImage("images/icon_next.svg")} />
    </ContentBox>
  );
};

export default NavigationCard;
