/**Packages */
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import Button from "../../../components/Elements/Button";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";

/**Helpers */
import { PAGES, OTP_CASES } from "../../../helpers/constants";
import { getOTPProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const Account = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) =>
    dispatch(setError(title, message));

  const user = useSelector((state) => state.user);

  const {
    token,
    name,
    surname,
    codiceFiscale: cf,
    sesso,
    email: apiEmail,
    dataNascita,
    indirizzo,
    cap,
    localita,
    provincia,
    localitaNascita,
    provinciaNascita,
    telefono,
    telefono2,
    telefonoSede,
    fax,
    cellulare,
    selectedCard,
    canEditData = false,
  } = user;

  const { id = null } = selectedCard;

  const [editMode, setEditMode] = useState(false);

  // residenza
  const [residenceAddress, setResidenceAddress] = useState(indirizzo);
  const [residenceZip, setResidenceZip] = useState(cap);
  const [residenceMunicipality, setResidenceMunicipality] = useState(localita);
  const [residenceProvince, setResidenceProvince] = useState(provincia);
  const [residenceNation, setResidenceNation] = useState("");
  // domicilio
  const [domicileAddress, setDomicileAddress] = useState("");
  const [domicileZip, setDomicileZip] = useState("");
  const [domicileMunicipality, setDomicileMunicipality] = useState("");
  const [domicileProvince, setDomicileProvince] = useState("");
  const [domicileNation, setDomicileNation] = useState("");
  // recapiti
  const [phone, setPhone] = useState(telefono);
  const [mobile, setMobile] = useState(cellulare);
  const [email, setEmail] = useState(apiEmail);

  const saveFieldsClickHandler = async (e) => {
    activateLoader();

    const onSuccessHandler = async (data) => {
      NavigationProvider.goToOTPPage(
        {
          clickCase: OTP_CASES.MODIFY_USER,
          token,
          email,
          card_id: id,
          nPratica: 0,
          telefono: phone,
          cellulare: mobile,
          telefono2,
          indirizzo: residenceAddress,
          cap: residenceZip,
          localita: residenceMunicipality,
          provincia: residenceProvince,
        },
        {
          token,
          id,
        }
      );
      deactivateLoader();
    };

    const onErrorHandler = (data) => {
      deactivateLoader();
      setErrorMessage(
        "Errore",
        "Errore durante la procedura di aggiornamento dei dati anagrafici dell'utente, riprovare più tardi"
      );
    };

    await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
  };

  return (
    <>
      <FullWidthLayout id="account-page">
        <PageTitle small title="dati anagrafici">
          <p>
            Gentile Cliente, in questa pagina può modificare i dati di recapito
            con poche semplici azioni.
            <br />
            Clicchi sul tasto “Modifica” in corrispondenza dei campi
            sottostanti, il sistema porterà ad una pagina dove potrà modificare
            i dati.
          </p>
          <br />
          <p>
            <b>
              Segnaliamo che, nel caso in cui venga modificato il numero di
              cellulare, non sarà possibile effettuare azioni dispositive nelle
              24 ore successive.
            </b>
          </p>
          {editMode && (
            <p className="text-center space-above-lg">
              <b>Autenticazione</b>
              <br />
              Alla conferma riceverai un messaggio sul cellulare con il codice
              OTP da inserire nella maschera che ti comparirà.
            </p>
          )}
        </PageTitle>

        {/* <Form noSubmit onSubmit={saveFieldsClickHandler}> */}
        <Form
          noSubmit
          onSubmit={
            editMode
              ? saveFieldsClickHandler
              : () => {
                  setEditMode(!editMode);
                }
          }
        >
          <Row gridSize="md" classList="space-below-md">
            <ContentBox
              withTitle="anagrafica"
              compact
              fullWidthOnMobile
              classList="space-below-md"
            >
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={name}
                    placeholder="Nome"
                    readonly
                    disabled
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={surname}
                    placeholder="Cognome"
                    readonly
                    disabled
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={moment(dataNascita).format("DD/MM/YYYY")}
                    placeholder="Data di nascita"
                    readonly
                    disabled
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={localitaNascita}
                    placeholder="Luogo di nascita"
                    readonly
                    disabled
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={provinciaNascita}
                    placeholder="Provincia di nascita"
                    readonly
                    disabled
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={user.nation}
                    placeholder="Nazione di nascita"
                    readonly
                    disabled
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={user.nationality}
                    placeholder="Cittadinanza"
                    readonly
                    disabled
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={sesso}
                    placeholder="Sesso"
                    readonly
                    disabled
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={cf}
                    name="userCf"
                    placeholder="Codice fiscale"
                    readonly
                  />
                </div>
              </FlexRow>
            </ContentBox>

            <ContentBox
              withTitle="indirizzo di residenza"
              compact
              fullWidthOnMobile
              classList="space-below-md"
            >
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={residenceAddress}
                    onChange={(e) => {
                      setResidenceAddress(e.target.value);
                    }}
                    name="address"
                    placeholder="Indirizzo"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={residenceZip}
                    onChange={(e) => {
                      setResidenceZip(e.target.value);
                    }}
                    name="capi"
                    placeholder="CAP"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={residenceMunicipality}
                    onChange={(e) => {
                      setResidenceMunicipality(e.target.value);
                    }}
                    name="municipality"
                    placeholder="Comune"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={residenceProvince}
                    onChange={(e) => {
                      setResidenceProvince(e.target.value);
                    }}
                    name="province"
                    placeholder="Provincia"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={residenceNation}
                    onChange={(e) => {
                      setResidenceNation(e.target.value);
                    }}
                    placeholder="Nazione"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col"></div>
              </FlexRow>

              <Row classList="text-center">
                <Button
                  type="submit"
                  round
                  isEnabled={canEditData}
                  text={editMode ? "salva modifiche" : "modifica"}
                  // onClick={
                  //   editMode
                  //     // ? () => {saveFieldsClickHandler()}
                  //     ? (e) => { return e; }
                  //     : () => {
                  //       setEditMode(!editMode);
                  //     }
                  // }
                />
              </Row>
            </ContentBox>

            <ContentBox
              withTitle="domicilio"
              compact
              fullWidthOnMobile
              classList="space-below-md"
            >
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={domicileAddress}
                    onChange={(e) => {
                      setDomicileAddress(e.target.value);
                    }}
                    placeholder="Indirizzo"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={domicileZip}
                    onChange={(e) => {
                      setDomicileZip(e.target.value);
                    }}
                    placeholder="CAP"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={domicileMunicipality}
                    onChange={(e) => {
                      setDomicileMunicipality(e.target.value);
                    }}
                    placeholder="Comune"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={domicileProvince}
                    onChange={(e) => {
                      setDomicileProvince(e.target.value);
                    }}
                    placeholder="Provincia"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={domicileNation}
                    onChange={(e) => {
                      setDomicileNation(e.target.value);
                    }}
                    placeholder="Nazione"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col"></div>
              </FlexRow>

              <Row classList="text-center">
                <Button
                  type="submit"
                  round
                  isEnabled={canEditData}
                  text={editMode ? "salva modifiche" : "modifica"}
                  // onClick={
                  //   editMode
                  //     // ? saveFieldsClickHandler
                  //     ? (e) => { return e; }
                  //     : () => {
                  //       setEditMode(!editMode);
                  //     }
                  // }
                />
              </Row>
            </ContentBox>

            <ContentBox
              withTitle="recapiti"
              compact
              fullWidthOnMobile
              classList="space-below-md"
            >
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    placeholder="Telefono"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    placeholder="Cellulare"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
              </FlexRow>
              <FlexRow classList="cols-equal gap responsive space-below-lg">
                <div className="cpar-flex-col">
                  <FloatingInput
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="E-mail"
                    readonly={!editMode}
                    disabled={!editMode}
                  />
                </div>
                <div className="cpar-flex-col"></div>
              </FlexRow>
              <Row classList="text-center">
                <Button
                  type="submit"
                  round
                  isEnabled={canEditData}
                  text={editMode ? "salva modifiche" : "modifica"}
                  // onClick={
                  //   editMode
                  //     // ? saveFieldsClickHandler
                  //     ? (e) => { return e; }
                  //     : () => {
                  //       setEditMode(!editMode);
                  //     }
                  // }
                />
              </Row>
            </ContentBox>
          </Row>
        </Form>
      </FullWidthLayout>
    </>
  );
};

export default Account;
