/**Packages */
import { request } from "monforteclient";

/**Helpers */
import { createSuccessResponse, createErrorResponse } from "../helpers/response";
import { getAxiosHeadersConfig } from "../helpers/request";
import config from "../helpers/config";

const movements = {
  /**
   *
   * @param {*} token - token to identify the user and send the correct headers
   * @param {*} month - month of the resumes requested
   * @param {*} year - year of the resumes requested
   * @param {*} card_id - id of the product requested
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  getResumes: async (token, month, year, card_id, on200Callback, on500Callback) => {
    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.resumes}`,
        language: "it",
        method: "POST",
        data: {
          month,
          year,
          card_id,
        },
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.resumes);
    }
  },
  /**
   * When using month and year = null -> I'm getting movimenti viaggianti
   */
  /**
   *
   * @param {*} token - token to identify the user and send the correct headers
   * @param {*} month - month of the movements requested
   * @param {*} year - year of the movements requested
   * @param {*} card_id - id of the product requested
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  getTransactions: async (token, month, year, card_id, on200Callback, on500Callback) => {
    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.transactions}`,
        language: "it",
        data: {
          month,
          year,
          card_id,
        },
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.transactions);
    }
  },
};

export default movements;
