/**Packages */
import { useState } from "react";
import { useDispatch } from "react-redux";

/**Components */
import OTPFormComponent from "../../../components/Elements/OTPFormComponent";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";

/**Helpers */
import { getOTPProcedure } from "../../../helpers/handler";
import { getOnClickHandler } from "../../../helpers/otpFormHandler";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setSuccess } from "../../../redux/actions/successActions";
import { setWarning } from "../../../redux/actions/warningActions";

/**CSS */
import "./style.css";

const OTPForm = () => {
  const { data, dataOTP } = NavigationProvider.getStateFromHistory();
  const { token, id } = dataOTP;

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) => dispatch(setError(title, message));
  const setSuccessMessage = (title, message) => dispatch(setSuccess(title, message));
  const setWarningMessage = (title, message) => dispatch(setWarning(title, message));

  const [maxAttemps, setMaxAttemps] = useState(3);

  const [restartTimer, setRestartTimer] = useState(false);

  const restartTimerHandler = () => {
    setRestartTimer(true);
    setTimeout(() => {
      setRestartTimer(false);
    });
  };

  const displayWarning = () => {
    const attemps = maxAttemps - 1;
    const message = attemps === 0 || attemps > 1 ? "tentativi" : "tentativo";
    setWarningMessage("Attenzione", `Hai ancora ${attemps} ${message}`);
  };

  const getNewOTPHandler = async (e) => {
    e.preventDefault();
    if (maxAttemps > 0) {
      activateLoader();

      const onSuccessHandler = async (data) => {
        deactivateLoader();
        setMaxAttemps(maxAttemps - 1);
        displayWarning();
        restartTimerHandler();
      };

      const onErrorHandler = (data) => {
        deactivateLoader();
        setErrorMessage("Errore", "Errore durante la procedura di ri-invio dell'otp, riprovare più tardi");
      };

      await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
    } else {
      setErrorMessage("Errore", "Raggiunto numero massimo di richieste di otp");
    }
  };

  return (
    <FullWidthLayout>
      <OTPFormComponent
        onClickHandler={async (otp) => {
          activateLoader();
          await getOnClickHandler(
            data.clickCase,
            { ...data, otp },
            deactivateLoader,
            setErrorMessage,
            setSuccessMessage,
            dispatch
          );
        }}
        onNewOtpHandler={getNewOTPHandler}
        onTimerFinish={() => NavigationProvider.goBack()}
        restartTimer={restartTimer}
      />
    </FullWidthLayout>
  );
};

export default OTPForm;
