/**CSS */
import "./style.css";

const TextLink = ({
  text = "",
  withCaret = false,
  strong = false,
  noFormatting = false,
  classList = "",
  inline = false,
  onClick = () => {},
}) => {
  const classes = `text-link ${strong ? "strong" : ""} ${noFormatting ? "no-formatting" : ""} ${
    inline ? "inline" : ""
  } ${classList}`;
  return (
    <div className={classes}>
      <span
        onClick={(e) => {
          onClick(e);
        }}
      >
        {text}
      </span>

      {withCaret && <span className="menu-arrow _tmp">&rsaquo;</span>}
    </div>
  );
};

export default TextLink;
