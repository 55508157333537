/**Redux */
import {
  SET_RESUMES,
  SET_RESUMES_MONTH_YEAR,
  DELETE_RESUMES,
  DELETE_ALL_RESUMES,
} from "./../actionTypes/resumesActionsTypes";

/**Helpers */
import { BASE_RESUMES_OBJ } from "./../../helpers/constants";
import { getReduxMonthYearKey, getMonthYearPrevious } from "./../../helpers/utils";

const initialState = {};

/*
    //initialState[getReduxMonthYearKey(null, null)] = Object.assign({}, BASE_RESUMES_OBJ);
    //initialState[getMonthYearPrevious()] = Object.assign({}, BASE_RESUMES_OBJ);
*/

export const resumesReducer = function (state = initialState, action) {
  const payload = action?.payload;
  switch (action.type) {
    case SET_RESUMES: {
      const newObj = {};

      if (payload.data && payload.data.ec) {
        const estrattoContoFull = [...payload.data.ec];
        estrattoContoFull.forEach((estrattoMensile) => {
          const dateSplitted = estrattoMensile.periodo.split("/");
          const key = getReduxMonthYearKey(dateSplitted[0], dateSplitted[1]);
          newObj[key] = Object.assign({}, estrattoMensile);
        });
      }

      return {
        ...state,
        ...newObj,
      };
    }
    case SET_RESUMES_MONTH_YEAR: {
      const key = getReduxMonthYearKey(payload?.month, payload?.year);
      state[key] = Object.assign({}, payload.data);

      return {
        ...state,
      };
    }
    case DELETE_RESUMES: {
      const key = getReduxMonthYearKey(payload?.month, payload?.year);
      delete state[key];

      return {
        ...state,
      };
    }
    case DELETE_ALL_RESUMES: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
