/**Packages */
import moment from "moment";
import { useState } from "react";

/**Components */
import MovementItem from "../MovementItem";

/**Helpers */
import { orberByData } from "../../../helpers/order";
import { trimString } from "../../../helpers/utils";
import { getImage } from "../../../helpers/image";

/**CSS */
import "./style.css";

const MovementItemList = ({ elements = [], expandable = false }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={`movement-item-list ${expandable && "expandable"} ${open && "open"}`}>
        {elements?.length > 0 ? (
          orberByData(elements).map((trx, idx) => {
            return (
              <MovementItem
                trxDate={moment(trx.data).format("DD/MM/YYYY")}
                trxDescription={trimString(trx.descrizione)}
                importo={trx.importo}
                positivo={trx.positivo}
                key={idx}
              />
            );
          })
        ) : (
          <div className="movement-item">Nessun movimento disponibile</div>
        )}
      </div>
      {elements?.length > 6 && (
        <div
          onClick={() => {
            setOpen(true);
          }}
          className="expand-button text-center"
        >
          <img className="clickable" src={getImage("images/yellow_back.svg")} alt="" />
        </div>
      )}
    </>
  );
};

export default MovementItemList;
