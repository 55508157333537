/**Packages */
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { OTP_CASES } from "../../../helpers/constants";
import { getOTPProcedure } from "../../../helpers/handler";
import { getValueWithValuta } from "../../../helpers/valuta";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const BankAdvanceRequest = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorMessage = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const { selectedCard, token } = user;
  const { id = null } = selectedCard;

  /**Getting Values from state */
  const { amount, refundableIn } = NavigationProvider.getStateFromHistory();

  /**
   * Checking if values are setted correctly
   */
  if (!amount || amount <= 0 || !refundableIn || refundableIn <= 0) {
    NavigationProvider.goBack();
  }

  const onBankAdvancedClick = async (e) => {
    activateLoader();

    const onSuccessHandler = async (data) => {
      NavigationProvider.goToOTPPage(
        {
          clickCase: OTP_CASES.BONIFICO,
          token,
          id,
          amount,
          refundableIn,
        },
        {
          token,
          id,
        }
      );
      deactivateLoader();
    };

    const onErrorHandler = (data) => {
      deactivateLoader();
      setErrorMessage("Errore", "Errore durante la procedura di invio dell'otp, riprovare più tardi");
    };

    await getOTPProcedure(token, id, "", onSuccessHandler, onErrorHandler);
  };

  return (
    <TwoColumnsLayout>
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <PageTitle inner title={rp.getResource("header.bankAdvanceTitle")}>
          <p className="text-clear-formatting">
            Puoi utilizzare CartaBancoPostaPiù per trasferire la somma che desideri sul tuo conto corrente*
          </p>
        </PageTitle>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox compact id="bank-advance-request-container" classList="space-below-lg">
          <FlexRow gridSize="md">
            <h6>Importo</h6>
          </FlexRow>

          <FlexRow gridSize="md" classList="cols-equal responsive v-centered">
            <br className="mobile-only" />
            <div className="cpar-flex-col">
              <p className="amount">{getValueWithValuta(amount)}</p>
            </div>
            <br className="mobile-only" />
            <div className="cpar-flex-col">
              <div className="cpar-flex-col">
                <p className="amount">Rimborsabile in {refundableIn} mesi</p>
              </div>
            </div>
            <br className="mobile-only" />
            <div id="request-advance-button-container" className="cpar-flex-col">
              <Button id="request-advance-button" round text="Richiedi" onClick={onBankAdvancedClick} />
            </div>
          </FlexRow>
        </ContentBox>

        <Row gridSize="md" classList="space-below-md">
          <div className="inline-on-desktop btn-container">
            <Button
              round
              outline
              text="calcola rata"
              classList="btn-block"
              onClick={() => {
                NavigationProvider.getCalculateRateLink();
              }}
            />
          </div>
        </Row>

        <Row gridSize="md" classList="space-below-lg space-above-lg">
          <TextLink
            text="Condizioni economiche"
            onClick={() => {
              NavigationProvider.openExternalLink(rp.getResource("externalLinks.transparency"));
            }}
          />
          <p className="text-indent">
            <small className="">{rp.getResource("bankAdvance.conditionsText")}</small>
          </p>
        </Row>
      </Row>
    </TwoColumnsLayout>
  );
};
export default BankAdvanceRequest;
