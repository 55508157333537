import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TextLink from "../../../components/Elements/TextLink";
import Timer from "../../../components/Elements/Timer";
import Form from "../../../components/Form/Form";
import Button from "../../../components/Elements/Button";
import Keypad from "../../../components/Elements/Keypad";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";

/**Helpers */
import { INDEX_URL, PAGES } from "../../../helpers/constants";
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import history from "../../../helpers/history";

/**CSS */
import "./style.css";

const Inbox = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();

  /**
   * Handles Login Form submission
   *
   * @function onLoginFormSubmit
   * @param {SyntheticEvent} submitEvent - it contains the event target (form element) we can use to access its fields' values by the name attribute
   * @return {Void}
   */
  const goToAccountPage = () => {
    history.push(INDEX_URL + PAGES.SIGNUP);
  };

  const requestNewOtpHandler = () => {
    console.log("requestNewOtpHandler");
  };

  const onSubmit = (submitEvent) => {
    console.log(submitEvent);
  };

  return (
    <>
      <FullWidthLayout>
        <PageTitle title="comunicazioni" />

        <Row gridSize="sm" classList="space-below-md"></Row>
      </FullWidthLayout>
    </>
  );
};

export default Inbox;
