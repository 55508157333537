/**
 * ValidationProvider for all kinds of inputs/values
 * Pass an array of validation rules and the FloatingInput will automatically handle validation
 *      and show the right error message
 *
 * ====================== EXAMPLE USAGE ======================
 * <FloatingInput ... validIf={['notEmpty', 'onlyNumbers']} ... />
 *
 */

/**Helpers */
import { validateEmail, validatePhoneNumber } from "../helpers/validators";

class ValidationProvider {
  rules = {
    notEmpty: (val) => {
      return {
        errorMessage: `Il campo non può essere vuoto`,
        isValid: +(val.toString().trim().length > 0),
      };
    },
    selectNotEmpty: (val) => {
      return {
        errorMessage: `Devi scegliere almeno un'opzione`,
        isValid: +(val != -1),
      };
    },

    radioChecked: (val) => {
      return {
        errorMessage: `Devi selezionae l'opzione`,
        isValid: +!!val,
      };
    },
    checkboxChecked: (val) => {
      return {
        errorMessage: `Devi selezionae l'opzione`,
        isValid: +!!val,
      };
    },
    onlyNumbers: (val) => {
      return {
        errorMessage: `Il campo può contenere solo numeri`,
        isValid: +/^\d+$/.test(val),
      };
    },
    minLength: (val, length) => {
      return {
        errorMessage: `Il campo deve contenere almeno ${length} caratteri`,
        isValid: +(val.toString().trim().length >= length),
      };
    },
    maxLength: (val, length) => {
      return {
        errorMessage: `Il campo non può contenere più di ${length} caratteri`,
        isValid: +(val.toString().trim().length <= length),
      };
    },
    notEqualTo: (val, eq) => {
      return {
        errorMessage: `Il campo non può essere uguale a ${eq}`,
        isValid: +(val.toString() != eq),
      };
    },
    equalTo: (val, eq) => {
      return {
        errorMessage: `Il campo deve coincidere con ${eq}`,
        isValid: +(val.toString() == eq),
      };
    },
    equalToPassword: (val, eq) => {
      return {
        errorMessage: `Le due password non coincidono`,
        isValid: +(val.toString() == eq),
      };
    },
    minValue: (val, eq) => {
      return {
        errorMessage: `Valore minimo: ${eq}`,
        isValid: +(parseInt(val) >= eq),
      };
    },
    maxValue: (val, eq) => {
      return {
        errorMessage: `Valore massimo: ${eq}`,
        isValid: +(parseInt(val) <= eq),
      };
    },
    phone: (val) => {
      return {
        errorMessage: `Numero di telefono valido non valido`,
        // isValid: validatePhoneNumber(val),
        isValid:
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            val.trim()
          ),
      };
    },
    email: (val) => {
      return {
        errorMessage: `E-mail non valida`,
        isValid: +validateEmail(val),
      };
    },
  };

  check = (inputProps, inputRules) => {
    if (!inputRules || inputRules.length == 0)
      return { isValid: true, errorMessage: "" };

    let inputValue = inputProps.value;

    if (inputProps.hasOwnProperty("checked")) {
      inputValue = inputProps.checked;
    }

    let validityFactor = 1;
    let lastErrorMessage = "";

    inputRules.forEach((rule) => {
      const args = rule.split("--");
      let arg = args.length > 1 ? args[1] : null;
      const finalCheck = this.rules[args[0]](inputValue, arg);
      validityFactor *= finalCheck.isValid;
      if (0 == finalCheck.isValid) lastErrorMessage = finalCheck.errorMessage;
    });
    const isValid = validityFactor > 0;

    return { isValid, lastErrorMessage };
  };
}

export default new ValidationProvider();
