/**Action Types*/
import { SET_MOVEMENTS, DELETE_MOVEMENTS, DELETE_ALL_MOVEMENTS } from "./../actionTypes/movementsActionsTypes";

/**
 *
 * @param {*} month - month
 * @param {*} year - year
 * @param {*} data - data to be setted in the state
 * @returns The action to save data in correct month-year field
 */
export const setMovementsMonthYear = (month = null, year = null, data) => {
  return {
    type: SET_MOVEMENTS,
    payload: {
      month,
      year,
      data,
    },
  };
};
/**
 *
 * @param {*} month - month
 * @param {*} year - year
 * @returns The action to delete data from correct month-year field
 */
export const deleteMovementsMonthYear = (month = null, year = null) => {
  return {
    type: DELETE_MOVEMENTS,
    payload: {
      month,
      year,
    },
  };
};
/**
 *
 * @returns The action to delete all movements from the state
 */
export const deleteAllMovements = () => {
  return {
    type: DELETE_ALL_MOVEMENTS,
  };
};
