/**CSS */
import "./style.css";

const Button = ({
  type = "button",
  isEnabled = true,
  text = "Button",
  round = "",
  fullWidth = "",
  outline = "",
  theme = "primary",
  classList = "",
  onClick = () => {},
  id = "",
}) => {
  const classes = `cpar-button ${round && "round"} bg-color-${theme}${outline && "-outline"} ${
    fullWidth && "full-width"
  } ${classList}`;

  return (
    <button disabled={!isEnabled} id={id} type={type} className={classes} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
