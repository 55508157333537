/**Packages */
import { useSelector } from "react-redux";

/**Components */
import ContentBox from "../ContentBox";
import FlexRow from "../FlexRow";

/**Helpers*/
import { getImage } from "../../../helpers/image";
import { getLastAccess } from "../../../helpers/lastAccess";
import { getValueWithValuta } from "../../../helpers/valuta";
import { default as rp } from "../../../providers/ResourceProvider";

/**CSS */
import "./style.css";

const UserCard = ({
  version = "full", // full, compact, ...
  classList = "",
  children,
}) => {
  const user = useSelector((state) => state.user);
  const { name, surname, lastAccess, cf, cards, selectedCard, token } = user;
  const { id = null, pan = "", disponibilita = 0, fido = 0, totalMonthUsage = 0 } = selectedCard;

  const classes = `user-card-header user-card-header-${rp.getProductType()} ${classList} user-card-${version}`;
  return (
    <header className={classes}>
      <ContentBox>
        <FlexRow gridSize="md" classList=" cols-equal responsive gap no-padding">
          <div className="cpar-flex-col card-image-container">
            <div className="card-pan-container">
              <img
                alt={"Logo"}
                className="image _centered img-responsive"
                src={getImage(rp.getResource("userCard.image"))}
              />
              <div id="floating-card-number">{pan}</div>
            </div>
          </div>
          <div className="cpar-flex-col mobile-only">{children}</div>
          <div className="summary-container cpar-flex-col display-flex-column equal">
            {version == "full" && (
              <h6 className="inner-flex-row summary-header">situazione contabile al {getLastAccess(lastAccess)}</h6>
            )}

            <div className="only-large-desktop">
              <div className="inner-flex-row ">
                <h6>fido accordato</h6>
                <h3>{getValueWithValuta(fido)}</h3>
              </div>

              <div className="inner-flex-row">
                <h6>totale acquisti</h6>
                <h3>{getValueWithValuta(totalMonthUsage)}</h3>
              </div>
            </div>

            <div className="no-large-desktop flex-row-full">
              <div className="inner-flex-row ">
                <h6>fido accordato</h6>
                <h3>{getValueWithValuta(fido)}</h3>
              </div>

              <div className="inner-flex-row">
                <h6>totale acquisti</h6>
                <h3>{getValueWithValuta(totalMonthUsage)}</h3>
              </div>
            </div>

            <div className="inner-flex-row">
              <h6>disponibilità</h6>
              <h3>{getValueWithValuta(disponibilita)}</h3>
            </div>

            {/* {version === "compact" && (
              <div className="inner-flex-row text-center tablet-only">
                <h6>
                  modalità di invio estratto conto  :
                  <br />
                  <b>internet</b>
                  <br />
                  <br />
                  <TextLink
                    onClick={() => {
                      NavigationProvider.goTo(PAGES.USER_AREA.CHANGE_STANDING_EC);
                    }}
                    text="modifica"
                  />
                </h6>
              </div>
            )} */}
          </div>
        </FlexRow>
      </ContentBox>
    </header>
  );
};

export default UserCard;
