/**Packages */
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

/**Helpers */
import { getRandomString } from "../../../helpers/utils";

/**CSS */
import "./style.css";

const Select = ({
  type = "text",
  name = "",
  value = "",
  placeholder = "",
  required = false,
  disabled = false,
  readonly = false,
  withError = false,
  hideError = false,
  options = [],
  validIf = [],
  errorMessage = "Errore!!!",
  id = `floating-input-${name}-${(Math.random() + 1).toString(36).substring(5)}`,
  classList = "",
  onFocus = () => { },
  onBlur = () => { },
  onChange = (e) => { },
  children
}) => {
  const [isActive, setIsActive] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [togglePasswordClass, setTogglePasswordClass] = useState(faEye);

  function handleTextChange(e, currentValue) {
    if (currentValue !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    onChange(e, currentValue);
  }

  function getIsActive() {
    return isActive || value !== "";
  }

  const togglePassword = (e) => {
    e.preventDefault();

    if (inputType === "password") {
      setInputType("text");
      setTogglePasswordClass(faEyeSlash);
    } else {
      setInputType("password");
      setTogglePasswordClass(faEye);
    }
  };

  const classes = `float-label float-select floating-input-${name} ${classList} ${withError && "with-error"}`;

  return (
    <div className={classes}>
      <select
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {options.length && options.map((itm) => {
          let optItm = <option value={itm}>{itm}</option>;
          if (itm === -1) {
            optItm = <option value={-1}></option>;
          }

          return optItm;
        })}

        {!options.length && children}
      </select>

      <div className={`error-message ${!!hideError && 'hidden'}`}>{errorMessage}</div>
      <label className={getIsActive() ? "active" : ""} htmlFor={type}>
        {placeholder}
      </label>
    </div>
  );
};

export default Select;
