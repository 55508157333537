/**Packages */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCard from "../../../components/Elements/UserCard";
import Button from "../../../components/Elements/Button";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import Textarea from "../../../components/Form/Textarea";
import Checkbox from "../../../components/Form/Checkbox";
import ContentBox from "../../../components/Elements/ContentBox";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { validateEmail, validateGenericString } from "../../../helpers/validators";
import { customCareProcedure } from "../../../helpers/handler";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setSuccess } from "../../../redux/actions/successActions";

/**CSS */
import "./style.css";
import { PAGES } from "../../../helpers/constants";

const Support = () => {
  const { t, i18n, ready } = useTranslation();

  const stateUser = useSelector((state) => state.user);
  const { email: userEmail } = stateUser;

  const [email, setEmail] = useState(userEmail);
  const [text, setText] = useState("");
  const [infoAccepted, setInfoAccepted] = useState(false);

  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);
  const { selectedCard, cards, token } = user;

  const [formValidated, setFormValidated] = useState(false);
  const [cardSelected, setCardSelected] = useState(selectedCard?.id);

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorState = (title, message) => dispatch(setError(title, message));
  const setSuccessState = (title, message) =>
    dispatch(setSuccess(title, message));

  const validateInput = () => {
    let value = false;
    if (validateEmail(email) && validateGenericString(text) && infoAccepted) {
      value = true;
    }

    setFormValidated(value);
  };

  const onEmailHandler = (e, newValue = "") => {
    if (newValue.length >= 0) {
      setEmail(newValue);
    }
  };

  const onTextHandler = (e, newValue = "") => {
    if (newValue.length >= 0) {
      setText(newValue);
    }
  };

  const onChangeProductHandler = (e) => {
    const selectedCardId = e?.target?.value;
    if (selectedCardId) {
      setCardSelected(selectedCardId);
    }
  };

  const onClickHandler = async () => {
    // e.preventDefault();
    activateLoader();
    const onSuccessCallback = (data) => {
      deactivateLoader();
      // NavigationProvider.goToUserArea();
      setSuccessState(
        "Operazione eseguita correttamente",
        "Messaggio inoltrato con successo"
      );
      document.querySelector("textarea#body").setAttribute("value", "");
      document.querySelector("#acceptTos").checked = false;
    };
    const onErrorCallback = (data) => {
      deactivateLoader();
      setErrorState("Errore", "Errore durante la procedura di invio della richiesta, riprovare più tardi");
    };

    await customCareProcedure(token, email, cardSelected, text, onSuccessCallback, onErrorCallback);
  };

  useEffect(() => {
    validateInput();
  }, [email, text, infoAccepted]);

  const onInfoAccepted = (e) => {
    if (e === false) {
      setInfoAccepted(false);
    } else {
      const value = e.target.checked;
      setInfoAccepted(value);
    }
  };

  return (
    <>
      <TwoColumnsLayout id="support-page">
        <Row gridSize="md" classList="">
          <PageTitle inner title="servizio clienti" />
        </Row>

        {/* <Row gridSize="md" classList="space-below-md">
          <ContentBox compact classList="">
            <p>
              Richiesta di utilizzo{" "}
              <TextLink
                onClick={() => {
                  NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER);
                }}
                inline
                text="Clicca qui."
              />
            </p>
            <p>
              Vuoi attivare o disattivare il servizio SMS?{" "}
              <TextLink
                onClick={() => {
                  NavigationProvider.goTo(PAGES.USER_AREA.SMS_ACTIVATION);
                }}
                inline
                text="Clicca qui."
              />
            </p>
            {rp.getResource("support.topLinks") && (
              <p>
                Vuoi modificare la soglia minima per l’invio dell’SMS?{" "}
                <TextLink
                  onClick={() => {
                    NavigationProvider.goTo(PAGES.USER_AREA.SMS_ACTIVATION);
                  }}
                  inline
                  text="Clicca qui."
                />
              </p>
            )}
            <p>
              Vuoi verificare o modificare i tuoi dati?{" "}
              <TextLink
                onClick={() => {
                  NavigationProvider.goTo(PAGES.USER_AREA.ACCOUNT);
                }}
                inline
                text="Clicca qui."
              />
            </p>
          </ContentBox>
        </Row> */}

        <Row gridSize="md" classList="space-below-md">
          <ContentBox compact classList="">
            <Form onSubmit={onClickHandler} noSubmit classList="stack">
              <p>
                <b>Hai bisogno di ulteriori informazioni o hai delle segnalazioni da inoltrarci?</b> Compila il form
                sottostante, riceverai il prima possibile una risposta all’indirizzo da te rilasciato.
              </p>

              <h5>Indirizzo e-mail di contatto:</h5>
              <FloatingInput
                name="amount"
                id="amount"
                placeholder="E-mail"
                validIf={["email", "notEmpty"]}
                value={email}
                onChange={onEmailHandler}
              />

              <h5>Seleziona dal menu sottostante il prodotto a cui la segnalazione si riferisce:</h5>
              {cards?.length > 0 ? (
                <select name="productId" id="productId" onChange={onChangeProductHandler} value={cardSelected}>
                  {products.map((itm) => {
                    return (
                      <option value={itm?.id}>
                        {itm?.prodotto} {itm?.pan}
                      </option>
                    );
                  })}
                </select>
              ) : null}

              <h5>Testo della segnalazione:</h5>

              <Textarea
                name="body"
                id="body"
                rows="6"
                validIf={["notEmpty"]}
                value={text}
                onChange={(e) => {
                  onTextHandler(e, e?.target?.value);
                }}
              ></Textarea>

              {/* <TextLink classList="space-below-xs space-above-md" text="Informativa" /> */}

              <br />
              <div className="always-inline space-below-sm">
                <Checkbox
                  name="acceptTos"
                  id="acceptTos"
                  value="true"
                  label="Inoltrando la richiesta prendo atto che i miei dati personali sanno trattati in conformità all'informativa privacy resa da Compass Banca S.p.A. in occasione della sottoscrizione del contratto a me intestato."
                  checked={infoAccepted == true}
                  validIf={["checkboxChecked"]}
                  // onChange={(e) => {
                  //   setInfoAccepted(!infoAccepted);
                  // }}
                  onChange={(value) => {
                    onInfoAccepted(value);
                  }}
                />
              </div>

              <div>
                L'informativa di tempo in tempo aggiornata, sul trattamento dei dati personali da parte di Compass Banca S.p.A. è sempre consultabile sul sito <a target="_blank" href="http://www.compass.it">www.compass.it</a> sezione PRIVACY.
              </div>
              <br />

              <div className="inline-on-desktop btn-container">
                <Button
                  type="reset"
                  round
                  outline
                  text="torna indietro"
                  classList="btn-block"
                  onClick={NavigationProvider.goBack}
                />
                <Button
                  // isEnabled={formValidated}
                  type="submit"
                  round
                  text="inoltra"
                  classList="btn-block"
                />
              </div>
            </Form>
          </ContentBox>
        </Row>
      </TwoColumnsLayout>
    </>
  );
};
export default Support;
