import { WARNING_VISIBLE, WARNING_INVISIBLE } from "../actionTypes/warningActionsTypes";

const initialState = {
  warningVisible: false,
  title: "Attenzione",
  message: "Qualcosa è andato storto",
};

export const warningReducer = function (state = initialState, action) {
  const payload = action.payload ? action.payload : null;
  switch (action.type) {
    case WARNING_VISIBLE:
      return {
        ...state,
        title: payload.title ? payload.title : initialState.title,
        message: payload.message ? payload.message : initialState.message,
        warningVisible: true,
      };
    case WARNING_INVISIBLE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
