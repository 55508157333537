/**Packages */
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";

/**Helpers */
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**CSS */
import "./style.css";

const RegistrationConfirmation = () => {
  const { t, i18n, ready } = useTranslation();

  const { email } = NavigationProvider.getStateFromHistory();
  const phone = "06 45263322";

  return (
    <>
      <FullWidthLayout>
        <PageTitle small title={t("registration_text")} />

        <Row gridSize="xs" classList="space-below-md">
          <ContentBox fullWidthOnMobile classList="text-center">
            <p>
              {t("registration_confirmation_text_1")} <b>{rp.getProductName()}</b>.
            </p>
            <br />
            <p>{t("registration_confirmation_text_2", { email })}</p>
            <p>{t("registration_confirmation_text_3", { phone })}</p>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};
export default RegistrationConfirmation;
