/**Packages */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import MovementItemList from "../../../components/Elements/MovementItemList";
import FlexRow from "../../../components/Elements/FlexRow";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { getMovimentiProcedure } from "../../../helpers/handler";
import { getValueWithValuta } from "../../../helpers/valuta";
import { getReduxMonthYearKey } from "../../../helpers/utils";

/**Redux */
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { setMovementsMonthYear } from "../../../redux/actions/movementsActions";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";
import NavigationProvider from "../../../providers/NavigationProvider";

const MovementDetail = () => {
  const dispatch = useDispatch();
  const { t, i18n, ready } = useTranslation();

  const { monthYear, month, year, previousPage } =
    NavigationProvider.getStateFromHistory();

  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setMovementsRedux = (movementsData, month = null, year = null) => {
    dispatch(setMovementsMonthYear(month, year, movementsData));
  };
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const { selectedCard, token } = user;
  const { id = null } = selectedCard;
  const movements = useSelector((state) => state.movements);

  const getMovements = async () => {
    activateLoader();
    if (id) {
      const on200Callback = (data) => {
        setMovementsRedux(data, month, year);
        deactivateLoader();
      };

      const on500Callback = (data) => {
        deactivateLoader();
        setErrorState(
          "Errore",
          "Errore durante il download dei movimenti del mese, ritenta più tardi"
        );
      };

      await getMovimentiProcedure(
        token,
        parseInt(month),
        parseInt(year),
        id,
        on200Callback,
        on500Callback,
        setMovementsRedux
      );
    } else {
      setMovementsRedux([]);
      deactivateLoader();
    }
  };

  useEffect(async () => {
    const key = getReduxMonthYearKey(month, year);
    if (!movements[key]) {
      await getMovements();
    } else {
      console.log("prosegui ho il dato in cache");
    }
  }, []);

  const monthYearMovements = movements[getReduxMonthYearKey(month, year)],
    acquisti = monthYearMovements?.acquisti,
    ec = monthYearMovements?.ec,
    subCarte = monthYearMovements?.subCarte
      ? monthYearMovements?.subCarte
      : null;

  const {
    saldo = 0,
    acquisti: utilizzi = 0,
    pagamenti = 0,
    intCom: commissioni = 0,
  } = ec ? ec : {};

  return (
    <TwoColumnsLayout>
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        {/* @todo breadcrumb cmp */}
        <TextLink
          onClick={() => {
            NavigationProvider.goBack();
          }}
          classList="text-uppercase cpar-breadcrumb"
          text={`< ${previousPage}`}
        />
        <PageTitle inner title={`movimenti ${monthYear}`} />
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox withTitle="situazione contabile" compact>
          <div id="movements-balance-container">
            <header>
              <FlexRow>
                <div className="cpar-flex-col left-column">
                  Saldo inizio periodo
                </div>
                <div className="cpar-flex-col right-column">
                  {getValueWithValuta(saldo)} +
                </div>
              </FlexRow>
            </header>
            <FlexRow>
              <div className="cpar-flex-col left-column">Totale pagamenti</div>
              <div className="cpar-flex-col right-column">
                {getValueWithValuta(pagamenti)} -
              </div>
            </FlexRow>
            <FlexRow>
              <div className="cpar-flex-col left-column">Totale acquisti</div>
              <div className="cpar-flex-col right-column">
                {getValueWithValuta(utilizzi)} +
              </div>
            </FlexRow>
            <FlexRow>
              <div className="cpar-flex-col left-column">
                Totale commissioni
              </div>
              <div className="cpar-flex-col right-column">
                {getValueWithValuta(commissioni)} +
              </div>
            </FlexRow>
            <footer id="">
              <FlexRow>
                <div className="cpar-flex-col left-column">Saldo a debito</div>
                <div className="cpar-flex-col right-column">
                  {getValueWithValuta(utilizzi + commissioni)} +
                </div>
              </FlexRow>
            </footer>
          </div>
        </ContentBox>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox withTitle="movimenti del mese" compact>
          <MovementItemList elements={acquisti} />
        </ContentBox>
      </Row>

      {subCarte
        ? subCarte.map((subCarta) => {
            const { id, acquisti } = subCarta;
            const title = `movimenti del mese - carta aggiuntiva n: ${id}`;
            return (
              <Row gridSize="md" classList="space-below-md">
                <ContentBox withTitle={title} compact>
                  <MovementItemList elements={acquisti} />
                </ContentBox>
              </Row>
            );
          })
        : null}
    </TwoColumnsLayout>
  );
};
export default MovementDetail;
