/**Redux */
import { SET_USERS, DELETE_ALL_USERS } from "../actionTypes/usersActionsTypes";

const initialState = [];

export const usersReducer = (state = initialState, payload) => {
  switch (payload.type) {
    /**DEPRECATED REDUX USER ACTION */
    case SET_USERS: {
      const data = payload?.data;

      return [...data];
    }
    case DELETE_ALL_USERS: {
      return [...initialState];
    }
    default: {
      return state;
    }
  }
};
