/**Packages */
import { useTranslation } from "react-i18next";

/**Components */
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import OnlineSecurityComponent from "../../../components/Elements/OnlineSecurityComponent";

/**CSS */
import "./style.css";

const OnlineSecurity = () => {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <FullWidthLayout id="guest-online-security">
        <OnlineSecurityComponent />
      </FullWidthLayout>
    </>
  );
};
export default OnlineSecurity;
