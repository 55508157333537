/**Packages */
import { BrowserRouter as Router } from "react-router-dom";
import { Suspense } from "react";
import { useSelector } from "react-redux";

/**Components */
import Main from "./components/Layout/Main";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import ReactLoader from "./components/Elements/Loader";
import Message from "./components/Elements/Message";

/**Helpers */
import { SITE_MESSAGE_STATUS } from "./helpers/constants";
import { getBaseNameURL } from "./helpers/basename";

/**Redux */
import { expireError } from "./redux/actions/errorActions";
import { expireSuccess } from "./redux/actions/successActions";
import { expireWarning } from "./redux/actions/warningActions";

/**CSS */
import "./App.css";

const Loader = (value) => <ReactLoader visible={value} />;

function App() {
  const { loaderVisible } = useSelector((state) => state?.loader);
  const { errorVisible, title: errorTitle, message: errorMessage } = useSelector((state) => state?.error);
  const { successVisible, title: successTitle, message: successMessage } = useSelector((state) => state?.success);
  const { warningVisible, title: warningTitle, message: warningMessage } = useSelector((state) => state?.warning);

  return (
    <Router basename={getBaseNameURL()}>
      <Suspense fallback={Loader(true)}>
        <div id="layout-container">
          {Loader(loaderVisible)}
          {errorVisible && (
            <Message
              status={SITE_MESSAGE_STATUS.ERROR}
              expireAction={expireError}
              title={errorTitle}
              message={errorMessage}
            />
          )}
          {successVisible && (
            <Message
              status={SITE_MESSAGE_STATUS.SUCCESS}
              expireAction={expireSuccess}
              title={successTitle}
              message={successMessage}
            />
          )}
          {warningVisible && (
            <Message
              status={SITE_MESSAGE_STATUS.WARNING}
              expireAction={expireWarning}
              title={warningTitle}
              message={warningMessage}
            />
          )}
          <Header />
          <Main />
          <Footer />
        </div>
      </Suspense>
    </Router>
  );
}
export default App;
