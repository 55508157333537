import { ERROR_VISIBLE, ERROR_INVISIBLE } from '../actionTypes/errorActionsTypes';

const initialState = {
    errorVisible: false,
    title: 'Errore',
    message: 'Qualcosa è andato storto',
};

export const errorReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case ERROR_VISIBLE:
            return {
                ...state,
                title: payload.title ? payload.title : initialState.title,
                message: payload.message ? payload.message : initialState.message,
                errorVisible: true,
            };
        case ERROR_INVISIBLE:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};
