import React, { useState, useEffect, useContext } from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import TextLink from "../../../components/Elements/TextLink";
import Row from "../../../components/Elements/Row";

import { INDEX_URL, PAGES } from "../../../helpers/constants";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const EditPasswordConfirmation = () => {
  const { t, i18n, ready } = useTranslation();
  const [formValidated, setFormValidated] = useState(false);
  const [inputs, setInputs] = useState({
    texcode: "",
    email: "",
    reemail: "",
    cardnumber: "",
  });

  useEffect(() => {
    validateInput();
  });
  const handleEnterClick = () => {
    console.log("Clicked Enter");
  };

  const handleInputChange = (event) => {
    setInputs({ ...inputs, [event.target.id]: event.target.value });
  };

  const validateInput = () => {
    let validated = true;
    Object.keys(inputs).forEach((p) => {
      if (p === "cardnumber") {
        if (inputs[p].length !== 4) {
          validated = false;
        }
      } else if (inputs[p] === "") {
        validated = false;
      }
    });
    setFormValidated(validated);
  };

  return (
    <>
      <FullWidthLayout>
        <PageTitle small title="reimposta password">
          <p>La tua nuova password è stata impostata con successo.</p>

          <p>
            Per accedere subito all’area riservata{" "}
            <TextLink
              onClick={() => {
                NavigationProvider.goTo(PAGES.USER_AREA);
              }}
              inline
              text="clicca qui"
            />
            .
          </p>
        </PageTitle>
      </FullWidthLayout>
    </>
  );
};
export default EditPasswordConfirmation;
