/**Packages */
import moment from "moment";

/**Helpers */
import { DATES_OBSOLETE } from "./constants";

export const getLastAccess = (lastAccess = null) => {
  if (lastAccess) {
    const date = moment(lastAccess).format("DD/MM/YYYY");
    if (!DATES_OBSOLETE.includes(date)) {
      return lastAccess;
    }
  }

  return moment().format("DD/MM/YYYY HH:mm:ss");
};
