/**Components */
import Row from "../Row";

/**CSS */
import "./style.css";

const PageTitle = ({ title = "Title", small = false, subtitle = "", inner = false, children = "" }) => {
  const classes = `page-title ${inner && "inner-title"} ${small && "title-small"}`;
  return (
    <div className={classes}>
      <Row gridSize="md" classList="text-center">
        <h1>{title}</h1>
        {subtitle && (
          <div className="subtitle">
            <p className="space-below-md">{subtitle}</p>
            {children}
          </div>
        )}
        {children && <div className="subtitle">{children}</div>}
      </Row>
    </div>
  );
};

export default PageTitle;
