/**Packages */
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

/**Helpers */
import { getRandomString } from "../../../helpers/utils";

/**CSS */
import "./style.css";

const FloatingInput = ({
  type = "text",
  name = "",
  value = "",
  placeholder = "",
  required = false,
  disabled = false,
  readonly = false,
  withError = false,
  hideError = false,
  autocomplete = true,
  validIf = [],
  errorMessage = "Errore!!!",
  id = `floating-input-${name}-${(Math.random() + 1).toString(36).substring(5)}`,
  classList = "",
  onFocus = () => { },
  onBlur = () => { },
  onChange = (e) => { },
}) => {
  const [isActive, setIsActive] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [togglePasswordClass, setTogglePasswordClass] = useState(faEye);

  function handleTextChange(e, currentValue) {
    if (currentValue !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }

    onChange(e, currentValue);
  }

  function getIsActive() {
    return isActive || value !== "";
  }

  const togglePassword = (e) => {
    e.preventDefault();

    if (inputType === "password") {
      setInputType("text");
      setTogglePasswordClass(faEyeSlash);
    } else {
      setInputType("password");
      setTogglePasswordClass(faEye);
    }
  };

  const classes = `float-label floating-input-${name} ${classList} ${withError && "with-error"}`;

  return (
    <div className={classes}>

      <input
        autoComplete={autocomplete ? "on" : "new-password"}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        id={id}
        name={name}
        type={inputType}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => handleTextChange(e, e.target.value)}
      />

      <div className={`error-message ${!!hideError ? 'hidden' : ""}`}>{errorMessage}</div>
      <label className={getIsActive() ? "active" : ""} htmlFor={type}>
        {placeholder}
      </label>

      {type === "password" && (
        <i className="toggle-password-i" onClick={togglePassword}>
          <FontAwesomeIcon icon={togglePasswordClass} />
        </i>
      )}
    </div>
  );
};

export default FloatingInput;
