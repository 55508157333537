/**Helpers */
import { OTP_CASES, EC_METHODS_FULL } from "../helpers/constants";
import {
  transferProcedure,
  setSMSActivationProcedure,
  setECMethodProcedure,
  updateUserInfoProcedure,
  getCardsUpdatedDataProcedure,
} from "../helpers/handler";
import NavigationProvider from "../providers/NavigationProvider";

/**Redux */
import {
  setNewECMethod,
  setSMSActivation,
  updateSelectedProduct,
} from "../redux/actions/userActions";
import { updateProductById } from "../redux/actions/productsActions";

/**
 *
 * @param {*} clickCase - Case to be be performed (one from OTP_CASES constant)
 * @param {*} data - data to be used/sended
 * @param {*} deactivateLoader - Method to be used to deactivateLoader
 * @param {*} setErrorMessage - Method to be used to display error message
 * @param {*} setSuccessMessage - Method to be used to display success message
 * @param {*} dispatch - Method to be used to use redux state dispatcher
 *
 * The method complete operation of the page described by the clickCase prop, otherwise do nothing (only console.log something)
 */
export const getOnClickHandler = async (
  clickCase = "bonifico",
  data = {},
  deactivateLoader = () => {},
  setErrorMessage = () => {},
  setSuccessMessage = () => {},
  dispatch = () => {}
) => {
  switch (clickCase) {
    case OTP_CASES.BONIFICO: {
      const { token, id, amount, refundableIn, otp } = data;
      await transferProcedure(
        token,
        id,
        amount,
        parseInt(refundableIn),
        otp,
        async () => {
          const on200Callback = (data) => {
            deactivateLoader();
            dispatch(updateSelectedProduct(id, data?.card));
            dispatch(updateProductById(id, data?.card));
            setSuccessMessage(
              "Operazione completata",
              "Procedura di bonifico in conto avvenuta con successo"
            );
            NavigationProvider.goToUserArea();
          };
          const on500Callback = (data) => {
            deactivateLoader();
            setErrorMessage(
              "Errore",
              "Errore durante la procedura di aggiornamento dei dati"
            );
          };
          await getCardsUpdatedDataProcedure(
            token,
            id,
            on200Callback,
            on500Callback
          );
        },
        (error) => {
          deactivateLoader();
          setErrorMessage("Errore", error);
        }
      );
      break;
    }
    case OTP_CASES.SMS_ACTIVATION: {
      const {
        token,
        card_id,
        phone_number,
        min_amount,
        alertNuovaAutorizzazione,
        alertCalcoloNuovoEC,
        otp,
      } = data;
      await setSMSActivationProcedure(
        token,
        card_id,
        phone_number,
        parseFloat(min_amount),
        alertNuovaAutorizzazione,
        alertCalcoloNuovoEC,
        otp,
        async () => {
          const on200Callback = (data) => {
            deactivateLoader();
            dispatch(updateSelectedProduct(card_id, data?.card));
            dispatch(updateProductById(card_id, data?.card));
            setSuccessMessage(
              "Operazione completata",
              "Procedura di settaggio invio sms avvenuta con successo"
            );
            NavigationProvider.goToUserArea();
          };
          const on500Callback = (data) => {
            deactivateLoader();
            setErrorMessage(
              "Errore",
              "Errore durante la procedura di aggiornamento dei dati"
            );
          };
          await getCardsUpdatedDataProcedure(
            token,
            card_id,
            on200Callback,
            on500Callback
          );

          /*
          dispatch(
            setSMSActivation(
              card_id,
              parseFloat(min_amount),
              alertNuovaAutorizzazione,
              alertCalcoloNuovoEC,
              phone_number
            )
          );
          */
        },
        () => {
          deactivateLoader();
          setErrorMessage(
            "Errore",
            "Errore durante la procedura di settaggio invio sms"
          );
        }
      );
      break;
    }
    case OTP_CASES.EC_METHOD: {
      const { token, card_id, mode, email_cc_receiver, otp } = data;
      await setECMethodProcedure(
        token,
        card_id,
        mode,
        email_cc_receiver,
        otp,
        async () => {
          const on200Callback = (data) => {
            deactivateLoader();
            dispatch(updateSelectedProduct(card_id, data?.card));
            dispatch(updateProductById(card_id, data?.card));
            setSuccessMessage(
              "Operazione completata",
              "Procedura di settaggio metodo di invio dell'estratto conto avvenuta con successo"
            );
            NavigationProvider.goToUserArea();
          };
          const on500Callback = (data) => {
            deactivateLoader();
            setErrorMessage(
              "Errore",
              "Errore durante la procedura di aggiornamento dei dati"
            );
          };
          await getCardsUpdatedDataProcedure(
            token,
            card_id,
            on200Callback,
            on500Callback
          );
          /*
          dispatch(setNewECMethod(card_id, mode, EC_METHODS_FULL[mode], email_cc_receiver));
          */
        },
        (
          message = "Errore durante la procedura di settaggio metodo di invio dell'estratto conto"
        ) => {
          deactivateLoader();
          setErrorMessage("Errore", message);
        }
      );
      break;
    }
    case OTP_CASES.MODIFY_USER: {
      const {
        token,
        email,
        card_id,
        nPratica,
        telefono,
        cellulare,
        telefono2,
        indirizzo,
        cap,
        localita,
        provincia,
        otp,
      } = data;
      await updateUserInfoProcedure(
        token,
        email,
        card_id,
        nPratica,
        telefono,
        cellulare,
        telefono2,
        indirizzo,
        cap,
        localita,
        provincia,
        otp,
        () => {
          deactivateLoader();
          setSuccessMessage(
            "Operazione completata",
            "Procedura di settaggio dati anagrafici dell'utente avvenuta con successo"
          );
          NavigationProvider.goToUserArea();
        },
        (
          message = "Errore durante la procedura di settaggio dati anagrafici dell'utente"
        ) => {
          deactivateLoader();
          setErrorMessage("Errore", message);
        }
      );
      break;
    }
    default: {
      console.log("default case, do nothing");
      NavigationProvider.goBack();
      break;
    }
  }
};
