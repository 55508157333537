/**Helpers */
import { PRODOTTO_TIPO } from "./constants";
import { swapElement } from "./utils";
import { getImage } from "./image";

/**
 *
 * @param {*} arrayOffArray - array of array of products
 * @returns array of products (one hierarchy deleted)
 */
const fromArrayOfArrayOfProductsToArrayOfProducts = (arrayOffArray = []) => {
  const newArray = [];

  if (arrayOffArray.length > 0) {
    arrayOffArray.forEach((itm) => {
      itm.forEach((childItm) => {
        newArray.push(childItm);
      });
    });
  }

  return newArray;
};
/**
 *
 * @param {*} users - array of users
 * @param {*} starIdx - starting idx
 * @returns return array of users with new prop "id"
 */
export const getUsersWithId = (users = [], starIdx = 0) => {
  return users?.map((utente, idx) => {
    return { id: idx + starIdx, ...utente };
  });
};
/**
 *
 * @param {*} users - array of users
 * @returns array of products formatted (adding idArrayUsers and idArrayProducts props)
 */
export const getArrayOfProductsWithIds = (users = []) => {
  const arrOfArr = users.map((utenteMapped) => {
    return utenteMapped.cards.map((card, idx) => {
      return {
        idArrayUsers: utenteMapped.id,
        idArrayProducts: idx,
        ...card,
      };
    });
  });

  return fromArrayOfArrayOfProductsToArrayOfProducts(arrOfArr);
};
/**
 *
 * @param {*} products - array of products of the user
 * @returns an array formatted in order to be passed to DropDownMenu component
 */
export const getFormattedCards = (products = [], selectedProduct = { id: null }) => {
  if (!products || !Array.isArray(products) || products.length <= 0) return [];

  const arrOfProducts = products.map((product) => {
    const { carta } = product;
    const prodottoFoundedTipo = Object.keys(PRODOTTO_TIPO).find((key) => {
      const prodotto_tipo = PRODOTTO_TIPO[key];
      return prodotto_tipo.id === carta;
    });

    if (prodottoFoundedTipo) {
      const prodottoFounded = PRODOTTO_TIPO[prodottoFoundedTipo];
      return {
        id: product.id,
        idArrayUsers: product.idArrayUsers,
        idArrayProducts: product.idArrayProducts,
        title: prodottoFounded.title,
        icon: getImage(prodottoFounded.image),
      };
    }

    return null;
  });

  if (arrOfProducts.length > 0) {
    const selectedProductIdx = arrOfProducts.findIndex((productFormatted) => {
      return productFormatted.id === selectedProduct?.id;
    });

    if (selectedProductIdx >= 0) {
      return swapElement(arrOfProducts, 0, selectedProductIdx);
    }

    return arrOfProducts;
  }

  return [];
};
/**
 *
 * @param {*} arrayOfProducts - array of products
 * @param {*} productIdToFind - id to found
 * @returns The method return the index of the item founded by the parameter productIdToFind, otherwise -1
 */
export const getIdxProductFromArray = (arrayOfProducts = [], productIdToFind = null) => {
  return arrayOfProducts.findIndex((product) => {
    return product?.id === productIdToFind;
  });
};
/**
 *
 * @param {*} arrayOfProducts - array of products
 * @param {*} productIdToFind - id to found
 * @returns The method return the element of the item founded by the parameter productIdToFind, otherwise undefined
 */
export const getProductFromArray = (arrayOfProducts = [], productIdToFind = null) => {
  return arrayOfProducts.find((product) => {
    return product?.id === productIdToFind;
  });
};
