/**Packages */
import { request } from "monforteclient";

/**Helpers */
import { createSuccessResponse, createErrorResponse } from "../helpers/response";
import { getAxiosHeadersConfig } from "../helpers/request";
import config from "../helpers/config";

const operation = {
  /**
   *
   * @param {*} param0 { token, nCarta, nPratica = "" } -> nPratica not needed
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  getOtp: async ({ token, nCarta, nPratica = "" }, on200Callback, on500Callback) => {
    const body = {
      nCarta,
      //nPratica,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.getOtp}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
        data: JSON.stringify(body),
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.getOtp);
    }
  },
  /**
   *
   * @param {*} param0 { token, nCarta }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  getCardsData: async ({ token, nCarta }, on200Callback, on500Callback) => {
    const body = {
      card_id: nCarta,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.getCardsData}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
        data: JSON.stringify(body),
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.getCardsData);
    }
  },
  /**
   *
   * @param {*} param0 { token, card_id, amount, refundable_in, otp }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   * @returns
   */
  transfer: async ({ token, card_id, amount, refundable_in, otp }, on200Callback, on500Callback) => {
    const body = {
      card_id,
      amount,
      refundable_in,
      otp,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.transfer}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
        data: JSON.stringify(body),
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.transfer);
    }
  },
  /**
   *
   * @param {*} param0  { token, card_id, mode, email_cc_receiver, otp }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called
   */
  setCCMethod: async (
    {
      token,
      card_id,
      /**
       * Cartaceo: C
       * Internet: I
       * Email: E -> not used
       */
      mode,
      email_cc_receiver,
      otp,
    },
    on200Callback,
    on500Callback
  ) => {
    const body = {
      card_id,
      mode,
      email_cc_receiver,
      otp,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.SetECMethod}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
        data: JSON.stringify(body),
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.SetECMethod);
    }
  },
  /**
   *
   * @param {*} param0 { token, card_id, phone_number, min_amount, alertNuovaAutorizzazione, alertCalcoloNuovoEC, otp }
   * @param {*} on200Callback - callback to use in case api return success code
   * @param {*} on500Callback - callback to use in case api return error codes
   * @returns The result of the api called500Callback
   */
  SMSActivation: async (
    { token, card_id, phone_number, min_amount, alertNuovaAutorizzazione, alertCalcoloNuovoEC, otp },
    on200Callback,
    on500Callback
  ) => {
    const body = {
      card_id,
      phone_number,
      min_amount,
      alertNuovaAutorizzazione,
      alertCalcoloNuovoEC,
      otp,
    };

    try {
      const result = await request({
        url: `${config.baseUrl}${config.urls.SMSActivation}`,
        language: "it",
        method: "POST",
        ...getAxiosHeadersConfig(token),
        on200Callback,
        on500Callback,
        on400Callback: on500Callback,
        on401Callback: on500Callback,
        on403Callback: on500Callback,
        on404Callback: on500Callback,
        data: JSON.stringify(body),
      });

      return result;
    } catch (e) {
      console.error("e: ", e);
      return createErrorResponse(e, config.urls.SMSActivation);
    }
  },
};

export default operation;
