import React, { useState, useEffect, useContext } from "react";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import TextLink from "../../../components/Elements/TextLink";
import FloatingInput from "../../../components/Form/FloatingInput";
import Form from "../../../components/Form/Form";
import MovementItemList from "../../../components/Elements/MovementItemList";
import MovementsFilterableList from "../../../components/Elements/MovementsFilterableList";

/**CSS */
import "./style.css";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";
import { PAGES } from "../../../helpers/constants";

const BankAdvanceSimulation = () => {
  const { t, i18n, ready } = useTranslation();
  const [formValidated, setFormValidated] = useState(false);
  const [cardOwner, setCardOwner] = useState("");
  const [ecSendMode, setEcSendMode] = useState("");

  const getLastRangeValue = () => {
    // @todo use a different storage system to persist during the session
    return sessionStorage.getItem("simulationRangeValue") || 50;
  };
  const setLastRangeValue = (rangeValue) => {
    // @todo use a different storage system to persist during the session
    sessionStorage.setItem("simulationRangeValue", rangeValue);
  };
  const [rangeValue, setRangeValue] = useState(getLastRangeValue());

  // Handle the slider widget rendering job
  useEffect(() => {
    onRangeChange({ target: { value: getLastRangeValue() } });
  }, []);

  const onCardOwnerChange = (value) => {
    setCardOwner(value);
    sessionStorage.setItem("cardOwner", value);
  };
  const onEcSendModeChange = (value) => {
    setEcSendMode(value);
    sessionStorage.setItem("ecSendMode", value);
  };

  // Make sure we position the slider handle text in the correct place whenever we move the handle
  const onRangeChange = (ev) => {
    const value = ev.target.value;
    console.log("chamge??");
    setRangeValue(value);
    setLastRangeValue(value);

    if (value / 100 >= 0.85) {
      console.log("max");
      document.querySelector(".min-max-labels.max").classList.add("down");
    } else if (value / 100 <= 0.15) {
      console.log("min");
      document.querySelector(".min-max-labels.min").classList.add("down");
    } else {
      document.querySelector(".min-max-labels.max").classList.remove("down");
      document.querySelector(".min-max-labels.min").classList.remove("down");
    }

    const sliderContainerNode = document.getElementsByClassName("slider-container")[0];
    const handleValueNode = document.getElementById("slider-handle-value");

    handleValueNode.style.left = `${sliderContainerNode.clientWidth * (value / 100) - 200 * (value / 100)}px`;
  };

  // we need the same flow in two different places, let's just make our life easier
  const goBack = () => {
    sessionStorage.removeItem("cardOwner");
    sessionStorage.removeItem("ecSendMode");
    sessionStorage.removeItem("simulationRangeValue");
    NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER);
  };

  return (
    <TwoColumnsLayout id="bank-advance-simulation">
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        {/* @todo breadcrumb cmp */}
        <TextLink
          onClick={() => {
            NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER);
          }}
          classList="text-uppercase cpar-breadcrumb"
          text={`< ${rp.getResource("header.bankAdvanceTitle")}`}
        />
        <PageTitle inner title="calcola la rata della tua carta bancoposta più">
          <p className="text-clear-formatting">
            Con Carta BancoPosta Più puoi decidere di rimborsare a rate i tuoi acquisti. Calcolare la rata è
            semplicissimo: inserisci le informazioni richieste e premi il pulsante “calcola”.
          </p>
        </PageTitle>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox compact>
          <Form noSubmit>
            <h3>Titolare di carta bancoposta più?</h3>
            <div className="inline-input-group">
              <label htmlFor="owner-yes">
                <input
                  name="cardOwner"
                  id="owner-yes"
                  checked={sessionStorage.getItem("cardOwner") && sessionStorage.getItem("cardOwner") == "yes"}
                  value="yes"
                  onChange={(e) => {
                    onCardOwnerChange(e.target.value);
                  }}
                  type="radio"
                />{" "}
                Sì
              </label>
              <label htmlFor="owner-no">
                <input
                  name="cardOwner"
                  id="owner-no"
                  checked={sessionStorage.getItem("cardOwner") && sessionStorage.getItem("cardOwner") == "no"}
                  value="no"
                  onChange={(e) => {
                    onCardOwnerChange(e.target.value);
                  }}
                  type="radio"
                />{" "}
                No
              </label>
            </div>

            <br />
            <h3>
              Importo da rateizzare<span className="no-large-desktop">: € {rangeValue}</span>
            </h3>
            <div className="slider-container">
              <span className="min-max-labels min">Min. 0</span>
              <input
                type="range"
                className="slider my-3"
                min="0"
                max="100"
                value={rangeValue}
                onChange={onRangeChange}
              ></input>
              <span id="slider-handle-value">€ {rangeValue}</span>
              <span className="min-max-labels max">Min. 100</span>
            </div>

            <br />
            <h3>Modalità invio estratto conto</h3>
            <div className="inline-input-group">
              <label htmlFor="ec-paper">
                <input
                  name="ecSendMode"
                  id="ec-paper"
                  value="paper"
                  checked={sessionStorage.getItem("ecSendMode") && sessionStorage.getItem("ecSendMode") == "paper"}
                  onChange={(e) => {
                    onEcSendModeChange(e.target.value);
                  }}
                  type="radio"
                />{" "}
                Carta
              </label>
              <label htmlFor="ec-online">
                <input
                  name="ecSendMode"
                  id="ec-online"
                  value="online"
                  checked={sessionStorage.getItem("ecSendMode") && sessionStorage.getItem("ecSendMode") == "online"}
                  onChange={(e) => {
                    onEcSendModeChange(e.target.value);
                  }}
                  type="radio"
                />{" "}
                Online
              </label>
            </div>

            <div className="inline-on-desktop btn-container">
              <Button type="reset" round outline text="Annulla" classList="btn-block" onClick={goBack} />
              <Button
                type="submit"
                round
                text="Calcola"
                classList="btn-block"
                onClick={() => {
                  NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER_CALCULATE_RATE_SUMM, { amount: rangeValue });
                }}
              />
            </div>
          </Form>
        </ContentBox>
      </Row>
    </TwoColumnsLayout>
  );
};
export default BankAdvanceSimulation;
