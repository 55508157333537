/**Packages */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

/**Components */
import TextLink from "../../Elements/TextLink";
import FlexRow from "../../Elements/FlexRow";
import DropDownMenu from "../../Layout/DropDownMenu";
import SideMenu from "../SideMenu";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { getImage } from "../../../helpers/image";
import { getFormattedCards } from "../../../helpers/user";
import { logoutProcedure } from "../../../helpers/handler";
import { getCurrentLng, changeLang } from "../../../helpers/language";

/**Redux */
import { TOKEN_EXPIRED } from "../../../redux/actionTypes/tokenActionTypes";
import { USER_LOGGED_OUT } from "../../../redux/actionTypes/userActionTypes";
import { DELETE_ALL_MOVEMENTS } from "../../../redux/actionTypes/movementsActionsTypes";
import { DELETE_ALL_RESUMES } from "../../../redux/actionTypes/resumesActionsTypes";
import { DELETE_ALL_PRODUCTS } from "../../../redux/actionTypes/productsActionsTypes";
import { DELETE_ALL_USERS } from "../../../redux/actionTypes/usersActionsTypes";
import { changeSelectedProduct, setSelectedUserAction } from "../../../redux/actions/userActions";

/**CSS */
import "./style.css";
import "./hamburger.css";

const Header = () => {
  const dispatch = useDispatch();
  const { t, i18n, ready } = useTranslation();

  const { isLoggedIn: isLogged, cards, selectedCard } = useSelector((state) => state?.user);
  const users = useSelector((state) => state?.users);
  const products = useSelector((state) => state?.products);

  const setSelectedUser = (user) => {
    dispatch(setSelectedUserAction(user));
  };

  const setSelectedProduct = (product) => {
    dispatch(changeSelectedProduct(product?.id));
  };

  //const [isSelectorOpened, setSelectorOpened] = useState(false);

  const [language, setLanguage] = useState(getCurrentLng());
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logout = () => {
    setMobileMenuOpen(false);

    const cookies = new Cookies();
    cookies.remove("userInfo", { path: "/" });

    dispatch({ type: TOKEN_EXPIRED });
    dispatch({ type: USER_LOGGED_OUT });
    dispatch({ type: DELETE_ALL_RESUMES });
    dispatch({ type: DELETE_ALL_MOVEMENTS });
    dispatch({ type: DELETE_ALL_PRODUCTS });
    dispatch({ type: DELETE_ALL_USERS });

    logoutProcedure();
    rp.resetProductType();
    NavigationProvider.goToLoginPage();
  };

  localStorage.setItem("lang", language);

  /*
  const toggleLanguageSelector = () => {
    setSelectorOpened(isSelectorOpened ? false : true);
  };

  const changeAndCloseLanguageSelector = (lang) => {
    const currentLng = localStorage.getItem("lang");
    if (lang && lang !== "" && lang !== currentLng) {
      setLanguage(lang);
      changeLang(lang);
    } else {
      console.log("Lingua already selected");
    }
    setSelectorOpened(false);
  };
  */

  // handle mobile menu animation and toggle show/hide
  const onDocumentScrollEventListener = () => {
    setMobileMenuOpen(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", onDocumentScrollEventListener);

    return () => {
      window.removeEventListener("scroll", onDocumentScrollEventListener);
    };
  });
  const onSideMenuItemClicked = () => {
    setMobileMenuOpen(false);
  };

  const goToPageAndCloseMobileMenu = (page) => {
    setMobileMenuOpen(false);
    // NavigationProvider.goTo(PAGES.USER_AREA.ACCOUNT);
    NavigationProvider.goTo(page);
  };

  // @todo refactor
  const onTopMenuItemClick = (nodeId, page) => {
    resetNavigationLink();
    setTimeout(() => {
      const nodes = document.getElementsByClassName(nodeId);
      for (let idx = 0; idx < nodes.length; idx++) {
        nodes[idx].classList.add("strong");
      }
    }, 100);
    NavigationProvider.goTo(page);
  };

  const onFloatingMenuItemClick = (nodeId, page) => {
    resetNavigationLink();
    setTimeout(() => {
      const nodes = document.getElementsByClassName(nodeId);
      for (let idx = 0; idx < nodes.length; idx++) {
        nodes[idx].classList.add("strong");
      }
    }, 100);

    goToPageAndCloseMobileMenu(page);
  };

  const resetNavigationLink = () => {
    const linkAreas = [
      "#header-navigation .text-link.strong",
      "#user-side-menu .text-link.strong",
      "#header-navigation-mobile .text-link.strong",
      "#header-user-actions .text-link.strong",
    ];

    linkAreas.forEach((l) => {
      let allNodes = document.querySelectorAll(l);
      allNodes.forEach((n) => {
        n.classList.remove("strong");
      });
    });
  };

  // @todo sostituisci pure con la tua parte
  const onProductClick = (product) => {
    if (product) {
      const { idArrayUsers, idArrayProducts } = product;
      const selectedUser = users[idArrayUsers];
      const selectedProduct = selectedUser?.cards[idArrayProducts];
      setSelectedUser(selectedUser);
      setSelectedProduct(selectedProduct);
      NavigationProvider.goToUserArea();
    }
  };

  return (
    <>
      <header
        id="layout-header"
        className={`bg-color-primary ${rp.getProductType()} ${isLogged ? "private-page" : "guest-page"}`}
      >
        <FlexRow classList="lg v-centered">
          <div className="header-logo left clickable">
            <img
              className=""
              onClick={() => {
                resetNavigationLink();
                NavigationProvider.goTo(PAGES.USER_AREA);
              }}
              // src={getImage("images/logo/cartaBancoPostaPiu.svg")}
              src={getImage(rp.getResource("header.topLeftLogo"))}
              alt="Carta Banco Posta +"
            />
          </div>

          <nav id="header-navigation" className="d-none d-md-flex">
            <ul className="inline flex-v-centered">
              <li>
                <TextLink
                  classList="top-menu-movements"
                  onClick={(e) => {
                    onTopMenuItemClick("top-menu-movements", PAGES.USER_AREA.MOVEMENTS);
                  }}
                  text={t("movimenti")}
                />
              </li>
              <li>
                <TextLink
                  classList="top-menu-account-balance"
                  onClick={(e) => {
                    onTopMenuItemClick("top-menu-account-balance", PAGES.USER_AREA.ACCOUNT_BALANCE);
                  }}
                  text={t("estratti conto")}
                />
              </li>
              <li>
                <TextLink
                  classList="top-menu-bank-transfer"
                  onClick={(e) => {
                    onTopMenuItemClick("top-menu-bank-transfer", PAGES.USER_AREA.BANK_TRANSFER);
                  }}
                  text={rp.getResource("header.bankAdvanceTitle")}
                />
              </li>
            </ul>
          </nav>

          <div id="header-user-actions">
            {/* @todo cmp */}

            <DropDownMenu
              id="card-dropdown"
              menuItems={getFormattedCards(products, selectedCard)}
              onClick={onProductClick}
            />

            <div className="dropdown-menu-container">
              <div className="icon-with-bottom-text">
                <img
                  id="mobile-menu-profile-icon"
                  className="icon"
                  src={getImage("images/top-menu/profile.svg")}
                  alt="Il mio Account"
                />
                <span className="bottom-text text-uppercase">profilo</span>
              </div>

              <div className="cpar-dropdown-menu">
                <ul>
                  {/* <li>
                    <TextLink
                      classList="floating-menu-account"
                      onClick={() => { onFloatingMenuItemClick("floating-menu-account", PAGES.USER_AREA.ACCOUNT) }}
                      text="profilo"
                    />
                  </li> */}
                  <li>
                    <TextLink
                      classList="floating-menu-account-access"
                      onClick={() => {
                        onFloatingMenuItemClick("floating-menu-account-access", PAGES.USER_AREA.ACCOUNT);
                      }}
                      // onClick={() => { onFloatingMenuItemClick("floating-menu-account-access", PAGES.USER_AREA.ACCOUNT_ACCESS) }}
                      text="dati anagrafici"
                    />
                  </li>
                  <li>
                    <TextLink
                      classList="floating-menu-edit-password"
                      onClick={() => {
                        onFloatingMenuItemClick("floating-menu-edit-password", PAGES.USER_AREA.EDIT_PASSWORD);
                      }}
                      text="modifica password"
                    />
                  </li>
                  {/* <li>
                    <TextLink
                      classList="floating-menu-inbox"
                      onClick={() => { onFloatingMenuItemClick("floating-menu-inbox", PAGES.USER_AREA.INBOX) }}
                      text="comunicazioni"
                    />
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="icon-with-bottom-text">
              <img
                onClick={logout}
                id="mobile-menu-logout-icon"
                className="icon"
                src={getImage("images/top-menu/logout.svg")}
                alt="Logout"
              />
              <span className="bottom-text text-uppercase">logout</span>
            </div>
            <button
              id="mobile-menu-hamburger"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
              }}
              className={`icon hamburger hamburger--collapse ${mobileMenuOpen && "is-active"}`}
              type="button"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>

          <div className="header-logo right">
            <img src={getImage("images/logo/posteItaliane.svg")} alt="Poste Italiane" />
          </div>
        </FlexRow>
      </header>

      <SideMenu
        open={mobileMenuOpen}
        onBackdropClick={onSideMenuItemClicked}
        onMenuItemClicked={onSideMenuItemClicked}
      />
    </>
  );
};
export default Header;
