import { SUCCESS_VISIBLE, SUCCESS_INVISIBLE } from "../actionTypes/successActionsTypes";

const initialState = {
  successVisible: false,
  title: "Azione completata",
  message: "Azione completata con successo",
};

export const successReducer = function (state = initialState, action) {
  const payload = action.payload ? action.payload : null;
  switch (action.type) {
    case SUCCESS_VISIBLE:
      return {
        ...state,
        title: payload.title ? payload.title : initialState.title,
        message: payload.message ? payload.message : initialState.message,
        successVisible: true,
      };
    case SUCCESS_INVISIBLE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
