/**Packages */
import { useSelector } from "react-redux";

/**Components */
import ContentBox from "../ContentBox";
import FlexRow from "../FlexRow";
import TextLink from "../TextLink";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { getImage } from "../../../helpers/image";
import { getValueWithValuta } from "../../../helpers/valuta";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**CSS */
import "./style.css";

const getProdottoPanFormatted = (prodotto = "", pan = "") => {
  //return `${prodotto} ${pan}`;
  return `${prodotto}`.toUpperCase();
};

const UserCard = ({ columns = ["availability", "fido", "ecMode"], classList = "", children }) => {
  const user = useSelector((state) => state.user);
  const { selectedCard } = user;
  const {
    id = "",
    disponibilita = 0,
    fido = 0,
    pan = "",
    prodotto = "",
    modInvioECDESC = "",
    pagGiornoRid = "",
  } = selectedCard;
  const classes = `user-card-header user-card-header-${rp.getProductType()} ${classList} user-card-compact`;

  const availableColumns = {
    availability: () => {
      return (
        <div className="inner-flex-row">
          <h6>disponibilità</h6>
          <h3>{getValueWithValuta(disponibilita)}</h3>
        </div>
      );
    },
    productCode: () => {
      return (
        <div className="inner-flex-row">
          <h6>{rp.getResource("userCard.productCodeTitle")}</h6>
          <h3>{getProdottoPanFormatted(prodotto, pan)}</h3>
        </div>
      );
    },
    productType: () => {
      return (
        <div className="inner-flex-row user-card-product-type-column">
          <h6>{rp.getResource("userCard.productTitle")}</h6>
          <h3>{getProdottoPanFormatted(prodotto, pan)}</h3>
        </div>
      );
    },
    fido: () => {
      return (
        <div className="inner-flex-row ">
          <h6>fido accordato</h6>
          <h3>{getValueWithValuta(fido)}</h3>
        </div>
      );
    },
    ecMode: () => {
      return (
        <div className="inner-flex-row text-center tablet-only flex-2">
          <h6>
            modalità di invio estratto conto :
            <br />
            <b>{modInvioECDESC}</b>
            <br />
            <br />
            <TextLink
              onClick={() => {
                NavigationProvider.goTo(PAGES.USER_AREA.CHANGE_STANDING_EC);
              }}
              text="modifica"
            />
          </h6>
        </div>
      );
    },
    currentEcMode: () => {
      return (
        <div className="inner-flex-row">
          <h6>OGGI CONSULTI L’ESTRATTO CONTO IN MODALITÀ</h6>
          <h3>{modInvioECDESC}</h3>
        </div>
      );
    },
    ticketNumberAndExpiration: () => {
      return (
        // <div className="flex-responsive">
        <>
          <div className="inner-flex-row">
            <h6>numero pratica</h6>
            <h3>{id}</h3>
          </div>
          <div className="inner-flex-row">
            <h6>{rp.getResource("userCard.expirationTitle")}</h6>
            <h3 style={{ marginBottom: 0 }}>{pagGiornoRid} di ogni mese</h3>
            <small className="text-xs text-uppercase">(salvo giorno festivo)</small>
          </div>
        </>
        // </div>
      );
    },
  };

  return (
    <header className={classes}>
      <ContentBox>
        <FlexRow gridSize="md" classList=" cols-equal responsive gap no-padding">
          <div className="cpar-flex-col card-image-container">
            <div className="card-pan-container">
              <img
                alt={"Logo"}
                className="image _centered img-responsive"
                src={getImage(rp.getResource("userCard.image"))}
              />
              <div id="floating-card-number">{pan}</div>
            </div>
          </div>
          <div className="cpar-flex-col mobile-only">{children}</div>
          <div className="summary-container cpar-flex-col display-flex-column equal">
            {columns.map((columnName) => {
              return availableColumns[columnName]();
            })}
          </div>
        </FlexRow>
      </ContentBox>
    </header>
  );
};

export default UserCard;
