/**Packages */
import moment from "moment";

/**Helpers */
import { VIAGGIANTI } from "./constants";

/**
 *
 * @returns actual pathname (page), otherwise empty string
 */
export const getCurrentPage = () => {
  return window?.location?.pathname ? window?.location?.pathname : "";
};
/**
 *
 * @param {*} locationSearch - location.search string
 * @returns array of parameteres from locationSearch, otherwise empty array
 */
export const getParametersFromUrl = (locationSearch = "") => {
  if (locationSearch.search?.length > 0) {
    return locationSearch.search.substr(1).split("&");
  }

  return [];
};
/**
 *
 * @param {*} month - month selected
 * @param {*} year - year selected
 * @returns if month and year are null return "viaggianti", otherwise month-year
 */
export const getReduxMonthYearKey = (month = null, year = null) => {
  if (month === null && year === null) return VIAGGIANTI;

  return `${month}-${year}`;
};
/**
 *
 * @param {*} minus - how much months to subtract from now
 * @returns key (month-year) of the month wanted
 */
export const getMonthYearPrevious = (minus = 1) => {
  const previousMonth = moment().subtract(minus, "months").format("YYYY-MM");
  const splitted = previousMonth.split("-");

  return getReduxMonthYearKey(splitted[1], splitted[0]);
};
/**
 *
 * @param {*} str - string to be trimmed
 * @returns str trimmed (if str is a valid string), otherwise empty string = ""
 */
export const trimString = (str = "") => {
  if (!str || typeof str !== "string") return "";

  return str.trim();
};
/**
 *
 * @param {*} array - array of elements
 * @param {*} indexA - 1st index of element to be swapped
 * @param {*} indexB - 2nd index of element to be swapped
 * @returns array with element of indexA and indexB swapped
 */
export const swapElement = (array, indexA, indexB) => {
  const arrayCP = [...array];
  const tmp = arrayCP[indexA];

  arrayCP[indexA] = arrayCP[indexB];
  arrayCP[indexB] = tmp;

  return arrayCP;
};
