import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import Button from "../../../components/Elements/Button";
import TextLink from "../../../components/Elements/TextLink";

/**Helpers */
import { getValueWithValuta } from "../../../helpers/valuta";

/**CSS */
import "./style.css";
import NavigationProvider from "../../../providers/NavigationProvider";
import { PAGES } from "../../../helpers/constants";

const BankAdvanceSimulationSummary = () => {
  const { state } = useLocation();
  const { amount } = state;
  // @todo da dove lo prendo???
  const refundableIn = 6;
  const { t, i18n, ready } = useTranslation();

  return (
    <TwoColumnsLayout>
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        {/* @todo breadcrumb cmp */}
        <TextLink
          onClick={() => {
            NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER_CALCULATE_RATE);
          }}
          classList="text-uppercase cpar-breadcrumb"
          text="< calcola la rata"
        />
        <PageTitle inner title="riepilogo" />
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox compact id="bank-advance-summary-container">
          <FlexRow gridSize="md" classList="cols-equal responsive space-below-lg">
            <div className="cpar-flex-col">
              <div className="inner-flex-row">
                <h6>importo da rateizzare</h6>
                <p className="amount">{getValueWithValuta(amount)}</p>
              </div>
            </div>
            <div className="cpar-flex-col">
              <div className="inner-flex-row">
                <h6>numero rate</h6>
                <p className="amount">{refundableIn}</p>
              </div>
            </div>
            <div className="cpar-flex-col">
              <div className="inner-flex-row">
                <h6>importo rata mensile</h6>
                <p className="amount">0</p>
              </div>
            </div>
          </FlexRow>
          <FlexRow gridSize="md" classList=" cols-equal responsive">
            <div className="cpar-flex-col">
              <div className="inner-flex-row">
                <h6>importo totale dovuto</h6>
                <p className="amount">{getValueWithValuta(amount)}</p>
              </div>
            </div>
            <div className="cpar-flex-col">
              <div className="inner-flex-row">
                <h6>TAEG*</h6>
                <p className="amount">0</p>
              </div>
            </div>
            <div className="cpar-flex-col">
              <div className="inner-flex-row"></div>
            </div>
          </FlexRow>
        </ContentBox>

        <br className="mobile-only" />

        <ContentBox classList="sub-panel">
          <div className="inner-panel">
            <FlexRow gridSize="md" classList=" responsive">
              <p>L'importo della rata è composto da:</p>
            </FlexRow>

            <FlexRow gridSize="md" classList=" responsive">
              <div className="cpar-flex-col flex-1">Quota capitale {getValueWithValuta(0)}</div>
              <div className="cpar-flex-col flex-2">
                Commissione per rimborso rateale (0.50%) {getValueWithValuta(0)}
              </div>
            </FlexRow>
            <FlexRow gridSize="md" classList=" responsive">
              <div className="cpar-flex-col flex-1">Imposta di bollo su E/C {getValueWithValuta(0)}</div>
              <div className="cpar-flex-col flex-2">Spese produzione e invio E/C {getValueWithValuta(0)}</div>
            </FlexRow>
          </div>
        </ContentBox>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <div className="inline-on-desktop btn-container">
          <Button
            round
            outline
            text="modifica simulazione"
            classList="btn-block"
            onClick={() => {
              NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER_CALCULATE_RATE);
            }}
          />
          <Button
            round
            text="Prosegui"
            classList="btn-block"
            onClick={() => {
              NavigationProvider.goTo(PAGES.USER_AREA.BANK_TRANSFER_REQUEST, { amount, refundableIn });
            }}
          />
        </div>
      </Row>

      <Row gridSize="md" classList="space-above-lg space-below-md">
        <small>
          Messaggio pubblicitario con finalità promozionale. Per le condizioni contrattuali ed economiche si rimanda ai
          documenti informativi con particolare riferimento al documento denominato Informazioni Europee di Base sul
          Credito ai Consumatori, a disposizione della Clientela presso gli Uffici Postali. Nell’esempio sopra
          riportato: fido 2.600,00€, TAEG 17,30% inclusivo di TAN fisso 0,00%, Commissione mensile per rimborso rateale
          3,00€ (0,50% dell’importo rateizzato),canone annuo della sola carta principale (assenza di carta aggiuntiva),
          spese di produzione ed invio per ciascun estratto conto mensile (e/c) cartaceo 1€ ed imposta di bollo su e/c
          mensile 2,00€. La rata mensile è di 55,81 €. L’importo totale dovuto a conclusione della durata (12 mesi) del
          piano di rimborso rateale scelto dal cliente è di 667,91€ inclusivo dell’imposta di bollo calcolata per 11
          mesi. Per saldi debitori mensili inferiori a 77,47€ non è prevista l’applicazione dell’imposta di bollo
          mensile. Il contratto ha durata indeterminata ed il cliente ha diritto di recedere in qualsiasi momento e
          senza spese. <br />
          (*) Il taeg e’ stato calcolato ipotizzando la titolarita’ della sola carta principale (assenza di carta
          aggiuntiva). <br />
          Per le condizioni contratturali ed economiche si rimanda ai documenti informativi con particolare riferimento
          al documento denominato Informazioni Europee di Base sul Credito ai Consumatori, a disposizione della
          Clientela presso gli Uffici postali.
        </small>
      </Row>
    </TwoColumnsLayout>
  );
};
export default BankAdvanceSimulationSummary;
