/**
 * Auth middleware
 * Useful for several reasons: maybe we want to use different providers depending on the env (dev, test, provd), or we plan to change library in the future.
 * Thanks to this wrapper we'll only change implementation in this file, leaving everything else intact (basically a kind of simplistic facade)
 */

/**Packages */
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthRoute = ({ component: Component, routeType }) => {
  const isLogged = useSelector((state) => state?.user?.isLoggedIn);

  let defaultRoute = "/login-1";
  const canRouteTo = () => {
    if (routeType === "user") {
      // defaultRoute = '/login-1';
      return isLogged;
    }
    // if guest
    else {
      if (isLogged) {
        defaultRoute = "/user-area";
        return false;
      }
      // defaultRoute = 'currRoute';
      return true;
    }
  };

  return (
    <Route
      render={(props) =>
        canRouteTo() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: defaultRoute, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default AuthRoute;
