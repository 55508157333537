/**Packages */
import React, { useState } from "react";

/**Components */
import Button from "../../Elements/Button";

/**Helpers */
import { default as Validator } from "../../../providers/ValidationProvider";

/**CSS */
import "./style.css";

const Form = ({
  submitEnabled = true,
  submitText = "Submit",
  classList = "",
  onSubmit = () => { },
  noSubmit = false,
  children,
}) => {

  const recursiveMap = (children, fn) => {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children, fn)
        });
      }

      return fn(child);
    });
  }

  const getInputChildren = () => {
    let arr = [];
    recursiveMap(children, (child) => {
      arr.push(child)
    });
    return arr.filter(el => el.props.name && el.props.name.length);
  }

  const submit = (e) => {
    e.preventDefault();
    let validationFactor = 1;

    const actualChildren = getInputChildren();

    actualChildren.forEach((i) => {
      const props = i.props;
      let validationResult = { isValid: true };

      // handle exceptions
      try {
        validationResult = Validator.check(props, props.validIf);
        const inputNode = document.getElementsByName(props.name)[0];
        const floatingLabelNode = inputNode.parentElement;
        const errorMessageNode = document.querySelector(`[name=${props.name}]+.error-message`);
        errorMessageNode.innerText = validationResult.lastErrorMessage;
        !validationResult.isValid
          ? floatingLabelNode.classList.add("with-error")
          : floatingLabelNode.classList.remove("with-error");
      } catch (error) {
        console.log(error);
      }

      // let Form know what happened
      validationFactor *= +validationResult.isValid;
    });
    if (validationFactor === 0) {
      return false;
    }

    console.log("submit");
    onSubmit(e);
  };

  const classes = `cpar-form ${classList}`;

  return (
    <form
      autoComplete="new-password"
      className={classes}
      onSubmit={(e) => {
        submit(e);
      }}
    >
      {children}

      {!noSubmit && <Button isEnabled={submitEnabled} type="submit" round text={submitText} />}
    </form>
  );
};

export default Form;
