/**Packages */
import moment from "moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import ContentBox from "../../../components/Elements/ContentBox";
import TwoColumnsLayout from "../../../components/Layout/TwoColumnsLayout";
import Row from "../../../components/Elements/Row";
import UserCardCompact from "../../../components/Elements/UserCardCompact";
import MovementItemList from "../../../components/Elements/MovementItemList";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { getReduxMonthYearKey } from "../../../helpers/utils";
import { getValueWithValuta } from "../../../helpers/valuta";
import { getResumesProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";
import { default as rp } from "../../../providers/ResourceProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setResumes } from "../../../redux/actions/resumesActions";

/**CSS */
import "./style.css";

const Movements = () => {
  const { t, i18n, ready } = useTranslation();

  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setResumesRedux = (resumes) => {
    dispatch(setResumes(resumes));
  };
  const setErrorMessage = (title, message) => dispatch(setError(title, message));

  const user = useSelector((state) => state.user);
  const movements = useSelector((state) => state.movements);
  const resumes = useSelector((state) => state.resumes);
  const { selectedCard, token } = user;
  const { id = null } = selectedCard;

  const onMonthClick = (e, monthYear, month, year) => {
    NavigationProvider.goTo(
      PAGES.USER_AREA.MOVEMENTS_MONTH,
      {
        monthYear,
        month,
        year,
      },
      "MOVIMENTI"
    );
  };

  const getResumes = async () => {
    activateLoader();
    if (id) {
      const on200Callback = (data) => {
        //setResumesRedux(data?.ec);
        deactivateLoader();
      };

      const on500Callback = (data) => {
        deactivateLoader();
        setErrorMessage("Errore", "Errore durante il download dei movimenti del mese, ritenta più tardi");
      };

      await getResumesProcedure(token, 0, 0, id, on200Callback, on500Callback, setResumesRedux);
    } else {
      setResumesRedux({});
      deactivateLoader();
    }
  };

  useEffect(async () => {
    if (Object.keys(resumes)?.length <= 0) {
      await getResumes();
    }
  }, []);

  const viaggiantiMovements = movements[getReduxMonthYearKey()],
    acquisti = viaggiantiMovements.acquisti,
    movimenti = viaggiantiMovements.movimenti;

  const { dataScadenza = moment(), rataScadenza = 0 } = viaggiantiMovements ? viaggiantiMovements : {};

  return (
    <TwoColumnsLayout>
      <Row gridSize="md" classList="space-below-md only-large-desktop">
        <UserCardCompact classList="text-left"></UserCardCompact>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <PageTitle inner title="movimenti">
          {rp.getResource("movements.detailsSection") && (
            <>
              <p className="text-clear-formatting">
                Di seguito puoi visualizzare i movimenti del mese in corso effettuati sulla tua {rp.getProductName()}.
                L’importo minimo dovuto <span dangerouslySetInnerHTML={{ __html: !!dataScadenza ? `entro il <b>${moment(dataScadenza).format("DD/MM/YYYY")}</b>` : "" }}></span> è di{" "}
                <b>{getValueWithValuta(rataScadenza)}</b> da rimborsare con addebito diretto in conto.
              </p>
              <br />
              <p className="text-clear-formatting">
                <b>Attenzione: i dati riportati tengono conto dei soli movimenti pervenuti e registrati.</b>
              </p>
            </>
          )}
        </PageTitle>
      </Row>

      {rp.getResource("movements.detailsSection") ? (
        <>
          {/**
              <Row gridSize="md" classList="space-below-md">
                <ContentBox withTitle="acquisti del mese" compact>
                  <MovementItemList expandable elements={acquisti} />
                </ContentBox>
              </Row>
            */}

          <Row gridSize="md" classList="space-below-md">
            <ContentBox withTitle="movimenti del mese" compact>
              <MovementItemList elements={[...acquisti, ...movimenti]} />
            </ContentBox>
          </Row>
          {/* <MovementsFilterableList elements={resumes} onClick={onMonthClick} /> */}
        </>
      ) : (
        <span></span>
        // <MovementsFilterableListCompact elements={movimenti} />
      )}
    </TwoColumnsLayout>
  );
};
export default Movements;
