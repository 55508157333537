import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

/**Helpers */
import { getImage } from '../../../helpers/image';

import PageTitle from '../../../components/Elements/PageTitle';
import TwoColumnsLayout from '../../../components/Layout/TwoColumnsLayout';
import Row from '../../../components/Elements/Row';
import OnlineSecurityComponent from '../../../components/Elements/OnlineSecurityComponent';

/**CSS */
import './style.css';

const UserOnlineSecurity = () => {
    const { t, i18n, ready } = useTranslation();
    return (
        <>
            <TwoColumnsLayout id="user-online-security">
                <OnlineSecurityComponent />
            </TwoColumnsLayout>
        </>
    );
};
export default UserOnlineSecurity;
