/**
 *
 * @param {*} data - data
 * @returns Success response formatted
 */
export const createSuccessResponse = (data) => {
  return {
    success: true,
    error: null,
    data,
  };
};
/**
 *
 * @param {*} error - error message
 * @param {*} apiUrl - endpoint that generated the error
 * @returns Error response formatted
 */
export const createErrorResponse = (error, apiUrl) => {
  return {
    success: false,
    error: error ? error : `Errore nella ${apiUrl} call`,
    data: null,
  };
};
