/**Packages */
import { useState } from "react";

/**Components */
import PinPad from "../PinPad";

/**CSS */
import "./style.css";

const Keypad = ({
  pinInputChangeHandler = (finalString) => {
    console.log(finalString);
  },
}) => {
  let [finalOTPString, setFinalOTPString] = useState("");
  const keyPadClickHandler = (e) => {
    if (finalOTPString.length >= 5) {
      return;
    }
    setFinalOTPString((finalOTPString += e));
    pinInputChangeHandler(finalOTPString);
  };

  const keyPadBackkHandler = () => {
    if (finalOTPString.length > 0) {
      setFinalOTPString(finalOTPString.slice(0, -1));
      pinInputChangeHandler(finalOTPString.slice(0, -1));
    }
  };

  return (
    <div className="row text-center">
      <div className="keypad mx-auto">
        <div className="keypad-container">
          <input
            id="pin0"
            type="password"
            maxLength="1"
            value={finalOTPString.length > 0 ? finalOTPString[0] : ""}
            onChange={() => {}}
          ></input>
          <input
            id="pin1"
            type="password"
            maxLength="1"
            value={finalOTPString.length > 1 ? finalOTPString[1] : ""}
            onChange={() => {}}
          ></input>
          <input
            id="pin2"
            type="password"
            maxLength="1"
            value={finalOTPString.length > 2 ? finalOTPString[2] : ""}
            onChange={() => {}}
          ></input>
          <input
            id="pin3"
            type="password"
            maxLength="1"
            value={finalOTPString.length > 3 ? finalOTPString[3] : ""}
            onChange={() => {}}
          ></input>
          <input
            id="pin4"
            type="password"
            maxLength="1"
            value={finalOTPString.length > 4 ? finalOTPString[4] : ""}
            onChange={() => {}}
          ></input>
        </div>
        <PinPad keyPadClickHandler={keyPadClickHandler} keyPadBackkHandler={keyPadBackkHandler} />
      </div>
    </div>
  );
};

export default Keypad;
