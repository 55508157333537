/**Helpers */
import { getIdxProductFromArray, getProductFromArray } from "../../helpers/user";
import { PRODOTTO_TIPO } from "../../helpers/constants";
import { setToken } from "../../helpers/token";
import { default as rp } from "../../providers/ResourceProvider";

/**Redux */
import {
  USER_LOGIN_FIRST_STEP,
  USER_SET_SELECTED_CARD_EC_METHOD,
  USER_SET_SMS_ACTIVATION,
  USER_LOGGED_IN,
  USER_INFO_RECEIVED,
  USER_LOGGED_OUT,
  USER_UPDATE_SELECTED_PRODUCT,
  USER_CHANGE_SELECTED_PRODUCT,
} from "../actionTypes/userActionTypes";
import { TOKEN_RECEIVED, TOKEN_EXPIRED, TOKEN_OTP_RECEIVED, TOKEN_OTP_EXPIRED } from "../actionTypes/tokenActionTypes";

const initialState = {
  name: "",
  surname: "",
  cf: "",
  lastAccess: null,
  tokenOTP: null,
  email: "",
  cards: [],
  selectedCard: null,
  isLoggedIn: false,
  token: null,
};

export const userReducer = (state = initialState, payload) => {
  switch (payload.type) {
    /**DEPRECATED REDUX USER ACTION */
    /*
    case USER_INFO_RECEIVED: {
      const data = payload?.data;

      return {
        ...state,
        cards: data.cards ? data.cards : [],
        selectedCard: data.cards && data.cards.length > 0 ? data.cards[0] : [],
        isLoggedIn: true,
      };
    }
    */
    case USER_LOGGED_IN: {
      const data = payload?.data;
      const utente = data;
      const cards = utente.cards ? utente.cards : [];
      const selectedCard = cards?.length > 0 ? cards[0] : {};

      if (selectedCard) {
        const productType = selectedCard?.carta;
        const type = Object.keys(PRODOTTO_TIPO).find((itm) => {
          const baseType = PRODOTTO_TIPO[itm];
          return baseType.id === productType;
        });
        rp.setProductType(PRODOTTO_TIPO[type]?.name);
      }

      setToken(utente?.token);

      return {
        ...state,
        ...data,
        name: utente.nome || "",
        surname: utente.cognome || "",
        email: utente.email || "",
        cf: utente.cf || "",
        lastAccess: utente.lastLoginDate || "",
        isLoggedIn: true,
        token: utente.token || "",
        cards,
        selectedCard,
        tokenOTP: null,
      };
    }
    case USER_SET_SELECTED_CARD_EC_METHOD: {
      const data = payload?.data;
      const { id, ecMethod, fullEcMethod, email } = data;

      if (id && state && state.selectedCard) {
        const idx = getIdxProductFromArray(state?.cards, id);

        if (idx >= 0) {
          state.cards[idx].modInvioEC = ecMethod;
          state.cards[idx].modInvioECDESC = fullEcMethod;
          state.selectedCard.modInvioEC = ecMethod;
          state.selectedCard.modInvioECDESC = fullEcMethod;

          if (email) {
            state.email = email;
          }
        }
      }

      return {
        ...state,
      };
    }
    case USER_SET_SMS_ACTIVATION: {
      const data = payload?.data;
      const { id, minAmount, alertNuovaAutorizzazione, alertCalcoloNuovoEC, phoneNumber } = data;

      if (id && state && state.selectedCard) {
        const idx = getIdxProductFromArray(state?.cards, id);

        if (idx >= 0) {
          state.cards[idx].crSidImpSogliaAut = minAmount < 0 ? 0 : alertNuovaAutorizzazione ? minAmount : 0;
          state.cards[idx].crSidServizioAggEC = alertCalcoloNuovoEC ? "S" : "N";
          state.cards[idx].crSidAvvisoAut = alertNuovaAutorizzazione ? "S" : "N";
          state.cards[idx].crSidCellulare = phoneNumber;
          state.selectedCard.crSidImpSogliaAut = minAmount < 0 ? 0 : alertNuovaAutorizzazione ? minAmount : 0;
          state.selectedCard.crSidServizioAggEC = alertCalcoloNuovoEC ? "S" : "N";
          state.selectedCard.crSidAvvisoAut = alertNuovaAutorizzazione ? "S" : "N";
          state.selectedCard.crSidCellulare = phoneNumber;
        }
      }

      return {
        ...state,
      };
    }
    case USER_UPDATE_SELECTED_PRODUCT: {
      const { id, newData } = payload?.data;

      if (id) {
        const selectedProductIdx = getIdxProductFromArray(state?.cards, id);

        if (selectedProductIdx >= 0) {
          state.selectedCard = Object.assign({}, newData);
          state.cards[selectedProductIdx] = Object.assign({}, newData);
          return {
            ...state,
          };
        }
      } else {
        return {
          ...state,
        };
      }

      break;
    }
    case USER_CHANGE_SELECTED_PRODUCT: {
      const id = payload?.data?.id;

      if (id) {
        const selectedProduct = getProductFromArray(state?.cards, id);

        if (selectedProduct) {
          const productType = selectedProduct?.carta;
          const type = Object.keys(PRODOTTO_TIPO).find((itm) => {
            const baseType = PRODOTTO_TIPO[itm];
            return baseType.id === productType;
          });
          rp.setProductType(PRODOTTO_TIPO[type]?.name);
          return {
            ...state,
            selectedCard: selectedProduct,
          };
        }
      } else {
        return {
          ...state,
        };
      }

      break;
    }
    case USER_LOGGED_OUT: {
      const baseState = Object.assign({}, initialState);
      return baseState;
    }
    case TOKEN_RECEIVED: {
      return {
        ...state,
        isLoggedIn: true,
        token: payload.data,
        tokenOTP: null,
      };
    }
    /**
     * TOKEN_OTP_RECEIVED = USER_LOGIN_FIRST_STEP
     */
    case TOKEN_OTP_RECEIVED: {
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        tokenOTP: payload.data,
      };
    }
    case TOKEN_OTP_EXPIRED: {
      return {
        ...state,
        isLoggedIn: false,
        tokenOTP: null,
      };
    }
    case TOKEN_EXPIRED: {
      return {
        ...state,
        isLoggedIn: false,
        token: null,
      };
    }
    default: {
      return state;
    }
  }
};
