/**CSS */
import "./style.css";

const Row = ({ gridSize = "md", classList = "", children }) => {
  const classes = `cpar-relative-row ${gridSize} ${classList}`;

  return <div className={classes}>{children}</div>;
};

export default Row;
