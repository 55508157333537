/**Action Types*/
import {
  TOKEN_RECEIVED,
  TOKEN_EXPIRED,
  TOKEN_OTP_RECEIVED,
  TOKEN_OTP_EXPIRED,
} from "./../actionTypes/tokenActionTypes";

/**
 *
 * @param {*} token - value of the token
 * @returns The action to save token in state
 */
export const setToken = (token = null) => {
  return {
    type: TOKEN_RECEIVED,
    data: token,
  };
};
/**
 *
 * @returns The action to delete token from the state
 */
export const expireToken = () => {
  return {
    type: TOKEN_EXPIRED,
  };
};
/**
 *
 * @param {*} tokenOTP - value of the token OTP
 * @returns The action to save tokenOTP in the state
 */
export const setTokenOTP = (tokenOTP = null) => {
  return {
    type: TOKEN_OTP_RECEIVED,
    data: tokenOTP,
  };
};
/**
 *
 * @returns The action to expire tokenOTP from the state
 */
export const expireTokenOTP = () => {
  return {
    type: TOKEN_OTP_EXPIRED,
  };
};
