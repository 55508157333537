/**Packages */
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import Button from "../Button";

/**Helpers */
import { getImage } from "../../../helpers/image";
import { getValueWithValuta } from "../../../helpers/valuta";
import { openWindowWithPost, getPdfUrl } from "../../../helpers/request";
import { validateGenericString } from "../../../helpers/validators";

/**Redux */
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

const MovementExpandedItem = ({ element = {}, onMonthClick = () => { } }) => {
  const dispatch = useDispatch();
  const { t, i18n, ready } = useTranslation();

  const setErrorState = (title, message) => dispatch(setError(title, message));

  const {
    periodo = moment().format("MM/YYYY"),
    acquisti = 0,
    pagamenti = 0,
    insoluti = 0,
    intCom = 0,
    saldo = 0,
    flagStampa = null,
    ecLink = null,
  } = element;

  const onDownloadIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (validateGenericString(flagStampa, 1) && validateGenericString(ecLink, 1)) {
      openWindowWithPost(getPdfUrl(), {
        ecLink,
      });
    } else {
      setErrorState("PDF non disponibile", "PDF non disponibile riprova più tardi");
    }
  };

  const checkIfZero = (value) => {
    return value === 0 || value === "0";
  };

  const checkIfPdfEnabled = () => {
    return validateGenericString(flagStampa, 1) && validateGenericString(ecLink, 1);
  };

  const dateSplitted = periodo.split("/");
  const month = dateSplitted[0];
  const year = dateSplitted[1];

  return (
    <div className="movement-expanded-item" onClick={(e) => onMonthClick(e, periodo, month, year)}>
      <div className="movement-expanded-item-container">
        <div className="first-row-column">
          <div className="flex-equal">
            <h6>periodo</h6>
          </div>
          <div className="flex-equal">
            <h6>acquisti</h6>
          </div>
          <div className="flex-equal">
            <h6>pagamenti</h6>
          </div>
          <div className="flex-equal">
            <h6>insoluti</h6>
          </div>
          <div className="flex-equal">
            <h6>int./com.</h6>
          </div>
          <div className="flex-equal">
            <h6>saldo</h6>
          </div>
          {checkIfPdfEnabled() ? (
            <div className="flex-equal">
              <Button round text="scarica pdf" onClick={onDownloadIconClick} />
            </div>
          ) : null}
        </div>
        <div className="second-row-column">
          <div className="flex-equal">
            <div className="movement-detail detail-date">{periodo}</div>
          </div>
          <div className="flex-equal">
            <div className="movement-detail">{getValueWithValuta(acquisti)}</div>
          </div>
          <div className="flex-equal">
            <div className="movement-detail">{getValueWithValuta(pagamenti)}</div>
          </div>
          <div className="flex-equal">
            <div className="movement-detail">{checkIfZero(insoluti) ? "-" : insoluti}</div>
          </div>
          <div className="flex-equal">
            <div className="movement-detail">{getValueWithValuta(intCom)}</div>
          </div>
          <div className="flex-equal">
            <div className="movement-detail detail-balance">{getValueWithValuta(saldo)}</div>
          </div>
          <div className="flex-equal text-center">
            <button type="button" className={`img-button download-pdf-icon ${!checkIfPdfEnabled() && 'invisible'}`} onClick={onDownloadIconClick}>
              <img className="img-responsive" alt={"Logo"} src={getImage("images/pdf.svg")} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovementExpandedItem;
