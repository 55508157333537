/**Packages */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

/**Helpers */
import { SITE_MESSAGE_STATUS } from "../../../helpers/constants";

/**CSS */
import "./style.css";

const getCorrectedClassName = (status) => {
  return status === SITE_MESSAGE_STATUS.ERROR
    ? "error"
    : status === SITE_MESSAGE_STATUS.WARNING
    ? "warning"
    : "success";
};

const Message = ({
  status = SITE_MESSAGE_STATUS.ERROR,
  title = "",
  message = "",
  visible = false,
  nSeconds = 10,
  expireAction = () => {},
}) => {
  const { t, i18n, ready } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(expireAction());
    }, 1000 * nSeconds);
  }, []);

  return (
    <div className={getCorrectedClassName(status)} id="site-error-container">
      <h1 id="site-error-title">{title}</h1>
      <div id="site-error-body">{message}</div>

      <div
        id="close-site-error"
        onClick={() => {
          dispatch(expireAction());
        }}
      >
        X
      </div>
    </div>
  );
};

export default Message;
