import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import TextLink from "../../../components/Elements/TextLink";
import Timer from "../../../components/Elements/Timer";
import Form from "../../../components/Form/Form";
import Button from "../../../components/Elements/Button";
import Keypad from "../../../components/Elements/Keypad";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";

/**Helpers */
import { INDEX_URL, PAGES } from "../../../helpers/constants";
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import history from "../../../helpers/history";

/**CSS */
import "./style.css";
import NavigationProvider from "../../../providers/NavigationProvider";

const AccountAccessOtp = () => {
  const { t, i18n, ready } = useTranslation();

  const dispatch = useDispatch();

  /**
   * Handles Login Form submission
   *
   * @function onLoginFormSubmit
   * @param {SyntheticEvent} submitEvent - it contains the event target (form element) we can use to access its fields' values by the name attribute
   * @return {Void}
   */
  const goToAccountPage = () => {
    history.push(INDEX_URL + PAGES.SIGNUP);
  };

  const requestNewOtpHandler = () => {
    console.log("requestNewOtpHandler");
  };

  const onSubmit = (submitEvent) => {
    NavigationProvider.goTo(PAGES.USER_AREA.ACCOUNT);
  };

  return (
    <>
      <FullWidthLayout>
        <PageTitle small title="Pagina riservata - OTP">
          <p>
            <b>È stato inviato un messaggio sul cellulare con il codice OTP, inserisci il codice OTP e conferma</b>
          </p>
          <br />
          <p>
            Inserisci il codice OTP che è stato inviato al numero di cellulare *** *****261 e conferma l’operazione. Se
            il numero di telefono non è più disponibile, chiama il numero di assistenza 0248244412
          </p>
        </PageTitle>

        <Row gridSize="ms" classList="space-below-md">
          <ContentBox fullWidthOnMobile classList="text-center space-below-lg">
            <Row gridSize="xxs" classList="space-below-md">
              <h3>codice otp</h3>
              <Form onSubmit={onSubmit} submitText="conferma">
                <Keypad></Keypad>
                <Timer></Timer>

                <TextLink text="Richiedi nuovo OTP" onClick={requestNewOtpHandler} />
              </Form>
            </Row>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};

export default AccountAccessOtp;
