/**Packages */
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import Timer from "../../../components/Elements/Timer";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { setUserData } from "../../../helpers/userData";
import { loginProcedureStep2 } from "../../../helpers/handler";
import { getUsersWithId, getArrayOfProductsWithIds } from "../../../helpers/user";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { USER_LOGGED_IN, USER_INFO_RECEIVED, USER_LOGGED_OUT } from "../../../redux/actionTypes/userActionTypes";
import { SET_USERS } from "../../../redux/actionTypes/usersActionsTypes";
import { SET_PRODUCTS } from "../../../redux/actionTypes/productsActionsTypes";
import { setError } from "../../../redux/actions/errorActions";

/**CSS */
import "./style.css";

/**
 *
 * User to use to login
 *
 *
 * BZZMRC74B10D612N
 *
 * CRRGNN77E04F799H
 *
 *
 *
 *
 *
 * Password Jolly: MP3Hyb39
 * OTP Jolly: MP3Hyb39
 */

const LoginStep2 = () => {
  const { t, i18n, ready } = useTranslation();
  const [userPin, setUserPin] = useState("");

  //From state
  const tokenOTP = useSelector((state) => state?.user?.tokenOTP);

  if (!tokenOTP || tokenOTP === "") {
    NavigationProvider.goToLoginPage();
  }

  //Dispatcher
  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setUsers = (data) => dispatch({ type: SET_USERS, data });
  const setProducts = (data) => dispatch({ type: SET_PRODUCTS, data });
  const setBaseUserData = (data) => dispatch({ type: USER_LOGGED_IN, data });
  const setUserInfoData = (data) => dispatch({ type: USER_INFO_RECEIVED, data });
  const userLoggedOut = () => dispatch({ type: USER_LOGGED_OUT });
  const setErrorState = (title, message) => dispatch(setError(title, message));

  const onClickButtonHandler = async () => {
    // if (userPin === '') {
    //     setErrorState('Non hai inserito il pin', 'Inserisci il pin e riprova');
    //     return;
    // }

    activateLoader();

    const onSuccessCallback = async (result) => {
      if (result?.utenti?.length > 0) {
        const utenti = result?.utenti;
        const utentiMapped = getUsersWithId(utenti);
        const products = getArrayOfProductsWithIds(utentiMapped);

        setUsers(utentiMapped);
        setProducts(products);
        setBaseUserData(utentiMapped[0]);

        setUserData(result?.utenti);

        NavigationProvider.goTo(PAGES.USER_AREA.INDEX);
        deactivateLoader();
      } else {
        deactivateLoader();
        setErrorState("Errore durante la retrieve dei token di autenticazione", "Qualcosa è andato storto, ritenta");
      }
    };

    const onErrorCallback = (err) => {
      deactivateLoader();
      setErrorState("Errore durante la fase di verifica dell'otp", "Qualcosa è andato storto, ritenta");
    };

    //await loginProcedureStep2(tokenOTP, userPin, onSuccessCallback, onErrorCallback, setBaseUserData);
    await loginProcedureStep2(tokenOTP, userPin, onSuccessCallback, onErrorCallback, null);
  };

  const userPinChangeHandler = (event, value) => {
    setUserPin(value);
  };

  const onTimerFinish = (event) => {
    userLoggedOut();
    NavigationProvider.goToLoginPage();
  };

  return (
    <>
      <FullWidthLayout id="login-step-2">
        <PageTitle
          title="codice otp"
          subtitle="Per garantire una maggiore sicurezza della tua area riservata, ti chiediamo di inserire il codice OTP (One Time Password) che ti abbiamo inviato via SMS al numero da te registrato in Area Riservata. Non hai ricevuto l’sms? Chiama BancoPosta Risponde al numero 06.4526.3160, dal Lunedì al Sabato dalle 8.00 alle 20.00, per aggiornare il tuo numero di cellulare."
        />

        <Row gridSize="xs" classList="space-below-md">
          <ContentBox fullWidthOnMobile classList="text-center">
            <Form submitEnabled="true" submitText={t("login")} onSubmit={onClickButtonHandler}>
              <FloatingInput
                name="userPin"
                placeholder="Codice OTP"
                validIf={["notEmpty"]}
                value={userPin}
                onChange={userPinChangeHandler}
              />
              <Timer onTimerFinish={onTimerFinish} setErrorState={setErrorState} />
            </Form>
          </ContentBox>
        </Row>
      </FullWidthLayout>

      {/* this node will always be rendered at the bottom of the page, no matter the content length */}
      <div className="stick-to-bottom">
        <Row gridSize="md" classList="text-center">
          <p>
            Per accedere a questo portale non sono valide le credenziali del portale Poste.it ed occorre effettuare una
            specifica registrazione. La registrazione è ammessa esclusivamente per i titolari di Carta BancoPosta Più,
            Fido Affari e/o Fido POS
          </p>
        </Row>
      </div>
    </>
  );
};

export default LoginStep2;
