/**Packages */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Components */
import TextLink from "../../Elements/TextLink";

/**CSS */
import "./style.css";

const SideMenu = ({ open = "", onMenuItemClicked = () => { }, onBackdropClick = () => { } }) => {
  const { t, i18n, ready } = useTranslation();
  const classes = `d-md-none ${open && "open"}`;

  useEffect(() => {
    setOpenClassForBackdrop();
  }, [open]);

  const setOpenClassForBackdrop = () => {
    const backdropNode = document.getElementById("backdrop");
    if (open) {
      backdropNode.style.display = "block";
      setTimeout(() => {
        backdropNode.classList.add("open");
      }, 30);
    } else {
      backdropNode.classList.remove("open");
      setTimeout(() => {
        backdropNode.style.display = "none";
      }, 800);
    }
  };

  // @todo refactor
  const onMobileMenuItemClick = (nodeId, page) => {
    resetNavigationLink();

    setTimeout(() => {
      const nodes = document.getElementsByClassName(nodeId);
      for (let idx = 0; idx < nodes.length; idx++) {
        nodes[idx].classList.add("strong");
      }
    }, 100);
    NavigationProvider.goTo(page);
    onMenuItemClicked();
  };

  const resetNavigationLink = () => {
    const linkAreas = [
      "#header-navigation .text-link.strong",
      "#user-side-menu .text-link.strong",
      "#header-navigation-mobile .text-link.strong",
      "#header-user-actions .text-link.strong",
    ];

    linkAreas.forEach((l) => {
      let allNodes = document.querySelectorAll(l);
      allNodes.forEach((n) => {
        n.classList.remove("strong");
      });
    });
  };

  return (
    <>
      <nav id="header-navigation-mobile" className={classes}>
        <ul className="unstyled">
          <li>
            <TextLink
              classList="top-menu-movements"
              onClick={() => {
                onMobileMenuItemClick("top-menu-movements", PAGES.USER_AREA.MOVEMENTS);
              }}
              withCaret
              text={t("movimenti")}
            />
          </li>
          <li>
            <TextLink
              classList="top-menu-account-balance"
              onClick={() => {
                onMobileMenuItemClick("top-menu-account-balance", PAGES.USER_AREA.ACCOUNT_BALANCE);
              }}
              withCaret
              text={t("estratti conto")}
            />
          </li>
          <li>
            <TextLink
              classList="top-menu-bank-transfer"
              onClick={() => {
                onMobileMenuItemClick("top-menu-bank-transfer", PAGES.USER_AREA.BANK_TRANSFER);
              }}
              withCaret
              text={rp.getResource("header.bankAdvanceTitle")}
            />
          </li>
          <li>
            <TextLink
              classList="side-menu-change-standing-eq"
              onClick={() => {
                onMobileMenuItemClick("side-menu-change-standing-eq", PAGES.USER_AREA.CHANGE_STANDING_EC);
              }}
              withCaret
              text={t("modifica invio e/c")}
            />
          </li>
          <li>
            <TextLink
              classList="side-menu-sms"
              onClick={() => {
                onMobileMenuItemClick("side-menu-sms", PAGES.USER_AREA.SMS_ACTIVATION);
              }}
              withCaret
              text={t("attivazione sms")}
            />
          </li>
          {/* <li>
            <TextLink
              onClick={() => {
                NavigationProvider.openExternalLink(rp.getResource("externalLinks.transparency"));
                onMenuItemClicked();
              }}
              withCaret
              text={t("trasparenza")}
            />
          </li> */}
          <li>
            <TextLink
              classList="side-menu-support"
              onClick={() => {
                onMobileMenuItemClick("side-menu-support", PAGES.USER_AREA.SUPPORT);
              }}
              noFormatting
              withCaret
              text={t("Servizio clienti")}
            />
          </li>
          <li>
            <TextLink
              classList="side-menu-numbers"
              onClick={() => {
                onMobileMenuItemClick("side-menu-numbers", PAGES.USER_AREA.USER_USEFUL_NUMBERS);
              }}
              noFormatting
              withCaret
              text={t("Assistenza")}
            />
          </li>
        </ul>
      </nav>
      <div onClick={onBackdropClick} id="backdrop"></div>
    </>
  );
};
export default SideMenu;
