/**Packages */
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import Button from "../../../components/Elements/Button";

/**Helpers */
import { PAGES } from "../../../helpers/constants";
import { recoveryPasswordStep2Procedure } from "../../../helpers/handler";
import { default as rp } from "../../../providers/ResourceProvider";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setSuccess } from "../../../redux/actions/successActions";

/**CSS */
import "./style.css";

const ForgotPassword2 = () => {
  const { t, i18n, ready } = useTranslation();
  const location = useLocation();

  let username = "";
  let guid = "";

  if (location?.search?.length) {
    const getParams = location.search.substr(1).split("&");
    username = getParams[1].split("=")[1];
    guid = getParams[0].split("=")[1];
  } else {
    if (!NavigationProvider.getStateFromHistory()) NavigationProvider.goBack();

    // const { username, guid } = NavigationProvider.getStateFromHistory();
    const stateFromHistory = NavigationProvider.getStateFromHistory();
    username = stateFromHistory?.username;
    guid = stateFromHistory?.guid;
  }

  /*
  console.log(username);
  console.log(guid);
  */

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorState = (title, message) => dispatch(setError(title, message));
  const setSuccessState = (title, message) =>
    dispatch(setSuccess(title, message));

  const [userNewPassword, setUserNewPassword] = useState("");
  const [userConfirmNewPassword, setUserConfirmNewPassword] = useState("");
  const [formValidated, setFormValidated] = useState(false);

  const validateInput = () => {
    let validated = true;
    if (userNewPassword === "" || userNewPassword !== userConfirmNewPassword) {
      validated = false;
    }
    setFormValidated(validated);
  };

  useEffect(() => {
    validateInput();
  });

  const onEditPasswordClickHandler = async () => {
    if (formValidated) {
      activateLoader();

      const onSuccessCallback = async (result) => {
        deactivateLoader();
        setSuccessState(
          "Operazione eseguita correttamente",
          "Nuova password impostata con successo"
        );
        NavigationProvider.goToLoginPage();
      };

      const onErrorCallback = (err = "Qualcosa è andato storto") => {
        deactivateLoader();
        console.log(err);
        setErrorState("Errore", typeof err == "string" ? err : "");
      };

      await recoveryPasswordStep2Procedure(
        guid,
        username,
        userNewPassword,
        onSuccessCallback,
        onErrorCallback
      );
    }
  };

  const test = true;
  return (
    <>
      <FullWidthLayout id="edit-password-page">
        <PageTitle small title="Modifica password di accesso">
          <p>
            La password di accesso è la parola chiave necessaria per poter
            accedere al servizio. Al fine di mantenerne inalterata la sicurezza,
            la password di accesso deve rimanere riservata, personale e non deve
            essere divulgata a persone terze
          </p>
          {/* {test && (
            <p className="text-center space-above-lg">
              <b>Autenticazione</b>
              <br />
              Alla conferma riceverai un messaggio sul cellulare con il codice OTP da inserire nella maschera che ti
              comparirà.
            </p>
          )} */}
        </PageTitle>

        <Row gridSize="xs" classList="space-below-md">
          <ContentBox
            compact
            withTitle="modifica password"
            fullWidthOnMobile
            classList="text-center"
          >
            <Row gridSize="xxs" classList="">
              <Form
                noSubmit
                submitText="Conferma"
                onSubmit={onEditPasswordClickHandler}
                classList=""
              >
                <FloatingInput
                  type="password"
                  name="newPassword"
                  placeholder="Nuova password"
                  validIf={["notEmpty"]}
                  value={userNewPassword}
                  onChange={(ev) => {
                    setUserNewPassword(ev.target.value);
                  }}
                />
                <FloatingInput
                  type="password"
                  name="userPassword"
                  placeholder="Conferma nuova password"
                  validIf={[`equalTo--${userNewPassword}`]}
                  value={userConfirmNewPassword}
                  onChange={(ev) => {
                    setUserConfirmNewPassword(ev.target.value);
                  }}
                />

                <Row classList="space-above-sm">
                  <Button type="submit" round text="Conferma" />
                  <Button
                    round
                    outline
                    text="Annulla"
                    onClick={NavigationProvider.goBack}
                  />
                </Row>
              </Form>
            </Row>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};

export default ForgotPassword2;
