/**Packages */
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import Row from "../../../components/Elements/Row";
import FlexRow from "../../../components/Elements/FlexRow";
import ContentBox from "../../../components/Elements/ContentBox";
//import FullWidthLayout from "../../../components/Layout/FullWidthLayout";

/**Helpers */
import { getImage } from "../../../helpers/image";

/**CSS */
import "./style.css";

const OnlineSecurityComponent = () => {
  const { t, i18n, ready } = useTranslation();

  const onlineSecurityRows = [
    [
      {
        icon: "l1",
        number: "1",
        body: "Prima di acquistare, verifica che il tuo pc, tablet o smartphone sia adeguatamente protetto con un antivirus e un firewall aggiornato.",
      },
      {
        icon: "l2",
        number: "2",
        body: "Quando stai acquistando online, verifica che anche la connessione sia protetta, per farlo controlla che la pagina web in cui vengono richiesti i dati della carta di credito inizi per “https”. Questa sigla indica che il sito in quel momento è criptato e, di conseguenza, lo saranno anche i dati che andrai ad inserire.",
      },
      {
        icon: "l3",
        number: "3",
        body: "Non comunicare mai a terzi, specialmente via mail o telefono, i dati della tua carta, le credenziali di accesso all’Area Clienti o il PIN. Una delle strategie più diffuse per capire queste informazioni è il phishing: il cliente riceve una mail che lo invita ad inserire i dati della propria casa o le credenziali dell’Area Clienti. Non cadere in questa trappola!",
      },
      {
        icon: "l4",
        number: "4",
        body: "Attiva gratuitamente il servizio di SMS Alert, per ricevere un avviso tempestivo ogni volta che la carta viene utilizzata, potrai così mantenere tutte le transazioni sotto controllo.",
      },
      {
        icon: "l5",
        number: "5",
        body: "Evita di immettere i dati della carta di credito in aree con wi-fi libero, come bar, ristoranti o hall di alberghi, dove più facilmente potrebbero essere captati ed utilizzati in modo fraudolento.",
      },
    ],
    [
      {
        icon: "r1",
        number: "1",
        body: "Non lasciare mai la carta di credito in mano ad altre persone (ad esempio al ristorante, dal benzinaio) e assicurati di non perderla mai di vista mentre viene effettuato il pagamento.",
      },
      {
        icon: "r2",
        number: "2",
        body: "Quando prelevi agli sportelli ATM o paghi nei negozi, fai attenzione a non essere osservato quando digiti il PIN e copri la tastiera con la mano in modo che i numeri non possano essere visti.",
      },
      {
        icon: "r3",
        number: "3",
        body: "Tieni sempre a portata di mano il numero verde da contattare per bloccare la carta in caso di furto o smarrimento, per esempio memorizzandolo nella rubrica del cellulare.",
      },
    ],
  ];

  return (
    <>
      <PageTitle title="5 semplici regole per i tuoi acquisti sicuri online" />

      <Row gridSize="md" classList="text-center">
        <h5 className="text-center">
          <b>
            Ecco alcuni piccoli punti d’attenzione da tenere sempre a mente per acquistare online in sicurezza con la
            tua carta di credito:
          </b>
        </h5>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox>
          {onlineSecurityRows[0].map((row) => (
            <FlexRow gridSize="md" classList="cols-equal space-below-xs online-security-rule-container">
              <div className="flex-equal icon-container">
                <img src={getImage(`images/onlinesecurity/${row.icon}.svg`)} alt="" />
              </div>
              <strong className="flex-equal number-container">{row.number}</strong>
              <div className="flex-equal body-container">{row.body}</div>
            </FlexRow>
          ))}
        </ContentBox>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <h5 className="text-center">
          <b>Per gli acquisti di tutti i giorni:</b>
        </h5>
      </Row>

      <Row gridSize="md" classList="space-below-md">
        <ContentBox>
          {onlineSecurityRows[1].map((row) => (
            <FlexRow gridSize="md" classList="cols-equal space-below-xs online-security-rule-container">
              <div className="flex-equal icon-container">
                <img src={getImage(`images/onlinesecurity/${row.icon}.svg`)} alt="" />
              </div>
              <strong className="flex-equal number-container">{row.number}</strong>
              <div className="flex-equal body-container">{row.body}</div>
            </FlexRow>
          ))}
        </ContentBox>
      </Row>
    </>
  );
};
export default OnlineSecurityComponent;
