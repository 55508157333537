/**Packages */
import { useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Switch, Redirect, Router } from "react-router-dom";
import Cookies from "universal-cookie";

/**Helpers */
import { INDEX_URL, PAGES } from "../../../helpers/constants";
import { getUserData } from "../../../helpers/userData";
import { getToken } from "../../../helpers/token";
import history from "../../../helpers/history";
import NavigationProvider from "../../../providers/NavigationProvider";
import { getUsersWithId, getArrayOfProductsWithIds } from "../../../helpers/user";

/**Components */
import LoginStep1 from "../../../pages/guest/LoginStep1";
import LoginStep2 from "../../../pages/guest/LoginStep2";
import ForgotPassword from "../../../pages/guest/ForgotPassword";
import ForgotPassword2 from "../../../pages/guest/ForgotPassword2";
import ForgotPasswordActivate from "../../../pages/guest/ForgotPasswordActivate";
import ForgotPasswordConfirmation from "../../../pages/guest/ForgotPasswordConfirmation";
import Registration from "../../../pages/guest/Registration";
import RegistrationConfirmation from "../../../pages/guest/RegistrationConfirmation";
import OnlineSecurity from "../../../pages/guest/OnlineSecurity";
import UsefulNumbers from "../../../pages/guest/UsefulNumbers";
import AccountAccess from "../../../pages/user/AccountAccess";
import AccountAccessOtp from "../../../pages/user/AccountAccessOtp";
import Account from "../../../pages/user/Account";
import AccountUpdateConfirmOtp from "../../../pages/user/AccountUpdateConfirmOtp";
import EditPassword from "../../../pages/user/EditPassword";
import EditPasswordConfirmOtp from "../../../pages/user/EditPasswordConfirmOtp";
import EditPasswordConfirmation from "../../../pages/user/EditPasswordConfirmation";
import Inbox from "../../../pages/user/Inbox";
import Dashboard from "../../../pages/user/Dashboard";
import Movements from "../../../pages/user/Movements";
import MovementDetail from "../../../pages/user/MovementDetail";
import AccountBalance from "../../../pages/user/AccountBalance";
import BankAdvance from "../../../pages/user/BankAdvance";
import BankAdvanceSimulation from "../../../pages/user/BankAdvanceSimulation";
import BankAdvanceSimulationSummary from "../../../pages/user/BankAdvanceSimulationSummary";
import BankAdvanceRequest from "../../../pages/user/BankAdvanceRequest";
import ChangeStandingEc from "../../../pages/user/ChangeStandingEc";
import UserUsefulNumbers from "../../../pages/user/UserUsefulNumbers";
import UserOnlineSecurity from "../../../pages/user/UserOnlineSecurity";
import SmsActivation from "../../../pages/user/SmsActivation";
import OTPForm from "../../../pages/user/OTPForm";
import Support from "../../../pages/user/Support";
import AuthRoute from "../../AuthRoute";
import ScrollToTop from "../../Layout/ScrollToTop";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../../redux/actionTypes/loaderActionsTypes";
import { SET_USERS } from "../../../redux/actionTypes/usersActionsTypes";
import { SET_PRODUCTS } from "../../../redux/actionTypes/productsActionsTypes";
import { USER_LOGGED_IN } from "../../../redux/actionTypes/userActionTypes";

const Main = (props) => {
  /*
    const cookies = new Cookies();
    let curruntCookie = cookies.get("userInfo");
  */
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state?.user?.isLoggedIn);

  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setUsers = (data) => dispatch({ type: SET_USERS, data });
  const setProducts = (data) => dispatch({ type: SET_PRODUCTS, data });
  const setBaseUserData = (data) => dispatch({ type: USER_LOGGED_IN, data });

  //checking user if has already logged in
  useEffect(() => {
    activateLoader();
    if (!isLoggedIn) {
      const userToken = getToken(),
        userData = getUserData();

      if (userToken && userData) {
        //Relogging user
        const utenti = userData,
          utentiMapped = getUsersWithId(utenti),
          products = getArrayOfProductsWithIds(utentiMapped);

        setUsers(utentiMapped);
        setProducts(products);
        setBaseUserData(utentiMapped[0]);

        NavigationProvider.goTo(PAGES.USER_AREA.INDEX);
      } else {
        console.log("user not logged before");
      }
    } else {
      console.log("user already logged in");
    }
    deactivateLoader();
  }, []);

  return (
    <main id="layout-content">
      <div id="layout-content-inner">
        <Router history={history}>
          <ScrollToTop />
          <Switch>
            {/* Guest routes */}
            <AuthRoute
              routeType="guest"
              isLogged={isLoggedIn}
              exact
              path={`${INDEX_URL}${PAGES.LOGIN_STEP_1}`}
              component={LoginStep1}
            />
            <AuthRoute routeType="guest" exact path={`${INDEX_URL}${PAGES.LOGIN_STEP_2}`} component={LoginStep2} />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.FORGOT_PASSWORD}`}
              component={ForgotPassword}
            />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.FORGOT_PASSWORD_STEP_2}`}
              component={ForgotPassword2}
            />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.FORGOT_PASSWORD_ACTIVATE}`}
              component={ForgotPasswordActivate}
            />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.NEW_PASSWORD_CONFIRMATION}`}
              component={ForgotPasswordConfirmation}
            />
            <AuthRoute routeType="guest" exact path={`${INDEX_URL}${PAGES.SIGNUP}`} component={Registration} />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.SIGNUP_CONFIRMATION}`}
              component={RegistrationConfirmation}
            />
            <AuthRoute
              routeType="guest"
              exact
              path={`${INDEX_URL}${PAGES.ONLINE_SECURITY}`}
              component={OnlineSecurity}
            />
            <AuthRoute routeType="guest" exact path={`${INDEX_URL}${PAGES.USEFUL_NUMBERS}`} component={UsefulNumbers} />

            {/* Private routes */}
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.ACCOUNT_ACCESS}`}
              component={AccountAccess}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.ACCOUNT_ACCESS_OTP}`}
              component={AccountAccessOtp}
            />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.ACCOUNT}`} component={Account} />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.ACCOUNT_UPDATE_CONFIRM_OTP}`}
              component={AccountUpdateConfirmOtp}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.EDIT_PASSWORD}`}
              component={EditPassword}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.EDIT_PASSWORD_CONFIRM_OTP}`}
              component={EditPasswordConfirmOtp}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.EDIT_PASSWORD_CONFIRMATION}`}
              component={EditPasswordConfirmation}
            />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.INBOX}`} component={Inbox} />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.INDEX}`} component={Dashboard} />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.MOVEMENTS}`} component={Movements} />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.MOVEMENTS_MONTH}`}
              component={MovementDetail}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.ACCOUNT_BALANCE}`}
              component={AccountBalance}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.BANK_TRANSFER}`}
              component={BankAdvance}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.BANK_TRANSFER_CALCULATE_RATE}`}
              component={BankAdvanceSimulation}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.BANK_TRANSFER_CALCULATE_RATE_SUMM}`}
              component={BankAdvanceSimulationSummary}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.BANK_TRANSFER_REQUEST}`}
              component={BankAdvanceRequest}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.CHANGE_STANDING_EC}`}
              component={ChangeStandingEc}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.USER_USEFUL_NUMBERS}`}
              component={UserUsefulNumbers}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.USER_ONLINE_SECURITY}`}
              component={UserOnlineSecurity}
            />
            <AuthRoute
              routeType="user"
              exact
              path={`${INDEX_URL}${PAGES.USER_AREA.SMS_ACTIVATION}`}
              component={SmsActivation}
            />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.SUPPORT}`} component={Support} />
            <AuthRoute routeType="user" exact path={`${INDEX_URL}${PAGES.USER_AREA.OTP_FORM}`} component={OTPForm} />
            {/**
                <Route exact path={`${INDEX_URL}${PAGES.ERROR}`} component={Error} />
               */}

            <Redirect from="*" to={`${INDEX_URL}${PAGES.LOGIN_STEP_1}`} />
          </Switch>
        </Router>
      </div>
    </main>
  );
};

const mapStateToProps = (state) => {
  return state;
};

//Not used here
const mapStateToDispatch = (dispatch) => ({
  /*
  setUsers: (data) => dispatch({ type: SET_USERS, data }),
  setProducts: (data) => dispatch({ type: SET_PRODUCTS, data }),
  setBaseUserData: (data) => dispatch({ type: USER_LOGGED_IN, data }),
  */
});

export default connect(mapStateToProps, mapStateToDispatch)(Main);
