/**Packages */
import Loader from 'react-loader-spinner';

/**CSS */
import './_loader.css';

const ReactLoader = ({ visible = false }) => {
    return visible ? (
        <div className="loader-container">
            <Loader
                type="Oval"
                color="#00BFFF"
                visible={true}
                height={100}
                width={100} //3 secs
            />
        </div>
    ) : null;
};

export default ReactLoader;
