import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import PageTitle from "../../../components/Elements/PageTitle";
import FullWidthLayout from "../../../components/Layout/FullWidthLayout";
import ContentBox from "../../../components/Elements/ContentBox";
import Row from "../../../components/Elements/Row";
import Form from "../../../components/Form/Form";
import FloatingInput from "../../../components/Form/FloatingInput";
import Button from "../../../components/Elements/Button";

/**Helpers */
import { editPasswordProcedure } from "../../../helpers/handler";
import NavigationProvider from "../../../providers/NavigationProvider";

/**Redux */
import {
  LOADER_VISIBLE,
  LOADER_INVISIBLE,
} from "../../../redux/actionTypes/loaderActionsTypes";
import { setError } from "../../../redux/actions/errorActions";
import { setSuccess } from "../../../redux/actions/successActions";

/**CSS */
import "./style.css";

const EditPassword = () => {
  const { t, i18n, ready } = useTranslation();

  const user = useSelector((state) => state?.user);
  const { token, username } = user;

  const dispatch = useDispatch();
  const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
  const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });
  const setErrorState = (title, message) => dispatch(setError(title, message));
  const setSuccessState = (title, message) => dispatch(setSuccess(title, message));

  const [userOldPassword, setUserOldPassword] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userConfirmNewPassword, setUserConfirmNewPassword] = useState("");
  const [formValidated, setFormValidated] = useState(false);

  const validateInput = () => {
    let validated = true;
    if (
      userNewPassword === "" ||
      userOldPassword === "" ||
      userNewPassword !== userConfirmNewPassword
    ) {
      validated = false;
    }
    setFormValidated(validated);
  };

  useEffect(() => {
    validateInput();
  });

  const onEditPasswordClickHandler = async () => {
    if (formValidated) {
      activateLoader();

      const onSuccessCallback = async (result) => {
        deactivateLoader();
        setSuccessState(
          "Operazione riuscita",
          "Password modificata con successo"
        );
        NavigationProvider.goToUserArea();
      };

      const onErrorCallback = (err = "Qualcosa è andato storto") => {
        deactivateLoader();
        setErrorState("Errore", err);
      };

      await editPasswordProcedure(
        token,
        username,
        userOldPassword,
        userNewPassword,
        onSuccessCallback,
        onErrorCallback
      );
    }
  };

  const test = true;
  return (
    <>
      <FullWidthLayout id="edit-password-page">
        <PageTitle small title="Modifica password di accesso">
          <p>
            La password di accesso è la parola chiave necessaria per poter
            accedere al servizio. Al fine di mantenerne in alterata la
            sicurezza, la password di accesso deve rimanere riservata, personale
            e non deve essere divulgata a persone terze
          </p>
          {test && (
            <p className="text-center space-above-lg">
              <b>Autenticazione</b>
              <br />
              Alla conferma riceverai un messaggio sul cellulare con il codice
              OTP da inserire nella maschera che ti comparirà.
            </p>
          )}
        </PageTitle>

        <Row gridSize="xs" classList="space-below-md">
          <ContentBox
            compact
            withTitle="modifica password"
            fullWidthOnMobile
            classList="text-center"
          >
            <Row gridSize="xxs" classList="">
              <Form
                noSubmit
                submitText="Conferma"
                onSubmit={onEditPasswordClickHandler}
                classList=""
              >
                <FloatingInput
                  type="password"
                  name="oldPassword"
                  placeholder="Vecchia password"
                  validIf={["notEmpty"]}
                  value={userOldPassword}
                  onChange={(ev) => {
                    setUserOldPassword(ev.target.value);
                  }}
                />
                <FloatingInput
                  type="password"
                  name="newPassword"
                  placeholder="Nuova password"
                  validIf={["notEmpty"]}
                  value={userNewPassword}
                  onChange={(ev) => {
                    setUserNewPassword(ev.target.value);
                  }}
                />
                <FloatingInput
                  type="password"
                  name="userPassword"
                  placeholder="Conferma nuova password"
                  validIf={[`equalToPassword--${userNewPassword}`]}
                  value={userConfirmNewPassword}
                  onChange={(ev) => {
                    setUserConfirmNewPassword(ev.target.value);
                  }}
                />

                <Row classList="space-above-sm">
                  <Button type="submit" round text="Conferma" />
                  <Button
                    round
                    outline
                    text="Annulla"
                    onClick={NavigationProvider.goBack}
                  />
                </Row>
              </Form>
            </Row>
          </ContentBox>
        </Row>
      </FullWidthLayout>
    </>
  );
};

export default EditPassword;
