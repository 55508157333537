/**Packages */
import { useState } from "react";

/**Components */
import MovementExpandedItem from "../MovementExpandedItem";

/**Helpers */
import { getImage } from "../../../helpers/image";

/**CSS */
import "./style.css";

const MovementExpandedItemList = ({
  expandable = false,
  onClick = () => {},
  elements = {},
}) => {
  const [open, setOpen] = useState(false);

  // @todo move to utils
  const getArrayFromObject = (obj) => {
    let elHtml = [];
    const keys = Object.keys(obj);
    if (keys && keys.length > 0) {
      elHtml = keys.map((key, idx) => {
        const r = elements[key];
        // already using the lines below in the item component
        // const dateSplitted = r.periodo.split('/');
        // const month = dateSplitted[0];
        // const year = dateSplitted[1];
        return r;
      });
    }
    return elHtml;
  };

  const loadMore = () => {
    console.log("loadmore");
  };

  return (
    <>
      <div
        className={`movement-expanded-item-list ${expandable && "expandable"} ${
          open && "open"
        }`}
      >
        <header className="list-header tablet-only">
          <div className="flex-equal">
            <h6>Periodo</h6>
          </div>
          <div className="flex-equal">
            <h6>Acquisti</h6>
          </div>
          <div className="flex-equal">
            <h6>Pagamenti</h6>
          </div>
          <div className="flex-equal">
            <h6>Insoluti</h6>
          </div>
          <div className="flex-equal">
            <h6>Commissioni</h6>
          </div>
          <div className="flex-equal">
            <h6>Saldo</h6>
          </div>
          <div className="flex-equal">
            <h6>Scarica estratto conto</h6>
          </div>
        </header>

        <div className="movement-item-list">
          {getArrayFromObject(elements).length ? (
            getArrayFromObject(elements).map((i) => (
              <MovementExpandedItem
                element={i}
                onMonthClick={onClick}
                key={i}
              />
            ))
          ) : (
            <div className="movement-item">Nessun movimento disponibile</div>
          )}
        </div>

        {/* @todo da rimettere quando avremo la paginazione
      <footer className="list-footer">
        <button className="load-more-btn empty-button text-blue-primary" onClick={loadMore}>
          Carica altri
        </button>
      </footer> */}
      </div>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="expand-button text-center"
      >
        <img
          className="clickable"
          src={getImage("images/yellow_back.svg")}
          alt=""
        />
      </div>
    </>
  );
};

export default MovementExpandedItemList;
